import styled from 'styled-components';
import React from 'react';

type DiaryPageTextParams = {
  marginTop?: number | { [lang: string]: number };
  marginLeft?: number | { [key: string]: number };
  fntSize?: number | { [lang: string]: number };
  marginRight?: number;
  imagePosition?: number;
  center?: boolean;
  shouldRotate?: boolean;
  lang?: string;
};

const DiaryPageContainer = styled.div`
  background: url('https://dapp.timesoul.com/assets/bg1-a407c28f.webp');
`;

const getProperty = (
  props: DiaryPageTextParams,
  property: 'marginTop' | 'marginLeft' | 'fntSize' | 'imagePosition'
): number | undefined => {
  const value = props[property];

  if (typeof value === 'number') return value;
  if (typeof value === 'undefined') return undefined;
  if (typeof props.lang !== 'undefined' && props.lang in value) return value[props.lang];
  return value['default'];
};

const DiaryPageText = styled.div<DiaryPageTextParams>`
  position: absolute;
  font-family: 'Unageo', sans-serif;
  font-weight: ${({ marginLeft }) => (marginLeft ? 'normal' : 'bold')};
  color: #55453d;
  padding: 55px;
  width: ${({ marginLeft, marginRight }) => (marginLeft || marginRight ? null : '635px')};
  margin-top: ${(props) => (getProperty(props, 'marginTop') ? `${getProperty(props, 'marginTop')}px` : undefined)};
  margin-left: ${(props) => (getProperty(props, 'marginLeft') ? `${getProperty(props, 'marginLeft')}px` : undefined)};
  font-size: ${(props) => (getProperty(props, 'fntSize') ? `${getProperty(props, 'fntSize')}px` : '31px')};
  line-height: ${({ marginLeft, fntSize }) => (marginLeft ? `${fntSize}px` : '44px')};
  margin-right: ${({ marginRight }) => marginRight}px;
  text-align: ${({ center, marginLeft }) => (center || marginLeft ? 'center' : 'justify')};
  transform: ${({ shouldRotate }) => (shouldRotate ? 'rotate(90deg)' : '')};
`;

const PositionedImage = styled.img<{ url: string; imagePosition?: number }>`
  width: 746.27px;
  height: 1004px;
  background: url(${({ url }) => url});
  background-size: 746.27px 1000px;
  background-position-y: ${({ imagePosition }) => imagePosition}px;
`;

const DiaryPage = ({
  src = '',
  data = [],
  lang,
}: {
  src: string | null;
  data: undefined[] | (DiaryPageTextParams & { text: string }[]);
  lang: string;
}) => (
  <DiaryPageContainer>
    <img
      alt="diary_page"
      src="/img/diary/staples.png"
      style={{ position: 'absolute', marginTop: '220px', marginLeft: '-20px' }}
    />
    {data.filter(Boolean).map((item, i) => (
      <DiaryPageText key={i} {...item} lang={lang}>
        {item.text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index !== item.text.split('\n').length - 1 && <br />}
          </React.Fragment>
        ))}
      </DiaryPageText>
    ))}
    <PositionedImage url={src} imagePosition={getProperty({ ...data[0], lang }, 'imagePosition')} />
  </DiaryPageContainer>
);

export default DiaryPage;
