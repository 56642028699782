import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { useTranslation } from 'react-i18next';

export const Icon = () => (
  <svg width="75" height="124" viewBox="0 0 75 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.8745 120.486C38.8745 120.486 38.2307 119.892 37.9831 119.694C37.5869 119.347 37.3393 119.149 37.1907 118.951L36.9926 118.753C36.3984 118.01 35.9032 117.416 35.507 116.821C35.4575 116.821 35.3584 116.821 35.2594 116.821C35.0118 116.821 34.7642 116.772 34.5661 116.623C32.6843 115.237 31.1491 112.711 30.1587 110.879C29.5149 109.74 28.9702 108.452 28.5245 107.214C28.4749 107.016 28.3759 106.719 28.3264 106.422C28.2769 106.422 28.2273 106.422 28.1283 106.422C28.0292 106.422 27.9302 106.422 27.8311 106.422C25.4541 105.729 23.8199 101.519 23.7209 99.8355C23.6218 97.4585 23.6218 94.7843 24.8599 92.5559C25.0579 92.2092 25.4541 91.9616 25.8503 91.9616L47.7883 92.2587C47.9864 92.2587 48.1845 92.3083 48.3826 92.4073C50.2644 93.5463 51.651 96.9633 51.8491 98.548C52.1462 100.727 51.8491 105.877 48.9769 107.016C48.8778 107.016 48.7292 107.066 48.6302 107.115C48.9769 109.591 49.1254 112.216 47.0455 114.543C46.7979 114.791 46.5008 114.94 46.2037 114.94C45.9561 114.94 45.7084 114.89 45.5104 114.742C45.2628 114.543 45.0647 114.345 44.9161 114.147C44.718 114.345 44.5694 114.543 44.3713 114.742C42.7371 116.524 41.202 118.258 41.1029 120.337L38.8249 120.238C38.8249 120.536 38.924 120.684 38.924 120.684L38.8745 120.486Z"
      fill="#D3AAD0"
    />
    <path
      d="M25.8503 93.0015L47.7884 93.2986C49.175 94.1405 50.4625 97.0623 50.7101 98.5974C50.9577 100.578 50.6111 104.986 48.5312 105.828C48.3331 105.233 47.7884 104.441 47.0951 104.441C47.4417 107.61 48.6302 110.928 46.1541 113.652C45.6094 113.256 45.4608 112.364 45.2628 111.77C43.3314 114.345 40.162 116.673 39.9639 120.139C39.9639 119.644 38.3793 118.604 38.0821 118.257C37.6364 117.663 35.3089 115.088 35.8041 114.395C35.507 114.791 35.4575 115.286 35.3089 115.732C33.6252 114.494 32.1891 112.166 31.1986 110.384C30.6044 109.294 30.0596 108.056 29.6635 106.868C29.3663 105.976 29.2673 103.847 28.3759 103.351C28.0292 103.896 28.2273 104.738 28.1778 105.332C26.5436 104.887 24.9589 101.321 24.9094 99.7859C24.8104 97.5575 24.8599 95.0814 25.8998 93.1005M25.8998 90.8225C25.058 90.8225 24.3151 91.2682 23.8694 92.011C22.4828 94.4871 22.4333 97.4089 22.5819 99.9345C22.6809 101.816 24.4142 106.62 27.435 107.561C27.435 107.561 27.435 107.61 27.435 107.66C27.8807 108.997 28.5245 110.334 29.1682 111.523C30.3072 113.602 31.8919 116.128 33.9223 117.614C34.2195 117.812 34.5661 117.96 34.9127 118.01C35.3089 118.555 35.7546 119.05 36.1508 119.545L36.3489 119.743C36.5965 120.04 36.9431 120.337 37.3393 120.635C37.5374 120.833 37.9336 121.13 38.0821 121.278C38.0821 121.278 41.2515 123.952 41.301 123.16L42.341 120.288C42.44 118.703 43.6781 117.218 45.0647 115.682C45.4113 115.93 45.857 116.029 46.2532 116.029C46.897 116.029 47.4912 115.781 47.9369 115.286C50.0663 112.909 50.2149 110.235 49.9178 107.808C53.2357 105.976 53.2852 100.281 53.0376 98.3498C52.79 96.6166 51.4034 92.7539 49.0264 91.3673C48.6797 91.1692 48.2836 91.0701 47.8874 91.0701L25.9494 90.773L25.8998 90.8225Z"
      fill="#303030"
    />
    <path
      d="M25.1074 95.428C24.7112 95.428 24.3646 95.2299 24.1665 94.9328C23.9684 94.6357 23.9189 94.2395 24.0179 93.8928L27.6825 84.038C27.8311 83.5923 28.2768 83.2952 28.772 83.2952H45.8074C46.3026 83.2952 46.7483 83.5924 46.8969 84.0876L50.1158 93.9424C50.2148 94.289 50.1653 94.6852 49.9672 94.9823C49.7691 95.2794 49.4225 95.4775 49.0263 95.4775H25.1569L25.1074 95.428Z"
      fill="#B48EC0"
    />
    <path
      d="M45.8074 84.4342L49.0264 94.289H25.157L28.8215 84.4342H45.857M45.857 82.1562H28.8215C27.8806 82.1562 26.9893 82.7505 26.6426 83.6419L22.978 93.4967C22.7304 94.19 22.8294 94.9823 23.2256 95.6261C23.6713 96.2204 24.3646 96.6165 25.1074 96.6165H48.9768C49.7196 96.6165 50.413 96.2699 50.8586 95.6756C51.3043 95.0814 51.4034 94.289 51.2053 93.5957L47.9864 83.7409C47.6893 82.8 46.7979 82.1562 45.8074 82.1562H45.857Z"
      fill="#303030"
    />
    <path
      d="M2.92172 91.6643C2.03034 91.6643 1.18848 90.971 1.18848 89.6339V80.621C1.18848 79.5315 1.73321 78.3925 2.57507 77.7982L24.9588 53.4336C24.9588 53.4336 25.1074 53.2851 25.2064 53.2356C25.4045 53.1365 25.6026 53.087 25.8007 53.087C27.1873 53.087 27.8806 56.1573 27.8806 62.1989V75.4707C27.8806 76.6592 27.2368 77.8478 26.3454 78.3925L3.86264 91.4662C3.56551 91.6643 3.21885 91.7138 2.92172 91.7138V91.6643Z"
      fill="#E0D9ED"
    />
    <path
      d="M25.8007 54.226C26.3455 54.226 26.7416 61.4561 26.7416 62.199V75.4707C26.7416 76.2136 26.296 77.0554 25.7512 77.4021L3.26842 90.4758C3.26842 90.4758 3.02081 90.5748 2.87225 90.5748C2.5256 90.5748 2.278 90.2282 2.278 89.6834V80.6705C2.278 79.9277 2.72369 79.0858 3.26842 78.7392L25.7512 54.2755M25.7512 51.9975C25.355 51.9975 24.9093 52.0966 24.5627 52.2946C24.3646 52.3937 24.1665 52.5423 24.018 52.7403L1.73326 77.0059C0.693304 77.7983 0 79.2344 0 80.6705V89.6834C0 91.5157 1.23804 92.9023 2.87225 92.9023C3.41699 92.9023 3.91221 92.7538 4.40743 92.4566L26.8902 79.3829C28.1282 78.6401 29.0196 77.0554 29.0196 75.4707V62.199C29.0196 61.7533 28.9206 59.7229 28.7225 57.742C28.4254 54.3746 28.2273 51.948 25.7512 51.948V51.9975Z"
      fill="#303030"
    />
    <path
      d="M71.212 91.6642C70.8654 91.6642 70.5683 91.5652 70.2711 91.4166L47.7883 78.3429C46.897 77.7982 46.2532 76.6097 46.2532 75.4212V62.1494C46.2532 56.1078 46.9465 53.0374 48.3331 53.0374C48.5312 53.0374 48.7788 53.0869 48.9273 53.186C49.0264 53.2355 49.1254 53.3345 49.1749 53.3841L71.5587 77.7487C72.351 78.2934 72.9453 79.4819 72.9453 80.5714V89.5843C72.9453 90.0795 72.7967 90.5252 72.5986 90.8719C72.3015 91.3671 71.7568 91.6147 71.212 91.6147V91.6642Z"
      fill="#E0D9ED"
    />
    <path
      d="M48.3331 54.226L70.8159 78.6897C71.3606 78.9868 71.8063 79.8782 71.8063 80.621V89.6339C71.8063 90.1787 71.5587 90.5253 71.212 90.5253C71.113 90.5253 70.9644 90.5253 70.8159 90.4263L48.3331 77.3526C47.7884 77.0554 47.3427 76.1641 47.3427 75.4212V62.1495C47.3427 61.4066 47.7388 54.1765 48.2836 54.1765M48.2836 51.8985C45.857 51.8985 45.6094 54.325 45.3123 57.6925C45.1637 59.5248 45.0151 61.6542 45.0151 62.1495V75.4212C45.0151 77.0059 45.9065 78.5906 47.1446 79.3334L69.6274 92.4071C70.1226 92.7043 70.6178 92.8528 71.1625 92.8528C72.7967 92.8528 74.0348 91.4662 74.0348 89.6339V80.621C74.0348 79.1849 73.3415 77.7488 72.3015 76.9564L50.0168 52.6908C50.0168 52.6908 49.6702 52.3937 49.4721 52.2451C49.1254 52.0471 48.6797 51.948 48.2836 51.948V51.8985Z"
      fill="#303030"
    />
    <path
      d="M26.1474 85.5732C21.0962 85.5732 16.9364 81.463 16.9364 76.3622V30.3567C16.9364 21.8885 29.0197 2.5256 36.3984 1.18852C36.5469 1.139 36.6955 1.08948 36.8441 1.08948C36.8441 1.08948 37.0422 1.08948 37.0917 1.08948C37.1907 1.08948 37.2898 1.08948 37.3393 1.08948C44.718 1.93134 57.0984 21.6904 57.0984 30.3072V76.3127C57.0984 81.3639 52.9881 85.5237 47.8874 85.5237H26.0484L26.1474 85.5732Z"
      fill="#FAFF00"
    />
    <path
      d="M37.2402 2.27799C43.9256 3.07033 56.0089 22.3342 56.0089 30.3567V76.3622C56.0089 80.8192 52.3938 84.3847 47.9864 84.3847H26.1474C21.6904 84.3847 18.1249 80.7696 18.1249 76.3622V30.3567C18.1249 22.2847 30.1586 3.02081 36.8935 2.27799C36.8935 2.27799 37.0421 2.27799 37.0916 2.27799C37.1412 2.27799 37.2402 2.27799 37.2897 2.27799M37.2897 0C37.2897 0 37.1907 0 37.0916 0C37.0421 0 36.9431 0 36.8935 0C36.6459 0 36.3488 -6.04179e-06 36.1012 0.148559C27.633 1.83229 15.7974 22.2352 15.7974 30.4062V76.4118C15.7974 82.1067 20.4524 86.7618 26.1474 86.7618H47.9864C53.6814 86.7618 58.3364 82.1067 58.3364 76.4118V30.4062C58.3364 22.2352 46.5007 1.78277 38.0325 0.148559C37.7849 0.0495155 37.5373 0 37.2897 0Z"
      fill="#303030"
    />
    <path
      d="M37.5373 2.32754C41.9943 8.61678 45.6589 16.4907 45.6589 20.9476V66.9532C45.6589 71.4101 42.0438 74.9757 37.6364 74.9757H18.1249V76.3128C18.1249 80.7697 21.74 84.3352 26.1474 84.3352H47.9864C52.4433 84.3352 56.0089 80.7202 56.0089 76.3128V30.3072C56.0089 22.3838 44.2723 3.51605 37.5373 2.27802V2.32754Z"
      fill="#EDD500"
    />
    <path
      d="M23.8199 16.5897C23.4237 16.5897 23.0276 16.3916 22.8295 15.9954C22.6314 15.6488 22.6314 15.2031 22.8295 14.8565C26.5436 8.71578 32.0405 1.98085 36.4479 1.23802C36.5965 1.1885 36.745 1.13898 36.8936 1.13898C36.8936 1.13898 37.0917 1.13898 37.1412 1.13898C37.2402 1.13898 37.3393 1.13898 37.3888 1.13898C41.8457 1.63419 47.4912 8.51769 51.3044 14.8069C51.5025 15.1536 51.5025 15.5993 51.3044 15.9459C51.1063 16.2926 50.7101 16.5402 50.3139 16.5402H23.8199V16.5897Z"
      fill="#B48EC0"
    />
    <path
      d="M37.2402 2.27797C40.9543 2.72367 46.2531 8.7653 50.2644 15.4507H23.7703C27.7816 8.7653 33.1299 2.72367 36.7945 2.3275C36.7945 2.3275 36.9431 2.3275 36.9926 2.3275C37.0421 2.3275 37.1412 2.3275 37.1907 2.3275M37.1907 0.0494995C37.1907 0.0494995 37.0916 0.0494995 36.9926 0.0494995C36.9431 0.0494995 36.844 0.0494995 36.7945 0.0494995C36.5469 0.0494995 36.2498 0.0495029 36.0517 0.198068C30.0596 1.38658 23.6713 11.2909 21.7895 14.3612C21.3438 15.0545 21.3438 15.9459 21.7895 16.6887C22.1856 17.4316 22.978 17.8773 23.7703 17.8773H50.2644C51.1062 17.8773 51.849 17.4316 52.2452 16.6887C52.6414 15.9459 52.6414 15.1041 52.2452 14.3612C50.3634 11.2909 43.9751 1.33706 37.983 0.198068C37.7354 0.0990245 37.4878 0.0494995 37.2402 0.0494995H37.1907Z"
      fill="#303030"
    />
    <path
      d="M37.0422 47.937C30.9511 47.937 25.9989 42.9848 25.9989 36.8937C25.9989 30.8025 30.9511 25.8503 37.0422 25.8503C43.1334 25.8503 48.0855 30.8025 48.0855 36.8937C48.0855 42.9848 43.1334 47.937 37.0422 47.937Z"
      fill="#B48EC0"
    />
    <path
      d="M37.0422 26.9893C42.5391 26.9893 46.9465 31.4462 46.9465 36.8936C46.9465 42.341 42.4895 46.7979 37.0422 46.7979C31.5948 46.7979 27.1379 42.341 27.1379 36.8936C27.1379 31.4462 31.5948 26.9893 37.0422 26.9893ZM37.0422 24.7113C30.3072 24.7113 24.8599 30.2082 24.8599 36.9431C24.8599 43.6781 30.3568 49.175 37.0422 49.175C43.7276 49.175 49.274 43.6781 49.274 36.9431C49.274 30.2082 43.7771 24.7113 37.0422 24.7113Z"
      fill="#303030"
    />
    <path
      d="M21.4923 31.0005V61.8524"
      stroke="white"
      strokeWidth="2.38464"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Boosts = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
      <ButtonLabel active={active} marginTop={13}>
        {t('menu.boosts')}
      </ButtonLabel>
    </ButtonContainer>
  );
};

export default Boosts;
