import Popup, { PopupUpgradeHeader } from '../PopupOverlay';
import { useApp } from '../../../contexts/AppContext';
import { isCloudEvent } from '../../../lib/events';
import { getQuote } from '../../../lib/farming/quotes';
import RunningText from '../../../components/RunningText';
import MeerkatHeadAnimated from '../../../components/Icons/MeerkatHeadAnimated';
import { IconCloud } from '../../../components/Icons/IconCloud';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const ContainerAnimation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 800px;
  margin-top: 40px;
  margin-bottom: 40px;

  @keyframes pulseAnimation {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }

  > svg {
    height: auto;
    position: absolute;
    top: 320px;
    left: 50px;
    width: 180px;
    z-index: 100;

    animation: pulseAnimation 2s infinite;

    &:first-of-type {
      width: 300px;
      left: 100px;
      top: 630px;
    }

    &:nth-of-type(3) {
      width: 200px;
      left: 650px;
      top: 500px;
    }
  }
`;

const Cloud = () => {
  const { event } = useApp();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  if (!isCloudEvent(event)) throw new Error('Should not happen');

  const quote = getQuote('turbo', currentLanguage);

  return (
    <Popup hideClose buttonText="Close" onConfirm={async () => {}}>
      <PopupUpgradeHeader>{t('boosts.upgrade')}!</PopupUpgradeHeader>
      <ContainerAnimation>
        <IconCloud />
        <IconCloud />
        <IconCloud />
        <MeerkatHeadAnimated />
      </ContainerAnimation>
      <RunningText text={quote} />
    </Popup>
  );
};

export default Cloud;
