import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  width: 79px;
  height: 79px;
  display: flex;
`;

const IconUser = () => (
  <Container>
    <img alt="user" src="/img/user.png" />
  </Container>
);

export default IconUser;
