import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';

import Calm from './Calm';
import Speed from './Speed';
import Cloud from './Cloud';
import { MenuContainer } from './Wrappers';
import { doHapticFeedback } from '../../lib/utils';
import Luck from './Luck';

const MenuBoosts = () => {
  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <MenuContainer>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Calm />
      </motion.div>
      <motion.div initial={{ x: -64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Speed />
      </motion.div>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Cloud />
      </motion.div>
      <motion.div initial={{ x: -64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Luck />
      </motion.div>
    </MenuContainer>
  );
};

export default MenuBoosts;
