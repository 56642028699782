import React from 'react';
import { XIcon } from './XIcon';
import { TelegramIcon } from './TelegramIcon';
import styled from 'styled-components';
import { ITask } from '../../../../lib/types';

export const UrlIcon = styled.div<{ large?: boolean; url: null | string }>`
    background: ${({ url }) => `url('${url}'`});
  background-size: cover;
  border-radius: 50% 50%;
  flex-shrink: 0;
    margin-right: ${({ large }) => (large ? '' : '26px')};
    width: ${({ large }) => (large ? '350px' : '79px')};
    height: ${({ large }) => (large ? '350px' : '79px')};
`;

const getIcon = (task: ITask) => {
  if (task.coverUrl) return ({ large }: { large?: boolean }) => <UrlIcon large={large} url={task.coverUrl || null} />;
  const isTelegram = task.url.startsWith('https://t.me');
  const isX = task.url.startsWith('https://x.com');
  if (isX) return XIcon;
  if (isTelegram) return TelegramIcon;
  return ({ large }: { large?: boolean }) => (
    <UrlIcon large={large} url="https://cdn.timesoul.com/tma/task-subsribe-our-tg-channel.jpg" />
  );
};

export default getIcon;
