export default {
  system: {
    user: 'Пользователь',
    level: 'Уровень',
    levelShort: 'Урв',
    coins: 'монет',
    close: 'Закрыть',
    friends: 'Друзья',
    continue: 'Продолжить',
    seconds: 'сек',
  },
  menu: {
    meerkatCoins: 'Монеты Суриката',
    way: 'Путь',
    boosts: 'Бусты',
    missions: 'Миссии',
    friends: 'Друзья',
    diary: 'Дневник',
  },
  welcome: {
    okay: 'Хорошо',
    startGame: 'Начать игру',
    useInsights: {
      heading: 'Используйте Озарения 🧠',
      text: 'Нажмите на вашего суриката, чтобы активировать буст Озарения! Эта особая способность увеличивает ваш заработок, максимально используйте её, пока она действует, чтобы получить наибольшую выгоду!',
    },
    makeUpgrades: {
      heading: 'Делайте Улучшения 🔋',
      text: 'Повышайте уровень вашего Суриката с помощью улучшений! От Озарений и Ментальной Энергии до Физической Силы и Стрессоустойчивости, эти улучшения помогут вашему Сурикату превзойти свои пределы.',
    },
    inviteFriends: {
      heading: 'Приглашайте Друзей ✨',
      text: 'Приводите своих друзей! Выполнение миссий вместе разблокирует новые бусты, улучшит игровой процесс и значительно увеличит ваш пассивный доход. Чем больше друзей - тем лучше!',
    },
    meerkatCoins: {
      heading: 'Монеты Суриката 💸',
      text: 'Каждый ваш шаг и каждая игра приближают вашего Суриката к сокровищу. Чем активнее вы в течение дня, тем больше монет вы получаете.',
    },
  },
  friends: {
    bestFriends: 'Лучшие друзья',
    and: 'и',
    yourReward1: 'Ваши',
    yourReward2: 'вознаграждение от заявок',
    yourReward3: 'рефералов первой линии и',
    yourReward4: 'от рефералов второй линии.',
    inviteFriends: 'Пригласите друзей!',
    inviteFriend: 'Пригласить друга',
    listOfYourFriends: 'Список ваших друзей',
    linkCopied: 'Ссылка-приглашение скопирована в буфер обмена!',
    claim: 'Получить 24ч реферальный доход',
    playWithMe:
      'Присоединяйся ко мне и играй, чтобы получать монеты 💰!\n' + 'Это вход во вселенную Беспокойных Сурикатов!',
  },
  leaderboard: {
    beTheFirst: 'Стань первым!',
    leagues: {
      wooden: 'Деревянная лига',
      stone: 'Каменная лига',
      iron: 'Железная лига',
      bronze: 'Бронзовая лига',
      silver: 'Серебряная лига',
      golden: 'Золотая лига',
      platinum: 'Платиновая лига',
      osmium: 'Осмиевая лига',
      palladium: 'Палладиевая лига',
      rhodium: 'Родиевая лига',
      iridium: 'Иридиевая лига',
      ether: 'Эфирная лига',
      calmMeerkat: 'Лига спокойных сурикатов',
    },
  },
  tasks: {
    moreCoins: 'Хотите еще больше монет?',
    tasksList: 'Список заданий',
  },
  stats: {
    statistics: 'Статистика',
    claimsDone: 'Получено бонусов',
    tasksDone: 'Выполнено задач',
    friendsCount: 'Количество друзей',
    total: 'Итого',
    friendsReward: 'Награда за друзей',
  },
  settings: {
    settings: 'Настройки',
    chooseLanguage: 'Выбери язык',
  },
  farming: {
    claimed: {
      includingBonus: 'Включая x{{multiplier}} бонус благодаря',
      stressResistance: 'стрессоустойчивости!!',
      claimed: 'Получено!',
    },
    stopped: {
      welcome: 'Добро пожаловать, вы приземлились в Meerkat Coins!',
      fuelUp: 'Заправьте турбо-ускорители вашего суриката, и давайте улучшимся для предстоящего путешествия!',
      startGame: 'Начать игру',
      tribeNotCult: 'И чтобы вы знали, это племя, а не культ. Понятно? Отлично.',
      walkInterrupted: 'Прогулка была прервана из-за недостатка ментальной энергии!',
      claimRewardPrompt: 'Получите награду за раунд и позвольте вашему сурикату подготовиться к следующему раунду',
      claimRewards: 'Получить награду',
      mentalEnergyInsights: 'Ментальная энергия и озарения будут восстановлены для следующего раунда',
    },
    progress: {
      roundBalance: 'Баланс раунда:',
      roundTime: 'Время раунда:',
      roundMentalEnergy: 'ментальная энергия раунда',
    },
  },
  boosts: {
    boosts: 'Бусты',
    upgrade: 'Повысить',
    levelUp: 'Повысить уровень!',
    toLevel: 'до уровня',
    for: 'на',
    run: 'пробег',
    byUpgradingYourBoosts: {
      part1:
        'Улучшая свои бусты, вы не просто усиливаете своего суриката — вы также увеличиваете свой дополнительный заработок! Как только будут достигнуты определенные пороги, ваш ',
      highlight: 'доход от выполнения МИССИЙ возрастет.',
      part2: 'Это БЕСПРОИГРЫШНЫЙ вариант!',
    },
    calm: {
      mentalEnergy: 'Ментальная энергия',
      duration: '{{hours}}ч {{minutes}}м {{seconds}}с',
      inRound: 'за раунд',
    },
    cloud: {
      insights: 'Озарения',
      upToTimes: 'до 3 раз за каждый раунд:',
      speedX: 'скорость x',
    },
    luck: {
      stressResistance: 'Стрессоустойчивость',
      percentChance: '% шанс награды x',
      reward_description: '{{probability}}% x{{multiplier}} награды',
    },
    speed: {
      physicalStrength: 'Физическая сила',
      profit: 'прибыль',
      coinsPerSec: 'монет/сек',
    },
    conditions: {
      needSolvedTasks: 'нужно решенных задач:',
      needFriends: 'нужно друзей:',
      needClaims: 'нужно претензий:',
    },
  },
  diary: {
    diaryOfMeerkat: 'Дневник Суриката',
    pages: {
      1: 'Новый рассвет опалил крыши домов, и под многоголосную полифонию будильников, ворочаясь и чертыхаясь, потихоньку стал просыпаться весь город. В утреннем "шумном" тумане образуются пробки из ранних пташек - они сигналят и притираются друг к другу, дабы устроиться поудобнее на дороге. А любители кофе, которые уже упаковались в очередь у кассы, уткнули мордочки в экраны мерцающих экранов.',
      2: 'Примерно в таких же позах, где-то под землёй, в вагонах метро, толкались сонные, но уже обеспокоенные зверьки. Их мордочки выглядели встревоженными предстоящими делами и новостями, которые им транслировал смартфон. Проснись и пой городок во вселенной Web3! Проснись и пой! Но только почему у тебя такая беспокойная песня?',
      3: {
        above:
          'В таком ритме, в суетном городском потоке, жил был один сурикат по имени Джейк! Про таких как Джейк говорят: ни рыба ни мясо. Обычный среднестатистический зверек - среднего роста и веса, с абсолютно таким же окрасом, как у многих. В общем и целом - ничем не примечательный, на первый взгляд.',
        fingers: '4 пальца\n' + 'рука обычная',
        common: 'Cурикат обычный\n' + 'Ничего особенного',
        below:
          'Хочешь верь - хочешь нет, но однажды Джейк увидел яркое свечение в лесу, который находился на окраине городa',
        centimeters: 'см',
      },
      4: {
        above:
          'Была яркая вспышка, несколько ярких пульсаций и потом... снова темнота. Сурикатов отличает их сурикатовое любопытство, которое иногда или даже зачастую бесит других жителей пространства WEB3.',
        below:
          'И конечно же, чрезмерное любопытство Джейка тут же взяло вверх над беспокойным разумом. Он быстро захлопнул ноутбук, наплевав на бизнес-план, сроки по которому давным давно прошли. Зверек быстрее поспешил убраться с ненавистной работы в поиске ответа на вопрос: "Что это еще за херня такая?".',
      },
      5: {
        above:
          'Джейк спешил в самую глушь леса, в котором свечение становилось все ярче и ярче. Чем ближе он подходил, тем больше его окутывал страх. Все тело его зудело от тревоги, но он же к этому привык, правда, беспокойный Джейк?',
        below:
          'И вот, Джейк уже выбрался из города и мелкими перебежками пробирался по лесу, не помня себя от страха. Он двигался на далекий, пульсирующий свет, что с каждым шагом становился всё ярче и ярче. В итоге, источником света, как ни странно оказалась пещера. Пещера?..',
      },
      6: 'Джейк был бы рад постучать или позвонить в звонок для приличия, но вместо этого, споткнувшись о камень, он провалился в её самую глубь...',
      7: {
        above:
          'Не спрашивайте, как ему удалось мягко приземлиться и не лишиться головы. Самое интересное, что там, глубоко на дне пещеры, он нашел странную коробку, которая парила в воздухе...',
        below:
          'Она была настолько любопытна Джейку, что он сел рядом с ней и стал ее рассматривать, а точнее – исследовать.',
      },
      8: 'Коробка, как вы уже поняли, была не что иное, как Таймбокс. Да да, тот самый Таймбокс. И вот, в один момент, Таймбокс открылся, и неожиданно на Джейка хлынул поток глубоких знаний, внутренней уверенности, спокойствия и умиротворения. Джейк, а точнее его сознание, оказалось во Вселенной, а Вселенная оказалась в нем — они стали едины.',
      9: 'От осознания этого необыкновенного чувства, у Джейка даже проступил пот на его и без того потной шерсти. Он слышал безбрежные волны, что мерно катились где-то далеко в глубине пещеры, он чувствовал холод камня вокруг, улавливал еле заметный сквозняк, что так спокойно и умиротворенно щекочет мордашку.. все остановилось, всё стало таким гармоничным.. ка красивая музыка и приятный шелест листвы.. всё это смешалось.. Джейк прислушался и вдруг понял - всё это время это было его дыхание.',
      10:
        'По телу пробежала тепло, будто зверек сидел у маленького трескучего костра. И наконец он осознал, что все его проблемы, тревоги, беспокойства не так уж и властны над ним, и что он Вполне может справиться с ними и контролировать. И как только он это подумал - эта мысль стала частью его.\n' +
        'Он обрел силу, получил мудрость в одной лишь мысли. И мысль стала плотью. И теперь, во что бы то ни стало захотел постичь её всю, до самой глубины.',
      11: {
        above:
          'Намерение - формулирует мысли. Мысли - формируют наши решения. Наши решения - наши действия. А то что мы делаем - это и есть наше настоящее. Одна лишь мысль может изменить нас. Луч света в царстве тьмы дает надежду. Джейк больше никогда не будет прежним тревожным сурикатом. Это знает он, знаем мы, и знает та энергия, которая таилась внутри таинственного ТаймБокса. Джейк открыл глаза.',
        below:
          'Перед ним была всё та же жуткая и мрачная пещера. Пещера была прежняя, но вот Джейк стал другим. И теперь он точно знал, что нужно делать. Он встал, положил ТаймБокс, внутри себя искренне поблагодарил его и отправился наверх, из пещеры.',
      },
      12:
        'Кто ищет - тот найдет, кто знает kyga ugmu - mom 8 kypce kako Bmep попутный! Не веришь мне? Спроси у Джейка!\n' +
        '\n' +
        'О вышел наружу. Мир выглядел по-другому: звуки природы, пение птиц, свет и тени. Казалось, что даже время замедлилось.',
      13:
        '«А не принял ли я чего-то дурманящего?»,\n' +
        '- подумал Джейк.\n' +
        '«Нет, просто я наконец-то пробудился от сна», - ответил мудрый внутренний голос ставшего мудрым суриката.\n' +
        '\n' +
        'Теперь Джейк постоянно практиковал свои новые умения: медитировал, тренировал контроль, учился быть в фокусе и многое другое. А ещё он стал чаще спускался в волшебную пещеру на noucku ТаймБоксов. Находил он их там в огромном количестве, разных цветов и значений. Каждый из них давал Джейку новые уникальные знания и мудрость. И mak, внутри Джейка развивалась осознанность, а вместе с ней и созрел план.',
      14: {
        above:
          'Душу беспокойного суриката обычно греет лишь только 2 вещи - это мех и госпожа удача. Без удачи сурикат лишь мишень для беркута. Без удачи, он просто комок шерсти в этих одиноких прериях.',
        below:
          'Именно к ней и решил воззвать Джейк, на пути реализации своего плана. К удаче.\n' +
          'План был таким - улучшение осознанности и самочувствия всех сурикатов мира WEB3. Бац бац... и вдруг Свет, музыка и веселое пение в глубине леса приковали внимания жителей городка.',
      },
      15: {
        above:
          'Что заставит отвлечься сурикатов от их беспокойных дел? Тайна? Знание?\n' +
          'Чувство наживы? Всё чаще тут и там слышна молва о чудесной лотерее и её крутых призах и пользе.',
        below:
          'Это то что-то новенькое! Азарт, любопытство и испытание удачи придали зверькам уверенности, и они отправились испытать удачу на своей шкуре. Но было условие - попытать удачу можешь лишь mom, kmo nocugum с ТаймБоксом небольшое время. Ох уж этот смышленый Джейк. Зверьки сами того не осознавая стали прикасаться к мудрости, осознанности, спокойствю.',
      },
      16: {
        above:
          'Благодаря лотерее, многие узнали о Волшебных ТаймБоксах и их силе, погрузились в тайны внутреннего умиротворения и бесконечной мудрости, а ещё получили разные полезные призы и бонусы.',
        below:
          'Когда ученик готов, ему является учитель!\n' +
          '\n' +
          'Tak у Джейка появилось новые друзья и последователи, а всё почему? Потому что удача + осознанность = гремучая смесь. Многие опробовали на себе силу ТаймБокса, силу покоя.',
      },
      17: 'Они попробовали ее на себе и рассказали друзьям, а те друзья своим друзья, а уже те - всему миру.',
      tbc: 'Продолжение следует...',
    },
  },
  popups: {
    checkTask: {
      join: 'Подписаться',
      check: 'Проверить',
      taskIsNotCompleted: 'Задача не выполнена',
    },
    taskComplete: {
      missionCompleted: 'Задача выполнена!',
    },
  },
  notifications: {
    debug: {
      taps_away: 'До режима отладки осталось {{count}} нажатий',
      on: 'Режим отладки включен!',
    },
    http: {
      unexpected_error_title: 'Мы столкнулись с непредвиденной ошибкой 🚧',
      unexpected_error_subtitle: 'Возможно, это связано с подключением. Мы быстро вернём суриката на путь!',
    },
    farming: {
      coins_collected_title: 'Собраны монеты Суриката! 🌱',
      coins_collected_subtitle: 'Начинается новое путешествие, наблюдайте за ростом монет! 🚀',
      insights_activated_title: 'Зарабатывайте больше с активированными инсайтами! 🌱',
      insights_activated_subtitle: 'Монеты Суриката приходят быстрее, чем когда-либо! 🏃‍♂️💨',
    },
    upgrade: {
      insight_title: 'Обновление инсайта завершено! ⚡️',
      insight_subtitle: 'Глубже — выше, время увидеть суть! 💥',
      physical_strength_title: 'Обновление физической силы завершено! 💪',
      physical_strength_subtitle: 'Сила — это мощь, время доминировать! 💥',
      mental_energy_title: 'Обновление ментальной энергии завершено! 🧠',
      mental_energy_subtitle: 'Энергия — это ключ, время для возможностей! 💥',
      stress_resistance_title: 'Обновление стрессоустойчивости завершено! 🍃',
      stress_resistance_subtitle: 'Удача на стороне сильных, время испытать свою удачу! 💥',
    },
    connection: {
      restored_title: 'Соединение восстановлено',
      restored_subtitle: 'Вы снова онлайн! Соединение с интернетом восстановлено',
      lost_title: 'Соединение потеряно',
      lost_subtitle: 'Вы сейчас офлайн. Проверьте подключение к интернету!',
    },
  },
};
