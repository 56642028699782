import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 600px;
  overflow: hidden;
  background: none;
  border: 0;

  img {
    position: absolute;
    opacity: 0;
    width: 600px;

    &.active {
      opacity: 1;
    }
  }
`;

const MeerkatHeadAnimated = () => {
  const [i, setIndex] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => setIndex((i) => (i === 6 ? 1 : i + 1)), 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <img src={`/img/animation/upgrade1.webp`} className={i === 1 ? 'active' : ''} />
      <img src={`/img/animation/upgrade1.webp`} className={i === 2 ? 'active' : ''} />
      <img src={`/img/animation/upgrade1.webp`} className={i === 3 ? 'active' : ''} />

      <img src={`/img/animation/upgrade2.webp`} className={i === 4 ? 'active' : ''} />
      <img src={`/img/animation/upgrade3.webp`} className={i === 5 ? 'active' : ''} />
      <img src={`/img/animation/upgrade2.webp`} className={i === 6 ? 'active' : ''} />
    </Container>
  );
};

export default MeerkatHeadAnimated;
