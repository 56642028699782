import styled from 'styled-components';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

const Container = styled.div`
  text-align: center;
  font-size: 80px;
  font-weight: 500;
  font-family: var(--font), sans-serif;
  color: #535353;
  margin-top: 30px;
  width: 100%;
  text-transform: uppercase;
  position: relative;

  .marquee {
    position: relative;
    min-height: 100px;
    padding: 0 10px;
  }
  .marquee,
  .marquee * {
    overflow: hidden;
    font-family: var(--font), sans-serif;
  }
  .marquee {
    white-space: nowrap;
  }
`;

const MarqueeText = styled.div<{ animate: boolean }>`
  position: absolute;
  animation: ${({ animate }) => (animate ? 'marqueeText linear 10s infinite' : 'none')};
  transform: translateX(800px);

  @keyframes marqueeText {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const RunningText = ({ text }: { text: string }) => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffectOnce(() => {
    setAnimationStarted(true);
  });

  return (
    <Container>
      <div className="marquee">
        <MarqueeText animate={animationStarted}>{text}</MarqueeText>
      </div>
    </Container>
  );
};

export default RunningText;
