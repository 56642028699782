import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Popup from '../PopupOverlay';
import { isTaskCompleteEvent } from '../../../lib/events';
import React from 'react';
import { useApp } from '../../../contexts/AppContext';
import Row from '../../Row';
import { compactAmount } from '../../../lib/utils';
import { IconCoin } from '../../Icons/IconCoin';

const RewardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    margin-top: -40px;
    margin-right: -20px;
    width: 200px;
    height: 200px;
  }
`;

const IconCoin1 = styled.div`
  svg {
    position: absolute;
    transform: rotate(45deg);
    margin-left: -30px;
    margin-top: -50px;
    scale: 1.5;
  }
`;

const IconCoin2 = styled.div`
  svg {
    position: absolute;
    transform: rotate(-50deg);
    margin-left: 300px;
    margin-top: 50px;
    scale: 2;
  }
`;

const IconCoin3 = styled.div`
  svg {
    position: absolute;
    transform: rotate(-40deg);
    margin-left: -75px;
    margin-top: 100px;
    scale: 2;
  }
`;

const Reward = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 117px;
  font-weight: 700;
  line-height: 128.7px;
  letter-spacing: -0.02em;
  text-align: left;
`;

const Text = styled.div`
  font-family: var(--font), sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;

  color: #535353;
  text-transform: uppercase;
`;

const TaskCompleted = () => {
  const { event, taskFactor } = useApp();
  const { t } = useTranslation();

  if (!isTaskCompleteEvent(event)) throw new Error('Should not happen');

  return (
    <Popup hideClose={true}>
      <RewardContainer>
        <Reward>+{compactAmount(event.task.reward * taskFactor)}</Reward>
        <IconCoin />
      </RewardContainer>
      <Row margin="0 40px 40px">
        <IconCoin1>
          <IconCoin />
        </IconCoin1>
        <IconCoin2>
          <IconCoin />
        </IconCoin2>
        <IconCoin3>
          <IconCoin />
        </IconCoin3>
        <img alt="Completed" src="/img/MeerkatCompleted.png" />
      </Row>
      <Text>{t('popups.taskComplete.missionCompleted')}</Text>
    </Popup>
  );
};

export default TaskCompleted;
