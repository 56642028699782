import styled from 'styled-components';

export const XIcon = styled(({ large, ...props }: { large?: boolean }) => (
  <svg width={254} height={254} viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M127 0.75C196.728 0.75 253.25 57.2716 253.25 127C253.25 196.728 196.728 253.25 127 253.25C57.2716 253.25 0.75 196.728 0.75 127C0.75 57.2716 57.2716 0.75 127 0.75Z"
      fill="black"
      stroke="white"
      strokeWidth={large ? 1.5 : 3}
    />
  </svg>
))<{ large?: boolean }>`
  flex-shrink: 0;
  margin-right: ${({ large }) => (large ? '' : '26px')};
  width: ${({ large }) => (large ? '350px' : '79px')};
  height: ${({ large }) => (large ? '350px' : '79px')};
`;
