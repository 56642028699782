import styled from 'styled-components';

import { MainButton } from '../../MainButton';
import PageContainer from '../../Wrappers';
import { useApp } from '../../../contexts/AppContext';
import { IconCoin } from '../../Icons/IconCoin';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../../../lib/utils';
import MeerkatHandAnimated from '../../../components/Icons/MeerkatHandAnimated ';
import { useTranslation } from 'react-i18next';

export const Container = styled.div`
  @keyframes pulseClaim {
    0% {
      transform: scale(0.97);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(0.97);
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: 0;

  background: #00000085;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 65px;
    text-align: center;
    width: 750px;
  }
  p {
    color: #fff;
    font-size: 42px;
    text-align: center;
    width: 800px;
    margin-top: 30px;
  }
  > img {
    margin-top: -50px;
    width: 600px;
    margin-left: 30px;
    animation: pulseClaim 5s infinite;
  }
  button {
    margin-top: -10px !important;
    font-size: 46px;
    margin-top: 30px;
    padding: 80px 70px;
    border-radius: 150px;
    z-index: 100;
    background: #000000d9;
    border-width: 3px;
    position: relative;

    .meerkatHand {
      position: absolute;
      right: 0;
      top: 50px;
    }
  }
  svg {
    width: 110px;
    height: auto;
    margin-top: -11px;
  }

  &.newClaim {
    > img {
      margin-top: -160px;
      width: 750px;
      margin-left: 30px;
    }
    button {
      margin-top: -20px !important;
    }
  }
`;

const FarmingStopped = () => {
  const { farmingClaim, app, isBusy } = useApp();
  const { t } = useTranslation();

  useEffectOnce(() => {
    doHapticFeedback();
  });

  if (app.state.claimsCount === 0) {
    return (
      <PageContainer spread light>
        <Container className="newGame">
          <h1>{t('farming.stopped.welcome')}</h1>
          <p>{t('farming.stopped.fuelUp')}</p>
          <img alt="Meerkat" src="/img/Meerkat.png" />
          <MainButton onClick={farmingClaim} disabled={isBusy}>
            {t('farming.stopped.startGame')} <IconCoin />
            <MeerkatHandAnimated />
          </MainButton>
          <motion.div
            initial={{ x: -64 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}
          >
            <p style={{ color: '#aaa', fontStyle: 'italic' }}>{t('farming.stopped.tribeNotCult')}</p>
          </motion.div>
        </Container>
      </PageContainer>
    );
  }

  return (
    <PageContainer spread light>
      <Container className="newClaim">
        <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <h1>{t('farming.stopped.walkInterrupted')}</h1>
        </motion.div>
        <motion.div initial={{ x: -64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <p>{t('farming.stopped.claimRewardPrompt')}</p>
        </motion.div>
        <img alt="MeerkatSunglasses" src="/img/MeerkatSunglasses.png" />
        <MainButton onClick={farmingClaim} disabled={isBusy}>
          {t('farming.stopped.claimRewards')} <IconCoin />
          <MeerkatHandAnimated />
        </MainButton>
        <motion.div initial={{ x: -64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <p style={{ color: '#aaa', fontStyle: 'italic' }}>{t('farming.stopped.mentalEnergyInsights')}</p>
        </motion.div>
      </Container>
    </PageContainer>
  );
};

export default FarmingStopped;
