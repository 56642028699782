import styled from 'styled-components';

export const Header = styled.div`
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 90px;
  line-height: 90px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.8px;
  text-transform: uppercase;
  margin-bottom: 40px;
`;

export const Button = styled.button<{ left?: boolean }>`
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: -2px;
  left: -2px;
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
  margin-left: ${({ left }) => (left ? '-440px' : '310px')};
  margin-top: ${({ left }) => (left ? '-100px' : '-100px')};
  text-align: left;
  background: transparent;
  border: none;
  &:active {
        ${({ disabled }) =>
          !disabled &&
          `
          top: 2px;
          left: 2px;
      `}
`;

export const ButtonLabel = styled.div`
  color: white;
  margin-top: 20px;
  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 50.92px;
  text-align: right;
`;
