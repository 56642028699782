import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { postFriendsReward } from '../../../lib/api';
import { useApp } from '../../../contexts/AppContext';
import { IconCoin } from '../../Icons/IconCoin';
import { compactAmount } from '../../../lib/utils';
import Loading from '../../Loading';

const Container = styled.button`
  position: relative;
  padding: 0 60px;
  min-width: 830px;
  height: 130px;
  background: ${({ disabled }) => (disabled ? '#32363ca3' : '#000000c4')};
  border: 1.5px solid #f9ff00;
  border-radius: 105px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  svg {
    position: absolute;
    width: 80px;
    height: 80px;
    margin-top: -20px;
  }
`;

const StyledText = styled.div<{ disabled: boolean }>`
  color: #f9ff00;
  font-family: var(--font), sans-serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-transform: uppercase;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const ClaimFriendsButton = () => {
  const [posting, setPosting] = useState(false);
  const { friendRewardBuffer, updateApp } = useApp();
  const { t } = useTranslation();

  const disabled = friendRewardBuffer === 0;

  const handleOnClick = async () => {
    setPosting(true);
    await postFriendsReward();
    await updateApp();
    setPosting(false);
  };

  return (
    <Container disabled={disabled || posting} onClick={handleOnClick}>
      <StyledText disabled={disabled}>
        {posting && <Loading />}
        {!posting && (
          <>
            {t('friends.claim') + ' '}
            {!!friendRewardBuffer && (
              <>
                {compactAmount(friendRewardBuffer)}
                <IconCoin />
                <span style={{ width: '70px', display: 'inline-block' }} />
              </>
            )}
          </>
        )}
      </StyledText>
    </Container>
  );
};
