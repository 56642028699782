import { i18nInit } from './init-i18n';

import enTranslations from './locales/en/translation'; // English
import ruTranslations from './locales/ru/translation'; // Russian

const i18n = i18nInit({
  en: enTranslations,
  ru: ruTranslations,
});

export const languages = {
  en: 'English',
  ru: 'Русский',
};

export default i18n;
