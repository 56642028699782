export const levels = [
  {
    number: 1,
    price: 0,
  },
  {
    number: 2,
    price: 5000,
  },
  {
    number: 3,
    price: 25000,
  },
  {
    number: 4,
    price: 150000,
  },
  {
    number: 5,
    price: 1000000,
  },
  {
    number: 6,
    price: 10000000,
  },
  {
    number: 7,
    price: 75000000,
  },
  {
    number: 8,
    price: 750000000,
  },
  {
    number: 9,
    price: 8000000000,
  },
];

export const getPriceForLevel = (level: number) => {
  if (level === 1) {
    return 0;
  }

  if (level === 2) {
    return 5000;
  }

  let price = 5000;

  for (let i = 3; i <= level; i++) {
    const multiplier: number = i % 2 === 0 ? 5 : 2; // Even levels multiply by 5, odd by 2
    price *= multiplier;
  }

  return price;
};

export const getLevelForBalance = (balance: number) => {
  if (balance <= 0) {
    return 1;
  }

  let level = 1;
  let currentPrice = getPriceForLevel(level);

  while (balance >= currentPrice) {
    level++;
    if (level > 1) {
      currentPrice = getPriceForLevel(level);
    }

    if (level > 100) {
      throw new Error('Should not happen');
    }
  }

  if (level > 13) return 13;

  return level - 1;
};
