import styled from 'styled-components';

import ButtonContainer from './ButtonContainer';
import { IconCoin } from '../../Icons/IconCoin';

import { getFarmingSpeed } from '../../../lib/farming/util';
import { useApp } from '../../../contexts/AppContext';
import IconQuestion from '../../../components/Icons/IconQuestion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ active: boolean }>`
  @keyframes pulseColor {
    0%,
    100% {
      color: #f56a3f;
    }
    50% {
      color: #9e42b0;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  filter: ${({ active }) => (active ? `grayscale(100)` : 'grayscale(0)')};

  p {
    color: #fff;
    font-size: 36px;
    display: flex;
    align-items: center;

    &.header {
      color: #faff00;
    }
    &.headerSub {
      margin-top: 15px;
      height: 50px;
    }
    svg {
      height: 100px;
    }
    .iconQuestion {
      height: 40px;
      margin-left: 8px;
      fill: #ccc;
    }
  }
  &.turbo {
    p {
      &.header {
        animation: pulseColor 3s infinite;
      }
    }
  }
`;

const Stats = ({ active = false }) => {
  const { app, farmingState } = useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const speed = getFarmingSpeed(app, farmingState);

  return (
    <ButtonContainer>
      <Container active={active} className={farmingState}>
        <p className="header">{t('menu.meerkatCoins')}</p>
        <p className="headerSub" onClick={() => navigate('/welcome')}>
          {speed}
          <IconCoin />/{t('system.seconds')} <IconQuestion />
        </p>
      </Container>
    </ButtonContainer>
  );
};

export default Stats;
