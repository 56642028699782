import React from 'react';
import styled from 'styled-components';
import Row from '../../../Row';
import { useTranslation } from 'react-i18next';
import { compactAmount, filterSpecialCharacters } from '../../../../lib/utils';
import { Friend } from '../../../../lib/types';
import IconUser from '../../../Icons/IconUser';
import { IconCoin } from '../../../Icons/IconCoin';

const Container = styled.div`
  height: 130px;
  width: 770px;

  border-radius: 105px;
  border: 1.5px solid rgba(235, 235, 235, 0.61);
  background: rgba(50, 54, 60, 0.64);

  padding: 0 30px;
  display: flex;
  margin: 10px auto;
  align-items: center;
`;

const Name = styled.div`
  color: white;
  margin-left: 37px;
  font-family: var(--font), sans-serif;
  font-size: 32px;

  & a {
    color: black;
  }
`;

const Reward = styled.div`
  font-size: 40px;
  color: white;
`;

const FriendCard = ({ friend }: { friend: Friend }) => {
  const { t } = useTranslation();
  const name = friend.displayAs || `${t('system.user')} ${friend.id}`;

  return (
    <Container>
      <Row spread={true} style={{ width: '100%' }}>
        <Row>
          <IconUser />
          <Name>
            L{friend.friendshipLevel} {filterSpecialCharacters(name)}
          </Name>
        </Row>
        {!!friend.reward && (
          <Row>
            <IconCoin />
            <Reward>{compactAmount(friend.reward)}</Reward>
          </Row>
        )}
      </Row>
    </Container>
  );
};

export default FriendCard;
