export const IconFireworksRocket = () => (
  <svg
    className="fireworksRocket"
    width="342"
    height="287"
    viewBox="0 0 342 287"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M201.923 219.7L203.337 218.286L201.923 216.872L195.492 210.442L194.078 209.027L192.664 210.442L130.525 272.58L129.111 273.995L130.525 275.409L136.955 281.839L138.37 283.253L139.784 281.839L201.923 219.7Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M176.509 193.792L246.78 123.521L290.208 166.948L219.937 237.219C219.658 237.189 219.187 237.071 218.493 236.784C217.038 236.181 215.047 235.02 212.635 233.326C207.837 229.959 201.688 224.711 195.353 218.376L195.352 218.375C189.017 212.049 183.77 205.904 180.402 201.106C178.709 198.694 177.548 196.703 176.945 195.246C176.656 194.546 176.539 194.072 176.509 193.792ZM220.11 237.225C220.11 237.226 220.107 237.226 220.099 237.226C220.106 237.225 220.11 237.225 220.11 237.225ZM176.503 193.622C176.504 193.622 176.503 193.626 176.502 193.633C176.503 193.625 176.503 193.622 176.503 193.622Z"
      fill="#D9B0E7"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M298.088 171.125C298.089 171.126 298.078 171.127 298.053 171.126C298.075 171.124 298.088 171.125 298.088 171.125ZM297.184 170.899C296.851 170.776 296.441 170.598 295.951 170.352C294.157 169.449 291.707 167.846 288.751 165.615C282.862 161.171 275.226 154.439 267.258 146.472C259.291 138.504 252.559 130.868 248.115 124.979C245.884 122.022 244.281 119.573 243.378 117.779C243.132 117.289 242.954 116.879 242.831 116.546L306.947 106.783L297.184 170.899ZM242.604 115.642C242.605 115.642 242.606 115.655 242.604 115.677C242.603 115.652 242.604 115.641 242.604 115.642Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="4"
    />
    <mask
      id="mask0_1101_4701"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="239"
      y="103"
      width="70"
      height="70"
    >
      <path
        d="M297.139 170.03C297.14 170.031 297.129 170.032 297.104 170.031C297.126 170.029 297.138 170.029 297.139 170.03ZM296.235 169.804C295.902 169.681 295.492 169.503 295.002 169.256C293.208 168.354 290.758 166.751 287.802 164.52C281.913 160.076 274.277 153.344 266.309 145.376C258.342 137.409 251.61 129.773 247.166 123.884C244.935 120.927 243.332 118.478 242.429 116.684C242.182 116.193 242.005 115.784 241.882 115.451L305.997 105.688L296.235 169.804ZM241.655 114.546C241.656 114.547 241.657 114.56 241.655 114.582C241.653 114.557 241.654 114.546 241.655 114.546Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
    </mask>
    <g mask="url(#mask0_1101_4701)">
      <path
        opacity="0.2"
        d="M306.733 186.782L264.645 154.782L307.324 107.464L318.036 153.261L306.733 186.782Z"
        fill="#231F20"
        stroke="#303030"
        strokeWidth="4"
      />
    </g>
    <path
      d="M201.904 175.967L243.305 134.566C243.676 134.196 243.987 134.14 244.227 134.159C244.521 134.181 244.883 134.338 245.196 134.651C245.509 134.964 245.666 135.326 245.688 135.62C245.707 135.86 245.651 136.171 245.281 136.541L239.459 142.364L198.058 183.765C197.687 184.135 197.376 184.19 197.136 184.172C196.842 184.149 196.48 183.993 196.167 183.68C195.854 183.367 195.697 183.005 195.675 182.711C195.657 182.471 195.712 182.16 196.082 181.789L201.904 175.967Z"
      fill="#FFFFF9"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      opacity="0.2"
      d="M179.749 200.152C192.62 208.13 209.477 209.943 227.297 206.381C240.23 203.796 253.766 198.368 266.853 190.303L219.937 237.219C219.658 237.189 219.187 237.071 218.493 236.784C217.038 236.181 215.047 235.02 212.635 233.326C207.837 229.959 201.688 224.711 195.353 218.376L195.352 218.375C189.727 212.759 184.957 207.282 181.602 202.763C180.921 201.845 180.302 200.973 179.749 200.152ZM220.11 237.225C220.11 237.226 220.107 237.226 220.099 237.227C220.106 237.225 220.11 237.225 220.11 237.225Z"
      fill="#231F20"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M138.204 199.075L139.618 197.661L138.204 196.247L133.002 191.045L131.588 189.631L130.174 191.045L49.1664 272.053L47.7522 273.467L49.1664 274.881L54.3679 280.083L55.7821 281.497L57.1964 280.083L138.204 199.075Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M50.3156 100.645C23.4618 127.499 0.121713 180.766 37.5507 219.06C74.9628 257.355 128.229 236.663 151.569 212.457"
      stroke="#303030"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M105.569 166.436C105.561 166.328 105.561 166.253 105.564 166.203L197.795 73.972L255.277 131.454L163.047 223.685C162.997 223.688 162.921 223.689 162.814 223.681C162.367 223.646 161.664 223.471 160.675 223.062C158.715 222.25 156.075 220.708 152.915 218.49C146.621 214.075 138.579 207.21 130.309 198.94C122.039 190.67 115.175 182.628 110.759 176.334C108.542 173.174 106.999 170.535 106.188 168.574C105.778 167.585 105.603 166.883 105.569 166.436ZM163.127 223.674C163.127 223.675 163.123 223.676 163.115 223.678C163.123 223.675 163.127 223.674 163.127 223.674ZM105.572 166.135C105.574 166.126 105.575 166.122 105.575 166.122C105.575 166.122 105.575 166.127 105.572 166.135Z"
      fill="#D9B0E7"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M31.0147 81.0167C31.0977 81.0686 31.1806 81.12 31.2632 81.1709C36.7173 84.5307 41.7008 86.0144 46.2908 84.7843C50.8807 83.5543 54.4543 79.7775 57.4972 74.1421C57.542 74.0592 57.5867 73.9757 57.6314 73.8918C57.6281 73.9866 57.6251 74.0809 57.6224 74.1749C57.4397 80.5765 58.6465 85.6337 62.0064 88.9936C65.3663 92.3535 70.4235 93.5603 76.8251 93.3776C76.922 93.3748 77.0193 93.3717 77.1169 93.3683C77.0308 93.4141 76.9453 93.46 76.8602 93.506C71.226 96.5509 67.4516 100.127 66.2233 104.718C64.995 109.309 66.479 114.292 69.8378 119.745C69.8865 119.825 69.9357 119.904 69.9853 119.983C69.9023 119.931 69.8195 119.88 69.7368 119.829C64.2828 116.469 59.2992 114.986 54.7092 116.216C50.1193 117.446 46.5457 121.223 43.5028 126.858C43.458 126.941 43.4133 127.024 43.3686 127.108C43.3719 127.013 43.3749 126.919 43.3776 126.825C43.5603 120.424 42.3535 115.366 38.9936 112.006C35.6337 108.646 30.5765 107.44 24.1749 107.622C24.078 107.625 23.9807 107.628 23.8831 107.632C23.9692 107.586 24.0547 107.54 24.1398 107.494C29.774 104.449 33.5484 100.873 34.7768 96.2822C36.005 91.6915 34.5211 86.7077 31.1622 81.2545C31.1135 81.1754 31.0643 81.0962 31.0147 81.0167Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M264.067 136.941L264.015 137.286L264.034 137.384C263.463 137.239 262.664 136.932 261.625 136.409C259.238 135.208 256.011 133.093 252.146 130.176C244.441 124.362 234.467 115.567 224.072 105.172C213.677 94.7774 204.883 84.8032 199.068 77.0979C196.151 73.2332 194.036 70.0061 192.835 67.6189C192.312 66.5796 192.005 65.7814 191.86 65.2098L191.958 65.2291L192.303 65.1766L276.966 52.2781L264.067 136.941ZM264.711 137.48C264.712 137.48 264.704 137.482 264.687 137.483C264.702 137.48 264.711 137.479 264.711 137.48ZM191.764 64.5328C191.765 64.5333 191.764 64.5417 191.761 64.5568C191.762 64.5398 191.764 64.5323 191.764 64.5328Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="4"
    />
    <mask
      id="mask1_1101_4701"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="188"
      y="47"
      width="91"
      height="90"
    >
      <path
        d="M263.302 134.304L263.249 134.648L263.269 134.747C262.697 134.602 261.899 134.295 260.86 133.772C258.472 132.571 255.245 130.455 251.381 127.539C243.675 121.724 233.701 112.93 223.306 102.535C212.911 92.1401 204.117 82.1659 198.302 74.4606C195.386 70.5958 193.27 67.3688 192.069 64.9816C191.546 63.9422 191.239 63.1441 191.094 62.5725L191.193 62.5918L191.537 62.5393L276.2 49.6407L263.302 134.304ZM263.946 134.843C263.946 134.843 263.939 134.845 263.922 134.846C263.937 134.842 263.945 134.842 263.946 134.843ZM190.999 61.8954C190.999 61.8959 190.999 61.9044 190.995 61.9194C190.996 61.9025 190.998 61.895 190.999 61.8954Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
    </mask>
    <g mask="url(#mask1_1101_4701)">
      <path
        opacity="0.2"
        d="M276.734 157.096L220.659 114.453L277.512 51.4319L291.804 112.439L276.734 157.096Z"
        fill="#231F20"
        stroke="#303030"
        strokeWidth="4"
      />
    </g>
    <path
      opacity="0.2"
      d="M253.332 136.139C202.527 183.566 136.835 196.399 103.582 166.371C103.667 171.396 114.089 185.552 128.891 200.354C145.543 217.006 161.415 228.142 164.334 225.222L255.675 133.882C254.894 134.629 254.13 135.392 253.349 136.139H253.332Z"
      fill="#231F20"
    />
    <path
      d="M138.109 2.54915V16.9436C138.109 19.1333 141.504 19.1333 141.504 16.9436V2.54915C141.504 0.359429 138.109 0.359429 138.109 2.54915Z"
      fill="#B480C5"
    />
    <path
      d="M114.072 18.1658C117.704 20.2706 121.337 22.3585 124.969 24.4634C125.496 24.7689 126.005 25.0575 126.531 25.363C127.329 25.8213 128.381 25.5497 128.857 24.7519C129.332 23.9541 129.043 22.8847 128.245 22.4264C124.613 20.3216 120.98 18.2337 117.348 16.1288C116.822 15.8233 116.312 15.5347 115.786 15.2292C114.988 14.7709 113.936 15.0425 113.461 15.8403C112.985 16.6381 113.274 17.7075 114.072 18.1658Z"
      fill="#B480C5"
    />
    <path
      d="M115.616 46.7681C119.249 44.6632 122.882 42.5753 126.514 40.4705L128.076 39.5708C128.874 39.1125 129.145 38.0431 128.687 37.2453C128.229 36.4475 127.159 36.1759 126.361 36.6342C122.729 38.7391 119.096 40.8269 115.464 42.9318L113.902 43.8314C113.104 44.2898 112.833 45.3592 113.291 46.157C113.749 46.9548 114.819 47.2264 115.616 46.7681Z"
      fill="#B480C5"
    />
    <path
      d="M141.129 59.7705V45.3761C141.129 43.1863 137.734 43.1863 137.734 45.3761V59.7705C137.734 61.9602 141.129 61.9602 141.129 59.7705Z"
      fill="#B480C5"
    />
    <path
      d="M165.165 44.154C161.533 42.0491 157.9 39.9612 154.268 37.8564C153.741 37.5508 153.232 37.2623 152.706 36.9567C151.908 36.4984 150.856 36.77 150.381 37.5678C149.905 38.3656 150.194 39.435 150.992 39.8933C154.624 41.9982 158.257 44.0861 161.889 46.1909C162.416 46.4965 162.925 46.785 163.451 47.0906C164.249 47.5489 165.301 47.2773 165.776 46.4795C166.252 45.6817 165.963 44.6123 165.165 44.154Z"
      fill="#B480C5"
    />
    <path
      d="M163.619 15.5517C159.987 17.6565 156.354 19.7444 152.722 21.8493L151.16 22.7489C150.362 23.2072 150.09 24.2766 150.549 25.0744C151.007 25.8723 152.076 26.1438 152.874 25.6855C156.507 23.5807 160.139 21.4928 163.772 19.388L165.334 18.4883C166.131 18.03 166.403 16.9606 165.945 16.1628C165.486 15.365 164.417 15.0934 163.619 15.5517Z"
      fill="#B480C5"
    />
    <path
      d="M80.0066 163.876C77.8339 166.049 75.6611 168.221 73.4884 170.394C73.1828 170.7 72.8773 171.005 72.5548 171.328C71.9267 171.956 71.8758 173.11 72.5548 173.721C73.2338 174.332 74.2862 174.4 74.9482 173.721C77.121 171.548 79.2937 169.376 81.4664 167.203C81.772 166.897 82.0775 166.592 82.4001 166.269C83.0281 165.641 83.079 164.487 82.4001 163.876C81.7211 163.265 80.6686 163.197 80.0066 163.876Z"
      fill="#B480C5"
    />
    <path
      d="M59.381 159.989C60.1788 162.959 60.9766 165.93 61.7575 168.883C61.8763 169.308 61.9781 169.732 62.097 170.156C62.3346 171.022 63.3191 171.616 64.1848 171.345C65.0505 171.073 65.6107 170.173 65.3731 169.257C64.5753 166.286 63.7774 163.316 62.9966 160.362C62.8778 159.938 62.7759 159.513 62.6571 159.089C62.4195 158.223 61.4349 157.629 60.5692 157.901C59.7035 158.172 59.1434 159.072 59.381 159.989Z"
      fill="#B480C5"
    />
    <path
      d="M45.6646 175.877C48.6351 176.675 51.6057 177.472 54.5593 178.253L55.8324 178.593C56.6811 178.83 57.7166 178.304 57.9203 177.405C58.1239 176.505 57.6487 175.554 56.732 175.317C53.7615 174.519 50.7909 173.721 47.8373 172.94L46.5642 172.601C45.7155 172.363 44.68 172.889 44.4764 173.789C44.2727 174.689 44.748 175.639 45.6646 175.877Z"
      fill="#B480C5"
    />
    <path
      d="M52.6084 195.703C54.7811 193.53 56.9539 191.358 59.1266 189.185C59.4321 188.879 59.7377 188.574 60.0602 188.251C60.6883 187.623 60.7392 186.469 60.0602 185.858C59.3812 185.247 58.3288 185.179 57.6668 185.858C55.494 188.031 53.3213 190.203 51.1485 192.376C50.843 192.682 50.5375 192.987 50.2149 193.31C49.5869 193.938 49.536 195.092 50.2149 195.703C50.8939 196.314 51.9463 196.382 52.6084 195.703Z"
      fill="#B480C5"
    />
    <path
      d="M73.2285 199.59C72.4307 196.62 71.6329 193.649 70.8521 190.696C70.7332 190.271 70.6314 189.847 70.5126 189.423C70.2749 188.557 69.2904 187.963 68.4247 188.234C67.559 188.506 66.9988 189.406 67.2365 190.322C68.0343 193.293 68.8321 196.263 69.6129 199.217C69.7318 199.641 69.8336 200.066 69.9524 200.49C70.1901 201.356 71.1746 201.95 72.0403 201.678C72.906 201.407 73.4662 200.507 73.2285 199.59Z"
      fill="#B480C5"
    />
    <path
      d="M86.9469 183.702C83.9763 182.904 81.0057 182.107 78.0522 181.326C77.6278 181.207 77.2034 181.105 76.7791 180.986C75.9303 180.749 74.8949 181.275 74.6912 182.174C74.4875 183.074 74.9628 184.025 75.8794 184.262C78.85 185.06 81.8205 185.858 84.7741 186.639C85.1985 186.758 85.6229 186.859 86.0472 186.978C86.8959 187.216 87.9314 186.69 88.1351 185.79C88.3388 184.89 87.8635 183.94 86.9469 183.702Z"
      fill="#B480C5"
    />
    <path
      d="M333.149 39.0106C330.976 41.1833 328.804 43.3561 326.631 45.5289C326.325 45.8344 326.02 46.1399 325.697 46.4625C325.069 47.0905 325.018 48.2448 325.697 48.8559C326.376 49.467 327.429 49.5349 328.091 48.8559C330.264 46.6831 332.436 44.5104 334.609 42.3376C334.915 42.0321 335.22 41.7265 335.543 41.404C336.171 40.776 336.222 39.6217 335.543 39.0106C334.864 38.3995 333.811 38.3316 333.149 39.0106Z"
      fill="#B480C5"
    />
    <path
      d="M312.524 35.1234C313.321 38.094 314.119 41.0645 314.9 44.0181C315.019 44.4425 315.121 44.8668 315.24 45.2912C315.477 46.1569 316.462 46.751 317.327 46.4794C318.193 46.2078 318.753 45.3082 318.516 44.3915C317.718 41.421 316.92 38.4504 316.139 35.4968C316.02 35.0725 315.918 34.6481 315.8 34.2237C315.562 33.358 314.578 32.7639 313.712 33.0355C312.846 33.3071 312.286 34.2068 312.524 35.1234Z"
      fill="#B480C5"
    />
    <path
      d="M298.807 51.0116C301.778 51.8094 304.748 52.6072 307.702 53.388L308.975 53.7275C309.824 53.9652 310.859 53.4389 311.063 52.5393C311.267 51.6396 310.791 50.6891 309.875 50.4514C306.904 49.6536 303.934 48.8558 300.98 48.075L299.707 47.7355C298.858 47.4978 297.823 48.0241 297.619 48.9237C297.415 49.8234 297.891 50.7739 298.807 51.0116Z"
      fill="#B480C5"
    />
    <path
      d="M305.751 70.8379C307.924 68.6652 310.096 66.4924 312.269 64.3197C312.575 64.0141 312.88 63.7086 313.203 63.3861C313.831 62.758 313.882 61.6037 313.203 60.9927C312.524 60.3816 311.471 60.3137 310.809 60.9927C308.637 63.1654 306.464 65.3382 304.291 67.5109C303.986 67.8164 303.68 68.122 303.358 68.4445C302.729 69.0726 302.679 70.2268 303.358 70.8379C304.036 71.449 305.089 71.5169 305.751 70.8379Z"
      fill="#B480C5"
    />
    <path
      d="M326.371 74.7252C325.573 71.7546 324.775 68.784 323.995 65.8305C323.876 65.4061 323.774 64.9817 323.655 64.5574C323.418 63.6917 322.433 63.0975 321.567 63.3691C320.702 63.6407 320.141 64.5404 320.379 65.457C321.177 68.4276 321.975 71.3981 322.755 74.3517C322.874 74.7761 322.976 75.2004 323.095 75.6248C323.333 76.4905 324.317 77.0846 325.183 76.813C326.049 76.5414 326.609 75.6418 326.371 74.7252Z"
      fill="#B480C5"
    />
    <path
      d="M340.089 58.8369C337.119 58.0391 334.148 57.2413 331.195 56.4605L329.922 56.121C329.073 55.8833 328.037 56.4095 327.834 57.3092C327.63 58.2089 328.105 59.1594 329.022 59.3971C331.993 60.1949 334.963 60.9927 337.917 61.7735L339.19 62.113C340.038 62.3507 341.074 61.8245 341.278 60.9248C341.481 60.0251 341.006 59.0746 340.089 58.8369Z"
      fill="#B480C5"
    />
    <path
      d="M267.915 7.03042C270.716 9.83123 270.716 14.3804 267.915 17.1812C265.115 19.982 260.565 19.982 257.765 17.1812C254.964 14.3804 254.964 9.83123 257.765 7.03042C260.565 4.22961 265.115 4.22961 267.915 7.03042Z"
      fill="#B480C5"
    />
    <path
      d="M114.751 108.301C114.751 112.256 111.543 115.481 107.571 115.481C103.599 115.481 100.391 112.273 100.391 108.301C100.391 104.329 103.599 101.121 107.571 101.121C111.543 101.121 114.751 104.329 114.751 108.301Z"
      fill="#B480C5"
    />
    <path
      d="M204.124 9.79732C204.124 13.7524 200.916 16.9776 196.944 16.9776C192.972 16.9776 189.764 13.7694 189.764 9.79732C189.764 5.82527 192.972 2.61707 196.944 2.61707C200.916 2.61707 204.124 5.82527 204.124 9.79732Z"
      fill="#B480C5"
    />
    <path
      d="M110.731 160.467L222.414 163.122M123.252 191.684L191.695 193.84M144.27 126.475L250.438 129.17M181.456 90.9065L211.635 92.5232"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M189.693 197.452L257.335 199.372M194.281 217.662L238.593 219.058M211.407 175.445L280.142 177.189M235.481 152.417L276.197 154.642"
      stroke="#303030"
      strokeWidth="4"
    />
    <path
      d="M192.155 88.1181C185.247 95.0268 178.321 101.952 171.412 108.861C160.362 119.912 149.328 130.945 138.278 141.996C135.715 144.559 133.169 147.105 130.605 149.668C127.516 152.757 132.32 157.561 135.409 154.472C142.318 147.563 149.244 140.638 156.152 133.729C167.203 122.678 178.236 111.645 189.287 100.594C191.85 98.0313 194.396 95.4851 196.959 92.922C200.049 89.8326 195.245 85.0288 192.155 88.1181Z"
      fill="#FFFFF9"
    />
  </svg>
);
