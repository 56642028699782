class HttpException extends Error {
  public static NAME = 'HttpException';

  constructor(message: string) {
    super();

    Object.setPrototypeOf(this, HttpException.prototype);
    this.name = HttpException.NAME;
    this.message = message;
  }
}

export default HttpException;
