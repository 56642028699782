import styled from 'styled-components';

export const TelegramIcon = styled(({ large, ...props }: { large?: boolean }) => (
  <svg width={254} height={254} viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx={120.952} cy={127.55} rx={84.6667} ry={83.5671} fill="white" />
    <path
      d="M54.2809 139.092L54.2803 139.092C52.2258 138.358 50.7158 137.603 49.7202 136.647C48.6797 135.648 48.2444 134.474 48.2444 133.052C48.2444 130.9 49.7613 129.21 51.7133 127.845C53.6892 126.463 56.3193 125.267 58.9497 124.151C64.4892 121.794 160.662 80.9593 168.208 78.2388C170.98 77.2397 173.594 76.5331 175.941 76.2772C178.284 76.0217 180.42 76.2085 182.188 77.0586C185.213 78.5078 186.615 81.7199 186.189 86.1667C185.51 93.2306 182.432 114.63 179.235 135.137C177.636 145.395 176.007 155.44 174.63 163.37C173.259 171.265 172.128 177.142 171.523 178.998C171.523 178.999 171.523 178.999 171.523 178.999L170.809 178.766L54.2809 139.092ZM54.2809 139.092C58.5358 140.609 69.2074 143.845 75.5037 145.58C81.7719 147.312 88.8834 145.821 92.9181 143.313C94.9813 142.031 107.842 133.475 120.591 124.905C126.971 120.617 133.329 116.322 138.306 112.925C143.261 109.542 146.891 107.019 147.772 106.3L147.773 106.3M54.2809 139.092L147.773 106.3M147.773 106.3C148.525 105.685 149.173 105.474 149.652 105.465M147.773 106.3L149.652 105.465M149.652 105.465C150.123 105.455 150.474 105.636 150.699 105.901M149.652 105.465L150.699 105.901M150.699 105.901C151.132 106.412 151.283 107.551 150.092 108.745L150.699 105.901ZM127 0.75C196.728 0.75 253.25 57.2716 253.25 127C253.25 196.728 196.728 253.25 127 253.25C57.2716 253.25 0.75 196.728 0.75 127C0.75 57.2716 57.2716 0.75 127 0.75Z"
      fill="black"
      stroke="white"
      strokeWidth={large ? 1.5 : 3}
    />
  </svg>
))<{ large?: boolean }>`
  flex-shrink: 0;
  margin-right: ${({ large }) => (large ? '' : '26px')};
  width: ${({ large }) => (large ? '350px' : '79px')};
  height: ${({ large }) => (large ? '350px' : '79px')};
`;
