import styled from 'styled-components';
import { useState } from 'react';
import { useInterval } from 'react-use';

import { IconCoin } from '../Icons/IconCoin';
import { useApp } from '../../contexts/AppContext';
import { getRoundPercentage, getRoundTimeFormatted } from '../../lib/farming/util';
import { useTranslation } from 'react-i18next';

const ProgressContainer = styled.div`
  color: #fff;
  width: 900px;

  header {
    display: flex;
    flex-direction: column;

    p {
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-family: var(--font), sans-serif;
      }
      &.points {
        span {
          font-size: 36px;
        }
        .value {
          color: yellow;
          align-items: center;
          display: flex;
          font-size: 36px;

          svg {
            margin-top: -10px;
            margin-right: -10px;
          }
        }
      }
      &.time {
        font-size: 36px;

        .value {
          color: #ccc;
          font-size: 36px;
        }
      }
    }
  }
  .bar {
    margin-top: 10px;
    width: 100%;
    height: 35px;
    background: #a0a1a4;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #0e0e0e6d;

    .state {
      width: 70%;
      height: 100%;
      border-radius: 20px;
      justify-content: end;
      display: flex;

      background: linear-gradient(
        90deg,
        rgba(181, 84, 15, 1) 0%,
        rgba(230, 186, 30, 1) 50%,
        rgba(185, 223, 78, 1) 100%
      );
      span {
        color: #333;
        font-size: 30px;
        line-height: 32px;
        text-align: right;
        overflow: hidden;
        padding-right: 10px;
      }
    }
  }
`;

export const Progress = () => {
  const { calculator, app, farmingState } = useApp();
  const [time, setTime] = useState(getRoundTimeFormatted(app));
  const [points, setPoins] = useState(calculator.round_balance);
  const percentage = getRoundPercentage(app);
  const { t } = useTranslation();

  const calculate = () => {
    const time = getRoundTimeFormatted(app);

    setTime(time);
    setPoins(calculator.round_balance);
  };

  useInterval(calculate, 1000);

  return (
    <ProgressContainer>
      <header>
        <p className="points">
          <span>{t('farming.progress.roundBalance')}</span>
          <span className="value">
            {points.toFixed(0)}
            <IconCoin />
          </span>
        </p>
        <p className="time">
          <span>{t('farming.progress.roundTime')}</span>
          <span className="value">{time}</span>
        </p>
      </header>
      <div className="bar">
        <div className={`state ${farmingState}`} style={{ width: `${100 - percentage}%` }}>
          <span>{t('farming.progress.roundMentalEnergy')}</span>
        </div>
      </div>
    </ProgressContainer>
  );
};
