import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  @keyframes pulseCloud {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1.15);
    }

    100% {
      transform: scale(0.95);
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 700px;
  margin-left: -120px;
  background: none;
  border: 0;

  animation: pulseCloud 5s infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  img {
    position: absolute;
    opacity: 0;
    width: 1000px;

    &.active {
      opacity: 1;
    }
  }
`;

const MeerkatCloud = () => {
  const [i, setIndex] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => setIndex((i) => (i === 6 ? 1 : i + 1)), 300);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <img src={`/img/animation/cloud1.webp`} className={i === 1 ? 'active' : ''} />
      <img src={`/img/animation/cloud1.webp`} className={i === 2 ? 'active' : ''} />
      <img src={`/img/animation/cloud1.webp`} className={i === 3 ? 'active' : ''} />
      <img src={`/img/animation/cloud2.webp`} className={i === 4 ? 'active' : ''} />
      <img src={`/img/animation/cloud3.webp`} className={i === 5 ? 'active' : ''} />
      <img src={`/img/animation/cloud4.webp`} className={i === 6 ? 'active' : ''} />
    </Container>
  );
};

export default MeerkatCloud;
