import { useApp } from '../contexts/AppContext';

import FarmingStopped from '../components/pages/Home/FarmingStopped';
import FarmingClaimed from '../components/pages/Home/FarmingClaimed';
import FarmingTurbo from '../components/pages/Home/FarmingTurbo';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { farmingState } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    const isWelcomePassed = localStorage.getItem('welcome');

    if (!isWelcomePassed) navigate('/welcome');
  }, []);

  if (farmingState === 'stopped') {
    return <FarmingStopped />;
  } else if (farmingState === 'claimed') {
    return <FarmingClaimed />;
  }

  return <FarmingTurbo />;
};

export default Home;
