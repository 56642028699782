import styled, { keyframes } from 'styled-components';
import React from 'react';

export const rotateAnimation = keyframes`
  0% {
      transform: rotate(0deg) scaleY(-1);
  }
  100% {
    transform: rotate(360deg) scaleY(-1);
  }
`;

const RefreshIcon = styled(({ disabled, isLoading, className, ...props }) => (
  <svg
    className={`${isLoading ? 'loading ' : ''} ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    width={67}
    height={67}
    viewBox="0 0 67 67"
    fill="none"
    {...props}
  >
    <rect
      x={0.75}
      y={0.75}
      width={65.5}
      height={65.5}
      rx={32.75}
      fill="#000000c4"
      stroke={disabled ? '#999' : '#FAFF00'}
      strokeWidth={1.5}
    />
    <path
      d="M50.7053 41.3008C50.5901 40.6481 49.9677 40.2124 49.315 40.3276L38.6793 42.2045C38.0267 42.3197 37.591 42.9421 37.7061 43.5948C37.8213 44.2474 38.4438 44.6831 39.0964 44.568L48.5503 42.8996L50.2187 52.3535C50.3339 53.0062 50.9563 53.4419 51.609 53.3267C52.2616 53.2116 52.6973 52.5891 52.5822 51.9365L50.7053 41.3008ZM16.855 35.772C16.7653 35.1153 16.1603 34.6557 15.5037 34.7454C14.847 34.835 14.3874 35.44 14.477 36.0967L16.855 35.772ZM48.5405 40.8212C46.0154 44.4284 41.3836 50.1329 33.3369 50.1329V52.5329C42.6728 52.5329 47.9348 45.8715 50.5066 42.1975L48.5405 40.8212ZM33.3369 50.1329C25.2398 50.1329 17.9607 43.8698 16.855 35.772L14.477 36.0967C15.7467 45.3951 24.0515 52.5329 33.3369 52.5329V50.1329Z"
      fill={disabled ? '#999' : '#FAFF00'}
    />
    <path
      d="M16.3007 25.6993C16.4159 26.3519 17.0383 26.7877 17.691 26.6725L28.3266 24.7956C28.9793 24.6804 29.415 24.058 29.2998 23.4053C29.1847 22.7527 28.5622 22.3169 27.9095 22.4321L18.4556 24.1005L16.7873 14.6465C16.6721 13.9939 16.0497 13.5582 15.397 13.6733C14.7443 13.7885 14.3086 14.411 14.4238 15.0636L16.3007 25.6993ZM49.8096 31.2026C49.9 31.8592 50.5054 32.3182 51.162 32.2279C51.8185 32.1376 52.2776 31.5321 52.1873 30.8756L49.8096 31.2026ZM18.4655 26.1789C21.0123 22.5406 25.3064 16.8611 33.3344 16.8611V14.4611C23.9799 14.4611 19.0494 21.1597 16.4993 24.8026L18.4655 26.1789ZM33.3344 16.8611C41.4314 16.8611 48.6975 23.1173 49.8096 31.2026L52.1873 30.8756C50.9103 21.592 42.62 14.4611 33.3344 14.4611V16.8611Z"
      fill={disabled ? '#999' : '#FAFF00'}
    />
  </svg>
))`
  width: 80px;
  height: 80px;
  &.loading {
    animation: ${rotateAnimation} 2s infinite linear;
  }
`;

export default RefreshIcon;
