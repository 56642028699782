import { notificationEmit } from '../NotificationsController';
import { TFunction } from 'i18next';

// @see https://stackoverflow.com/a/30120481/2235085
let IS_ONLINE = true;

export const onlineStatusInit = (t: TFunction) => {
  window.addEventListener('online', () => {
    if (IS_ONLINE) return;

    IS_ONLINE = true;

    notificationEmit({
      title: t('notifications.connection.restored_title'),
      subtitle: t('notifications.connection.restored_subtitle'),
    });
  });
  window.addEventListener('offline', async () => {
    if (!IS_ONLINE) return;

    IS_ONLINE = false;

    notificationEmit({
      title: t('notifications.connection.lost_title'),
      subtitle: t('notifications.connection.lost_subtitle'),
    });
  });
};
