import React from 'react';
import styled from 'styled-components';
import Row from '../../Row';
import { useTranslation } from 'react-i18next';
import { amountWithSpaces, filterSpecialCharacters } from '../../../lib/utils';
import { IconCoin } from '../../Icons/IconCoin';
import IconUser from '../../Icons/IconUser';
import { Leader } from '../../../lib/types';
import { useApp } from '../../../contexts/AppContext';

const Container = styled.div<{ isCurrentUser: boolean }>`
  color: white;
  height: 130px;
  width: 770px;

  border-radius: 105px;
  border: 2px solid ${({ isCurrentUser }) => (isCurrentUser ? '#FAFF00' : 'rgba(235, 235, 235, 0.61)')};
  background: rgba(50, 54, 60, 0.64);

  padding: 0 40px 0 30px;
  display: flex;
  margin: 10px auto;
  align-items: center;
`;

const Name = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 32px;

  & a {
    color: black;
  }
`;

const Balance = styled.div`
  margin-left: 5px;
  font-size: 40px;
`;

const Place = styled.div`
  font-size: 80px;
  opacity: 0.5;
`;

const LeaderCard = ({ leader, place, length = 100 }: { leader: Leader; place?: number; length?: number }) => {
  const { user } = useApp();
  const { t } = useTranslation();
  const name = leader.displayAs || `${t('system.user')} ${leader.id}`;
  const isCurrentUser = user.id === leader.id;

  return (
    <Container isCurrentUser={isCurrentUser}>
      <Row spread={true} style={{ width: '100%' }}>
        <Row gap="58px">
          {!isCurrentUser && <IconUser />}
          {isCurrentUser && <img alt="leader" src="/img/userLeaderboard.png" height="79" />}
          <div>
            <Name>{filterSpecialCharacters(name)}</Name>
            <Row style={{ justifyContent: 'left' }} gap="20px">
              <Balance>{amountWithSpaces(leader.balance)}</Balance>
              <IconCoin />
            </Row>
          </div>
        </Row>
        <Row>
          <Place>{place ? place : `${length}+`}</Place>
        </Row>
      </Row>
    </Container>
  );
};

export default LeaderCard;
