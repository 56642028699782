import moment from 'moment';

import { MainButton } from '../../components/MainButton';
import { IconCalm } from '../../components/Icons/IconCalm';
import { IconCoin } from '../../components/Icons/IconCoin';
import { BoostPageContainer } from '../../components/Boosts/Wrappers';
import { useApp } from '../../contexts/AppContext';
import { getFuelLevelNext } from '../../lib/farming/util';
import MeerkatHandAnimated from '../../components/Icons/MeerkatHandAnimated ';
import { shouldDisableBoost } from '../../lib/utils';
import BoostCondtions from '../../components/pages/Boost/BoostCondtions';
import { useTranslation } from 'react-i18next';

const BoostCalm = () => {
  const { app, userUpgrade, isBusy, farmingState } = useApp();
  const { t } = useTranslation();

  const levelNext = getFuelLevelNext(app);
  const handleUpgrade = () => userUpgrade('fuel');
  const isDisabled = shouldDisableBoost(app, levelNext, isBusy, farmingState);

  const m = moment(0).utc().add(levelNext.duration, 'seconds');
  const hh = m.format('H');
  const mm = moment(0).utc().add(levelNext.duration, 'seconds').format('m');
  const ss = moment(0).utc().add(levelNext.duration, 'seconds').format('s');

  return (
    <BoostPageContainer>
      <p className="upgrade">{t('boosts.upgrade') + ' ' + t('boosts.calm.mentalEnergy')}</p>
      <p className="level">
        {t('boosts.toLevel')} {levelNext.level}
      </p>

      <p className="duration">
        {t('boosts.run')} {t('boosts.for')}&nbsp;
        <b>{t('boosts.calm.duration', { hours: hh, minutes: mm, seconds: ss })}</b>
        &nbsp;{t('boosts.calm.inRound')}
      </p>

      <IconCalm />
      <p className="price">
        {levelNext.price.toLocaleString('us')} <IconCoin />
      </p>

      <BoostCondtions levelNext={levelNext} />

      <MainButton onClick={handleUpgrade} disabled={isDisabled}>
        {t('boosts.levelUp')}
        {!isDisabled && <MeerkatHandAnimated />}
      </MainButton>
    </BoostPageContainer>
  );
};

export default BoostCalm;
