import React from 'react';
import styled from 'styled-components';

import Row from '../../Row';
import { IconDone } from '../../Icons/IconDone';

const Container = styled.div<{ done?: boolean }>`
  height: 130px;
  width: 780px;
  border-radius: 84px;
  border: ${({ done }) => (done ? '1.5px solid #FAFF00' : '1.5px solid #ebebeb9c')};
  background: rgba(50, 54, 60, 0.64);

  padding: 0 33px;
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  color: #faff00;
  width: 510px;
  font-size: 32px;
  font-family: var(--font), sans-serif;
  margin-left: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Option = ({
  label,
  selected = false,
  onClick,
}: {
  label: string;
  selected: boolean;
  onClick: () => Promise<void>;
}) => {
  return (
    <Container done={selected} onClick={onClick}>
      <Row spread={true} style={{ width: '100%' }}>
        <Row>
          <Label>{label}</Label>
        </Row>
        {selected && <IconDone />}
      </Row>
    </Container>
  );
};

export default Option;
