import styled from 'styled-components';

import MeerkatHandAnimated from '../../../components/Icons/MeerkatHandAnimated ';

const Container = styled.div`
  margin: 0;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
  .overlay {
    background: #00000020;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 2000px;
    z-index: 1;
  }

  .popup {
    position: absolute;
    left: 80px;
    width: 550px;

    z-index: 2;
    color: #ccc;
    background: #333;
    position: absolute;
    left: 80px;
    top: 40px;
    padding: 40px;
    border-radius: 60px;
    border: 1.5px solid #efff00;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 54px 55px, rgba(0, 0, 0, 0.5) 0px -12px 30px, rgba(0, 0, 0, 0.5) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    .heading {
      font-size: 56px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #fff;
      line-height: 1.3;
      font-style: italic;
      text-align: justify;
    }
    .text {
      font-size: 36px;
      color: #c4c4c4;
      line-height: 1.2;
      font-style: italic;
      text-align: justify;
      margin-bottom: 20px;
    }
    .button {
      position: absolute;
      right: 50px;

      button {
        height: 100px;
        background: #333;
        color: #efff00;
        border: 1.5px solid #efff00;

        font-family: var(--font), sans-serif;
        border-radius: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-size: 42px;
        font-weight: 500;
        padding: 0 40px;
      }
      .meerkatHand {
        position: absolute;
        right: -50px;
        top: 50px;
      }
    }

    // Screen 1
    &.id-welcome-0 {
      top: 300px;
      width: 520px;
    }
    &.id-welcome-1 {
      top: 600px;
      left: auto;
      right: 80px;
      width: 520px;
    }
    &.id-welcome-2 {
      top: 1000px;
      width: 520px;
    }
    &.id-welcome-3 {
      top: 1300px;
      left: 80px;
      width: 830px;
    }

    // Screen 2
    &.id10 {
      top: 300px;
    }
    &.id11 {
      top: 600px;
      left: auto;
      right: 80px;
    }
    &.id12 {
      top: 900px;
    }
    // Screen 3
    &.id20 {
      top: 1300px;
      left: 80px;
      width: 830px;
    }
  }
`;

const IntroCard = ({
  image,
  screenId,
  introId,
  onClick,
  buttonText,
  heading,
  text,
}: {
  screenId: string | number;
  introId: string | number;
  image: string;
  onClick: Function;
  buttonText: string;
  heading: string;
  text: string;
}) => {
  return (
    <Container className={`introCard-${screenId}-${introId}`}>
      <img src={image} />
      <div className="overlay" />
      <div className={`popup id-${screenId}-${introId}`}>
        <p className="heading">{heading}</p>
        <p className="text">{text}</p>
        <div className="button" onClick={() => onClick()}>
          <button>{buttonText}</button>
          <MeerkatHandAnimated />
        </div>
      </div>
    </Container>
  );
};

export default IntroCard;
