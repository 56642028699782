export const IconFireworksStarA = () => (
  <svg
    className="fireworksStarA"
    width="221"
    height="221"
    viewBox="0 0 221 221"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M140.968 178.653C140.648 178.759 140.302 178.892 139.982 178.999C138.997 179.531 137.985 180.011 136.973 180.464L147.519 212.263C148.238 214.474 150.689 215.592 152.846 214.713C154.95 213.861 155.935 211.411 154.976 209.334L140.994 178.653H140.968Z"
      fill="#EDD500"
    />
    <path
      d="M79.8968 41.9998C80.5626 41.7601 81.2551 41.5204 81.9209 41.2807C82.5867 40.9345 83.2792 40.5882 83.9716 40.2686L73.5049 8.70882C72.7859 6.4983 70.3356 5.37972 68.1784 6.25861C66.0744 7.11085 65.089 9.56107 66.0478 11.6384L79.8968 41.9731V41.9998Z"
      fill="#EDD500"
    />
    <path
      d="M211.811 148.957L180.704 137.345C180.411 137.958 180.091 138.57 179.772 139.183C179.532 139.929 179.266 140.674 178.973 141.42L208.588 156.281C210.666 157.32 213.169 156.388 214.075 154.257C214.954 152.153 213.915 149.729 211.784 148.93L211.811 148.957Z"
      fill="#EDD500"
    />
    <path
      d="M42.1054 81.1233C42.2385 80.6972 42.3983 80.2711 42.5315 79.845L12.3832 64.7175C10.3058 63.6789 7.80237 64.611 6.89685 66.7416C6.01797 68.8456 7.05665 71.2692 9.18727 72.0682L40.7737 83.8665C41.1999 82.961 41.626 82.0555 42.1054 81.15V81.1233Z"
      fill="#EDD500"
    />
    <path
      d="M214.71 68.1799C213.858 66.0759 211.407 65.0905 209.33 66.0492L179.768 79.5254C180.38 80.8837 180.939 82.2952 181.472 83.7068L212.26 73.5064C214.47 72.7873 215.589 70.3371 214.71 68.1799Z"
      fill="#EDD500"
    />
    <path
      d="M40.5346 136.946L8.73506 147.492C6.52455 148.211 5.40597 150.662 6.28485 152.819C7.1371 154.923 9.58731 155.908 11.6647 154.949L42.2125 141.02C41.6265 139.689 41.0406 138.331 40.5346 136.972V136.946Z"
      fill="#EDD500"
    />
    <path
      d="M141.711 41.3873L156.279 12.3842C157.318 10.3068 156.386 7.80334 154.255 6.89783C152.151 6.01895 149.728 7.05763 148.929 9.18825L137.557 39.6828C138.942 40.2154 140.326 40.8013 141.711 41.4139V41.3873Z"
      fill="#EDD500"
    />
    <path
      d="M79.7105 178.706L64.6896 208.614C63.6509 210.692 64.5831 213.195 66.7137 214.101C68.8177 214.98 71.2412 213.941 72.0402 211.81L83.7586 180.41C82.4004 179.878 81.0421 179.318 79.7105 178.706Z"
      fill="#EDD500"
    />
    <path
      d="M117.374 126.292L132.928 160.409C133.407 161.448 132.928 162.699 131.836 163.126C130.744 163.552 129.519 162.992 129.146 161.874L117.348 126.266L117.374 126.292Z"
      fill="#FAFF00"
    />
    <path
      d="M103.604 94.7061L88.0508 60.5895C87.5714 59.5508 88.0508 58.2991 89.1428 57.873C90.2347 57.4202 91.4598 58.0061 91.8327 59.1247L103.631 94.7327L103.604 94.7061Z"
      fill="#FAFF00"
    />
    <path
      d="M126.533 116.785L161.662 129.915C162.754 130.314 163.26 131.539 162.807 132.605C162.354 133.67 161.076 134.149 160.037 133.617L126.533 116.785Z"
      fill="#FAFF00"
    />
    <path
      d="M94.4707 104.214L59.3421 91.084C58.2501 90.6845 57.7441 89.4594 58.1969 88.3941C58.6496 87.3288 59.928 86.8494 60.9667 87.3821L94.4707 104.214Z"
      fill="#FAFF00"
    />
    <path
      d="M126.297 103.601L160.413 88.0479C161.452 87.5685 162.704 88.0479 163.13 89.1398C163.556 90.2318 162.997 91.4569 161.905 91.8297L126.297 103.628V103.601Z"
      fill="#FAFF00"
    />
    <path
      d="M94.7075 117.397L60.591 132.951C59.5523 133.43 58.3006 132.951 57.8744 131.859C57.4217 130.767 58.0076 129.542 59.1262 129.169L94.7342 117.371L94.7075 117.397Z"
      fill="#FAFF00"
    />
    <path
      d="M116.785 94.4664L129.915 59.3378C130.315 58.2459 131.54 57.7399 132.605 58.1926C133.67 58.6454 134.15 59.9237 133.617 60.9624L116.785 94.4664Z"
      fill="#FAFF00"
    />
    <path
      d="M104.211 126.532L91.0809 161.661C90.6814 162.753 89.4563 163.259 88.3909 162.806C87.3256 162.353 86.8463 161.075 87.3789 160.036L104.211 126.532Z"
      fill="#FAFF00"
    />
    <path
      d="M134.786 133.59L188.505 182.967C190.156 184.485 190.209 187.069 188.611 188.64C187.013 190.238 184.377 190.158 182.859 188.453L134.76 133.59H134.786Z"
      fill="#B480C5"
    />
    <path
      d="M86.2114 87.4088L32.4932 38.0583C30.8419 36.5402 30.7887 33.9568 32.3866 32.3855C33.9846 30.7875 36.6212 30.8674 38.1393 32.5719L86.2381 87.4354L86.2114 87.4088Z"
      fill="#B480C5"
    />
    <path
      d="M144.004 109.647L216.898 106.558C219.135 106.451 220.999 108.262 220.999 110.499C220.999 112.763 219.082 114.574 216.791 114.441L144.004 109.647Z"
      fill="#B480C5"
    />
    <path
      d="M76.9953 111.352L4.10145 114.441C1.86429 114.548 0 112.736 0 110.499C0 108.236 1.91756 106.425 4.20798 106.558L77.022 111.352H76.9953Z"
      fill="#B480C5"
    />
    <path
      d="M133.592 86.2102L182.969 32.4919C184.487 30.8407 187.07 30.7874 188.642 32.3854C190.24 33.9834 190.16 36.62 188.455 38.1381L133.592 86.2369V86.2102Z"
      fill="#B480C5"
    />
    <path
      d="M87.4081 134.788L38.0309 188.507C36.5129 190.158 33.9295 190.211 32.3582 188.613C30.7602 187.015 30.8401 184.379 32.5446 182.861L87.4081 134.762V134.788Z"
      fill="#B480C5"
    />
    <path
      d="M109.647 76.9953L106.557 4.10145C106.451 1.86429 108.262 0 110.499 0C112.789 0 114.574 1.91756 114.441 4.20798L109.647 77.022V76.9953Z"
      fill="#B480C5"
    />
    <path
      d="M111.354 144.003L114.443 216.897C114.549 219.134 112.738 220.999 110.501 220.999C108.238 220.999 106.426 219.081 106.56 216.791L111.354 143.977V144.003Z"
      fill="#B480C5"
    />
  </svg>
);
