import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IntroCard from '../components/pages/Welcome/IntroCard';

const images = [
  '/img/help/farming-start.webp',
  '/img/help/farming-turbo.webp',
  '/img/help/boost-list.webp',
  '/img/help/friends.png',
];

// 1 тапни по сурикату чтобы активировать инсайт и увеличить доход на время его действия
// 2 прокачивай суриката. Исследуй механики бустов
// 3 приглашай друзей и выполняй задачи чтобы открыть новые бусты и получать пассивный доход

const screenId = 'welcome';

const Welcome = () => {
  const [introId, setIntro] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNextInfo = (i: number) => {
    setIntro(i);
  };

  const handleWelcomeComplete = () => {
    localStorage.setItem('welcome', 'YES');
    navigate('/');
  };

  if (introId === 0) {
    return (
      <IntroCard
        screenId={screenId}
        introId={introId}
        image={images[1]}
        buttonText={t('welcome.okay')}
        onClick={() => handleNextInfo(1)}
        heading={t('welcome.useInsights.heading')}
        text={t('welcome.useInsights.text')}
      />
    );
  }
  if (introId === 1) {
    return (
      <IntroCard
        screenId={screenId}
        introId={introId}
        image={images[2]}
        buttonText={t('welcome.okay')}
        onClick={() => handleNextInfo(2)}
        heading={t('welcome.makeUpgrades.heading')}
        text={t('welcome.makeUpgrades.text')}
      />
    );
  }
  if (introId === 2) {
    return (
      <IntroCard
        screenId={screenId}
        introId={introId}
        image={images[3]}
        buttonText={t('welcome.okay')}
        onClick={() => handleNextInfo(3)}
        heading={t('welcome.inviteFriends.heading')}
        text={t('welcome.inviteFriends.text')}
      />
    );
  }

  return (
    <IntroCard
      screenId={screenId}
      introId={3}
      image={images[0]}
      buttonText={t('welcome.startGame')}
      onClick={handleWelcomeComplete}
      heading={t('welcome.meerkatCoins.heading')}
      text={t('welcome.meerkatCoins.text')}
    />
  );
};

export default Welcome;
