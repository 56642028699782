import { ITask } from './types';

export type Event = null | CloudEvent | SpeedEvent | LuckEvent | CalmEvent | TaskCompleteEvent;

export type CloudEvent = {
  type: 'turbo';
};

export type SpeedEvent = {
  type: 'speed';
};

export type CalmEvent = {
  type: 'fuel';
};

export type LuckEvent = {
  type: 'luck';
};

export type TaskCompleteEvent = {
  type: 'taskComplete';
  task: ITask;
};

export const isCalmEvent = (event: Event): event is CalmEvent => event?.type === 'fuel';
export const isLuckEvent = (event: Event): event is LuckEvent => event?.type === 'luck';
export const isCloudEvent = (event: Event): event is CloudEvent => event?.type === 'turbo';
export const isSpeedEvent = (event: Event): event is SpeedEvent => event?.type === 'speed';
export const isTaskCompleteEvent = (event: Event): event is TaskCompleteEvent => event?.type === 'taskComplete';
