import ButtonContainer from './ButtonContainer';
import IconContainer from './IconContainer';

export const Icon = () => (
  <svg width="95" height="102" viewBox="0 0 95 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_141_888)">
      <g opacity="0.3">
        <path d="M15.6194 6.97552L14.3754 7.99182L3.87024 7.76084L3.45557 5.86682L15.6194 6.97552Z" fill="#808080" />
      </g>
      <g opacity="0.2">
        <path
          d="M29.4881 56.7744C29.4881 56.7744 29.442 56.8668 29.3959 56.8668C28.8891 57.4674 28.3823 57.9293 27.7833 58.2065C27.2765 58.4837 26.7236 58.576 26.1707 58.576C25.8942 58.576 25.6178 58.576 25.3413 58.4837L26.1246 57.9293L29.4881 56.7744Z"
          fill="#808080"
        />
      </g>
      <path
        d="M32.0222 46.1032C31.5154 44.2554 30.4556 42.9619 29.0734 42.3614C28.843 42.269 28.6126 42.1766 28.3823 42.1304C22.715 40.2364 17.6928 36.5407 13.7304 31.644C13.0853 30.8586 12.4864 30.0733 11.9335 29.2418C11.2884 28.3179 10.6894 27.3478 10.0905 26.3315C8.7082 23.8831 7.51025 21.2038 6.58875 18.3858C5.48294 15.0597 4.74574 11.5027 4.42322 7.80702L14.3294 7.9918C14.4676 10.0244 14.9744 13.4891 17.0478 16.1222C18.1075 17.4619 19.3976 18.432 20.9181 18.9864C21.8396 19.3097 23.6826 19.8179 24.7423 19.8641L24.558 18.9864C24.558 18.9864 24.6502 18.9864 24.6963 18.9864L24.8345 15.7527C23.6826 15.7065 22.6229 15.4293 21.7474 14.9673C21.0563 14.5978 20.4113 14.0434 19.9044 13.3967C17.6468 10.5326 17.8771 6.00539 17.8771 5.95919L17.9693 4.29615L1.29011 3.97278L0.506836 4.52713L0.598986 6.28256C0.967587 13.4891 2.76451 20.326 5.66724 26.2391C6.72697 28.3641 7.87885 30.3967 9.21503 32.2907C9.814 33.1222 10.413 33.9076 11.058 34.6929C11.657 35.3858 12.2099 36.0788 12.855 36.7255C16.8174 40.9293 21.5631 44.1168 26.8618 45.8722H26.9539C27.2304 45.9646 28.1058 46.1494 28.5205 47.7201C29.1195 49.8451 28.5205 53.2174 27.2765 54.7418L25.2952 58.5299C25.5717 58.6222 25.8481 58.6222 26.1246 58.6222C26.6775 58.6222 27.2304 58.4837 27.7372 58.2527C27.9676 58.1603 29.6263 56.9592 30.087 56.3587C32.0222 53.9565 32.8515 49.3831 31.93 46.1494L32.0222 46.1032Z"
        fill="#B48EC0"
        stroke="#303030"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <g opacity="0.3">
        <path
          d="M30.179 56.3125C29.6722 56.9131 29.1654 57.375 28.5664 57.6522C31.6074 51.7854 30.133 45.4566 29.0732 42.3615C30.4555 42.962 31.5152 44.2555 32.022 46.1033C32.9435 49.337 32.1142 53.9104 30.179 56.3125Z"
          fill="#808080"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M21.7933 15.4756C22.6687 15.9375 23.7285 16.2147 24.8804 16.2609L24.7421 19.4946C23.6363 19.4484 22.6227 19.2636 21.7012 18.8941L21.7933 15.4756Z"
          fill="#808080"
        />
      </g>
      <g opacity="0.2">
        <path d="M90.9524 8.82343L89.6162 9.56256L78.9268 9.33159V7.80713L90.9524 8.82343Z" fill="#808080" />
      </g>
      <path
        d="M77.2219 5.4973L76.1161 6.00545V7.66849C76.1161 7.66849 76.1161 12.2881 73.8123 15.0136C73.3516 15.568 72.7987 15.9375 72.1997 16.2609C71.5086 16.4919 70.7714 16.6304 69.896 16.6304V17.0462C69.5274 17.0924 69.1588 17.1386 68.7902 17.1848V20.4185C69.8499 20.4185 70.8635 20.2799 71.785 19.9565C72.1997 19.818 72.5222 19.587 72.8908 19.4484C74.5035 18.9402 75.8396 18.0625 76.9454 16.7228C78.9267 14.3669 79.6639 11.318 79.8942 9.28534L89.6161 9.47012C89.2475 12.2881 88.6485 15.0598 87.8652 17.6929C87.3584 19.3098 86.8055 20.8343 86.1605 22.3587C85.6076 23.6984 84.9625 24.9919 84.3175 26.1929C83.3038 28.0408 82.1519 29.8424 80.9079 31.4593C77.7287 35.6631 73.7663 38.9891 69.3891 41.2527C68.053 41.8533 66.7168 42.4076 65.2884 42.8234C65.1502 42.8234 65.012 42.9158 64.8738 42.9158C64.4591 43.0544 64.0905 43.2391 63.768 43.4239C62.2475 43.8859 61.0495 45.1794 60.4045 47.1196C59.3448 50.3071 59.9898 54.9266 61.8328 57.4212C62.1093 57.7446 62.3396 58.0679 62.6161 58.2989C62.9847 58.6223 63.3533 58.8533 63.7219 59.0381C64.1366 59.2228 64.5512 59.269 64.9659 59.3152C65.2424 59.3152 65.5188 59.3152 65.7953 59.2228L66.9011 58.7147L65.0581 54.8343C64.8738 54.5571 64.6895 54.2337 64.5052 53.8641C63.7219 52.1087 63.5376 49.5218 64.0905 47.7663C64.3209 47.1196 64.5973 46.7038 64.8738 46.4728C66.5785 45.9647 68.2372 45.318 69.8038 44.5326C74.3192 42.5462 78.3738 39.5435 81.8755 35.7093C83.0734 34.3696 84.2253 32.9375 85.239 31.4131C85.884 30.4891 86.483 29.519 87.082 28.5027C87.6809 27.4864 88.2338 26.4701 88.7407 25.3614C91.3669 20.0027 93.0717 13.9511 93.6707 7.48371L93.8089 5.72828L77.1297 5.40491L77.2219 5.4973Z"
        fill="#B48EC0"
        stroke="#303030"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <g opacity="0.3">
        <path
          d="M63.0768 58.0218C62.8003 57.7908 62.5239 57.4674 62.2935 57.144C60.4505 54.6495 59.529 51.0924 60.5426 47.9049C61.1877 45.9647 63.3071 43.5163 64.8276 43.0082C63.5375 45.6875 60.6809 51.7854 63.0768 57.9756V58.0218Z"
          fill="#808080"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M72.9366 16.076L72.8445 19.4945C71.923 19.7717 70.9093 19.9103 69.8496 19.9565V16.7228C71.0015 16.7228 72.0151 16.4918 72.8906 16.076H72.9366Z"
          fill="#808080"
        />
      </g>
      <path
        d="M71.5085 3.23366C71.5085 4.61953 71.4163 6.05159 71.3242 7.52985C71.3242 8.17659 71.232 8.82333 71.1859 9.47007C70.4948 18.8016 68.744 29.9347 65.6569 39.4972C61.6023 52.0163 55.3361 61.9021 46.2593 61.7174C30.0409 61.394 24.005 29.1494 23.4521 7.52985C23.4521 7.20648 23.4521 6.88311 23.4521 6.55974C23.4521 5.08148 23.4521 3.60322 23.4521 2.26355L71.4624 3.23366H71.5085Z"
        fill="#FAFF00"
        stroke="#303030"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M65.6569 39.4972C62.7542 48.5516 58.6535 56.2201 53.1706 59.6847C51.0511 61.0244 48.7474 61.7636 46.2593 61.7174C30.0409 61.394 24.005 29.1494 23.4521 7.52985C23.4521 7.20648 23.4521 6.88311 23.4521 6.55975C23.4521 5.08148 23.4521 3.60322 23.4521 2.26355L38.6109 2.58692L37.8276 5.68203C37.8276 5.68203 38.7951 66.9375 65.6109 39.4972H65.6569Z"
        fill="#EDD500"
      />
      <path
        d="M71.5085 3.23366C71.5085 4.61953 71.4163 6.05159 71.3242 7.52985C71.3242 8.17659 71.232 8.82333 71.1859 9.47007C70.4948 18.8016 68.744 29.9347 65.6569 39.4972C61.6023 52.0163 55.3361 61.9021 46.2593 61.7174C30.0409 61.394 24.005 29.1494 23.4521 7.52985C23.4521 7.20648 23.4521 6.88311 23.4521 6.55975C23.4521 5.08148 23.4521 3.60322 23.4521 2.26355L71.4624 3.23366H71.5085Z"
        stroke="#303030"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <g opacity="0.3">
        <path
          d="M71.5083 3.23366C71.5083 4.61953 71.4161 6.05159 71.324 7.52985C71.324 8.17659 71.2318 8.82333 71.1858 9.47007C66.8547 8.77714 55.1516 7.20648 39.3018 6.88311C29.3035 6.69833 25.1568 7.0679 23.498 7.52985C23.498 7.20648 23.498 6.88311 23.498 6.55974C23.498 5.08148 23.498 3.60322 23.498 2.26355L71.5083 3.23366Z"
          fill="#808080"
        />
      </g>
      <path
        d="M74.5495 1.70928L74.0887 3.23374L73.3055 5.95928L21.5631 4.89678L20.5034 0.600586L74.5495 1.70928Z"
        fill="#B48EC0"
        stroke="#303030"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M54.3227 63.75C54.3227 63.75 54.1384 63.8886 53.862 64.1657C53.4012 64.6277 52.664 65.3668 52.1111 66.1059C51.6965 66.7065 51.3739 67.307 51.3279 67.769C51.3279 69.663 51.0053 71.4184 50.5907 72.9891C50.4064 73.5896 50.2221 74.144 50.0378 74.6983C50.0378 74.7907 49.9917 74.8369 49.9456 74.9293C49.8535 75.1603 49.7613 75.3451 49.6692 75.5761C48.7016 77.5625 47.3654 78.7636 45.9371 78.7174C45.1538 78.7174 44.4166 78.3478 43.7716 77.6549C43.0804 76.9619 42.5275 75.9918 42.0668 74.7445C41.3296 72.8043 40.9149 70.2636 40.961 67.538C40.961 66.0597 38.1504 63.3804 38.1504 63.3804V63.288L39.3483 59.3152L53.263 59.5924L53.7698 61.625L54.1384 63.1032L54.2767 63.6114V63.7038L54.3227 63.75Z"
        fill="#FAFF00"
        stroke="#303030"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M54.3226 63.6576L38.1963 63.3343L39.3942 59.3615L53.2628 59.6386L53.8157 61.7174L54.1843 63.1495L54.3226 63.6576Z"
        fill="#B48EC0"
        stroke="#303030"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <g opacity="0.2">
        <path
          d="M43.7716 77.7011C42.0207 75.9456 40.8688 72.0652 40.961 67.6304C40.961 66.1522 38.1504 63.4728 38.1504 63.4728L39.3483 59.4076L53.263 59.6848C53.263 59.6848 39.164 60.1005 42.2511 63.981C42.4354 64.6277 44.6931 64.8587 44.6931 64.8587C44.7852 64.7663 42.3893 74.144 45.1999 74.8831C46.5361 75.2527 45.3381 76.5 43.7716 77.7011Z"
          fill="#808080"
        />
      </g>
      <path
        d="M57.6397 91.0054L33.0356 90.5897L39.3019 70.5408L52.5715 70.8179L52.6176 71.1875L57.6397 91.0054Z"
        fill="#FAFF00"
        stroke="#303030"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M56.6264 92.5299C55.3363 92.3914 53.9541 92.299 52.5257 92.1604C50.7749 92.0218 48.8858 91.9294 46.9967 91.8832C46.4899 91.8832 45.937 91.8832 45.4302 91.8832C44.5087 91.8832 43.6332 91.8832 42.7117 91.8832C40.7305 91.8832 38.8414 91.8832 37.0445 91.9756C36.0309 91.9756 35.0633 92.068 34.0957 92.1142L34.4643 90.5897L56.3039 91.0517L56.396 91.4674L56.6264 92.5761V92.5299Z"
        fill="url(#paint0_linear_141_888)"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20.3297 90.0214L20.1172 100.367L70.1902 101.401L70.4027 91.0555L20.3297 90.0214Z"
        fill="#B48EC0"
        stroke="#303030"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.0017 71.326L41.8361 89.8043H41.5136L34.1416 89.7119L40.0853 71.2336L44.0017 71.326Z"
        fill="#EDD500"
      />
      <g opacity="0.2">
        <path
          d="M43.9096 5.35867L21.5631 4.89671L20.5034 0.554321L30.087 0.739104C29.0273 1.47823 25.9864 4.57334 43.8635 5.31247L43.9096 5.35867Z"
          fill="#808080"
        />
      </g>
      <g opacity="0.2">
        <path d="M24.7884 4.98917L21.5631 4.89678L20.5034 0.600586L23.2218 0.646782L24.7884 4.98917Z" fill="#808080" />
      </g>
      <g opacity="0.2">
        <path
          d="M70.2184 101.399L20.1348 100.383L20.2269 90.4049H33.0358C33.0358 90.4049 20.5034 100.429 70.2184 101.446V101.399Z"
          fill="#808080"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_141_888"
        x1="38.1072"
        y1="98.5746"
        x2="60.6816"
        y2="99.0383"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA526" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <clipPath id="clip0_141_888">
        <rect width="94.5" height="102" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Goblet = ({ active = false }) => {
  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
    </ButtonContainer>
  );
};

export default Goblet;
