import styled from 'styled-components';

const ButtonContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 100px;
  /* width: 300px; */
`;

export default ButtonContainer;
