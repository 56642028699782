import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Popup, { PopupUpgradeHeader } from '../PopupOverlay';
import { useApp } from '../../../contexts/AppContext';
import { isSpeedEvent } from '../../../lib/events';
import { getQuote } from '../../../lib/farming/quotes';
import RunningText from '../../../components/RunningText';
import MeerkatHeadAnimated from '../../../components/Icons/MeerkatHeadAnimated';
import { IconSpeed } from '../../../components/Icons/IconSpeed';

export const ContainerAnimation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 800px;
  margin-top: 40px;
  margin-bottom: 40px;

  @keyframes pulseAnimation {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }

  > svg {
    height: auto;
    position: absolute;
    top: 170px;
    left: 70px;
    width: 150px;
    z-index: 100;
    animation: pulseAnimation 2s infinite;

    &:first-of-type {
      width: 270px;
      left: 70px;
      top: 600px;
    }

    &:nth-of-type(3) {
      width: 200px;
      left: 680px;
      top: 450px;
    }
  }
`;

const Speed = () => {
  const { event } = useApp();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  if (!isSpeedEvent(event)) throw new Error('Should not happen');

  const quote = getQuote('speed', currentLanguage);

  return (
    <Popup hideClose buttonText="Close" onConfirm={async () => {}}>
      <PopupUpgradeHeader>{t('boosts.upgrade')}!</PopupUpgradeHeader>
      <ContainerAnimation>
        <IconSpeed />
        <IconSpeed />
        <IconSpeed />
        <MeerkatHeadAnimated />
      </ContainerAnimation>
      <RunningText text={quote} />
    </Popup>
  );
};

export default Speed;
