import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../lib/utils';
import IconHand from '../components/Icons/IconHand';
import DiaryPage from '../components/pages/Diary/Page';
import { useDiaryNavigation, useDiaryPages } from '../components/pages/Diary/useDiary';
import { Button, Header } from '../components/pages/Diary/DiaryStyles';

const Diary = () => {
  const { t } = useTranslation();
  const { pages, pagesData, lang } = useDiaryPages();
  const { currentPage, handlePrev, handleNext } = useDiaryNavigation(pages.length);

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer vcenter>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Header>{t('diary.diaryOfMeerkat')}</Header>
      </motion.div>

      {currentPage === 0 ? (
        <img
          alt="diary"
          src="https://dapp.timesoul.com/assets/cover-12a1fbb5.webp"
          height={1036}
          style={{ marginTop: '-16px', marginBottom: '-16px', marginLeft: '-6px' }}
        />
      ) : (
        <DiaryPage src={pages[currentPage - 1]} data={pagesData[currentPage]} lang={lang} />
      )}

      <div style={{ position: 'relative' }}>
        {currentPage > 0 && (
          <Button onClick={handlePrev} disabled={currentPage === 0} left>
            <IconHand left />
          </Button>
        )}
        {currentPage !== pages.length && (
          <Button onClick={handleNext}>
            <IconHand />
          </Button>
        )}
      </div>
    </PageContainer>
  );
};

export default Diary;
