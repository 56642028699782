import { TFunction } from 'i18next';

export const createPagesData = (t: TFunction) => [
  [undefined],
  [
    {
      marginTop: 0,
      text: t('diary.pages.1'),
      fntSize: { fr: 30, de: 29 },
    },
  ],
  [
    {
      marginTop: 44 * 3,
      text: t('diary.pages.2'),
      fntSize: { fr: 29, de: 29 },
    },
  ],
  [
    {
      marginTop: 0,
      text: t('diary.pages.3.above'),
      fntSize: { ru: 27, de: 29 },
    },
    {
      marginTop: 395,
      marginLeft: { default: 65, jp: 90, cn: 90, de: 60, fr: 63 },
      text: t('diary.pages.3.fingers'),
      fntSize: 25,
    },
    {
      marginTop: { default: 652, de: 640 },
      marginLeft: { default: 120, jp: 70, cn: 210, de: 70 },
      text: t('diary.pages.3.common'),
      fntSize: { default: 23, en: 25 },
    },
    {
      marginTop: 44 * 17 - 3,
      text: t('diary.pages.3.below'),
    },
    {
      marginTop: 510,
      marginLeft: 565,
      fntSize: 25,
      text: `170 ${t('diary.pages.3.centimeters')}`,
      shouldRotate: true,
    },
  ],
  [
    {
      marginTop: 0,
      text: t('diary.pages.4.above'),
    },
    {
      marginTop: 44 * 13 - 3,
      text: t('diary.pages.4.below'),
      fntSize: { ru: 28 },
    },
  ],
  [
    {
      marginTop: 0,
      text: t('diary.pages.5.above'),
    },
    {
      marginTop: 44 * 13 - 3,
      text: t('diary.pages.5.below'),
      fntSize: 28.8,
    },
  ],
  [
    {
      marginTop: 44 * 8,
      text: t('diary.pages.6'),
    },
  ],
  [
    {
      marginTop: 0,
      text: t('diary.pages.7.above'),
    },
    {
      marginTop: 44 * 14 - 3,
      marginRight: 430,
      text: t('diary.pages.7.below'),
      fntSize: 28.8,
    },
  ],
  [
    {
      text: t('diary.pages.8'),
      fntSize: { fr: 30, de: 29 },
    },
  ],
  [
    {
      text: t('diary.pages.9'),
      fntSize: 28,
    },
  ],
  [
    {
      marginTop: 44 * 8 - 3,
      text: t('diary.pages.10'),
      fntSize: 28,
    },
  ],
  [
    {
      imagePosition: { ru: 44, de: 24 },
      marginTop: 0,
      fntSize: 26,
      text: t('diary.pages.11.above'),
    },
    {
      marginTop: { default: 44 * 14 - 3, ru: 44 * 15 - 3 },
      text: t('diary.pages.11.below'),
      fntSize: 26,
    },
  ],
  [
    {
      imagePosition: 30,
      text: t('diary.pages.12'),
      fntSize: { ru: 30 },
    },
  ],
  [
    {
      text: t('diary.pages.13'),
      fntSize: { default: 25, en: 27, fr: 24 },
    },
  ],
  [
    {
      imagePosition: { default: 44, jp: 66 },
      marginTop: 0,
      text: t('diary.pages.14.above'),
    },
    {
      marginTop: { default: 44 * 13 - 3, jp: 44 * 14 - 3 },
      text: t('diary.pages.14.below'),
      fntSize: { de: 30 },
    },
  ],
  [
    {
      imagePosition: { en: -10, ru: -44, jp: -22, cn: -44 },
      marginTop: 0,
      text: t('diary.pages.15.above'),
      fntSize: { fr: 30 },
    },
    {
      marginTop: { default: 44 * 12 - 3, ru: 44 * 11 - 3 },
      text: t('diary.pages.15.below'),
      fntSize: { fr: 26, de: 27 },
    },
  ],
  [
    {
      imagePosition: 44,
      marginTop: 0,
      text: t('diary.pages.16.above'),
    },
    {
      marginTop: { default: 44 * 13 - 3, jp: 44 * 14 - 3, cn: 44 * 14 - 3 },
      text: t('diary.pages.16.below'),
    },
  ],
  [
    {
      imagePosition: { de: 44 },
      text: t('diary.pages.17'),
      fntSize: 27,
    },
  ],
  [
    {
      marginTop: 44 * 9 - 8,
      center: true,
      fntSize: 50,
      text: t('diary.pages.tbc'),
    },
  ],
];
