import styled from 'styled-components';

export const MainButton = styled.button<{ disabled?: boolean }>`
  height: 100px;
  margin: 0 auto 0;

  background: ${({ disabled }) => (disabled ? '#000' : '#00000094')};
  color: ${({ disabled }) => (disabled ? '#555' : '#efff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? 'gray' : '#efff00')};

  font-family: var(--font), sans-serif;
  text-transform: uppercase;
  border-radius: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 42px;
  font-weight: 500;
  padding: 0 100px;
`;
