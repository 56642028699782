import styled from 'styled-components';

export const IconDone = styled((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79" fill="none" {...props}>
    <rect width="79" height="79" rx="39.5" fill="#FAFF00" fillOpacity="0.6" />
    <rect
      x="0.486"
      y="0.486"
      width="78.028"
      height="78.028"
      rx="39.014"
      stroke="#EBEBEB"
      strokeOpacity="0.61"
      strokeWidth="0.972"
    />
    <path
      d="M62.5034 28.6389L37.1409 54.733C35.9037 56.0059 33.8967 56.0059 32.6595 54.733L23.8364 45.6553C22.4527 44.2318 22.4191 41.8656 23.8297 40.4697C25.1972 39.1166 27.3707 39.1332 28.7181 40.5194L34.9002 46.8799L57.5666 23.5597C58.9301 22.1569 61.1399 22.1569 62.5034 23.5597C63.8669 24.9625 63.8669 27.2361 62.5034 28.6389Z"
      fill="#575757"
    />
  </svg>
))``;
