import ButtonContainer from './ButtonContainer';
import IconContainer from './IconContainer';

export const Icon = () => (
  <img alt="meerkat" src="/img/MeerkatSunglasses.png" style={{ width: '150px', marginTop: '-40px' }} />
);

const Meerkat = ({ active = false }) => {
  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
    </ButtonContainer>
  );
};

export default Meerkat;
