import { useNavigate } from 'react-router-dom';

import { ButtonContainer, Group, IconArrow } from './Wrappers';
import Text from './Text';
import { IconCloud } from '../Icons/IconCloud';
import { useApp } from '../../contexts/AppContext';
import { getTurboLevel } from '../../lib/farming/util';
import { useTranslation } from 'react-i18next';

const Cloud = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { app } = useApp();

  const level = getTurboLevel(app);

  return (
    <ButtonContainer onClick={() => navigate('/boost/cloud')}>
      <Group>
        <IconCloud />
        <Text
          heading={t('boosts.cloud.insights')}
          level={t('system.levelShort') + ` ${app.state.turboLevel}/30`}
          reward={`3*(${t('system.coins')} x${level.multiplier} ${t('boosts.for')} ${level.duration} ${t(
            'system.seconds'
          )})`}
        />
      </Group>
      <IconArrow />
    </ButtonContainer>
  );
};

export default Cloud;
