import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IconHand from './IconHand';

const Container = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 150px;
  overflow: hidden;
  background: none;
  border: 0;
  animation: upDownAnimation 2s infinite alternate;

  svg {
    margin-left: 20px;
    margin-top: 5px;
  }

  @keyframes upDownAnimation {
    0% {
      transform: translateY(0, 0);
    }
    100% {
      transform: translate(0, -30px);
    }
  }
`;

const MeerkatHandAnimated = () => {
  const [i, setIndex] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => setIndex((i) => (i === 6 ? 1 : i + 1)), 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container className="meerkatHand">
      <IconHand />
    </Container>
  );
};

export default MeerkatHandAnimated;
