import styled from 'styled-components';
import { motion } from 'framer-motion';

import PageContainer from '../../Wrappers';
import { useApp } from '../../../contexts/AppContext';
import { IconCoin } from '../../Icons/IconCoin';
import { doHapticFeedback } from '../../../lib/utils';
import { IconFireworksRocket } from '../../Icons/IconFireworksRocket';
import { IconFireworksStarA } from '../../Icons/IconFireworksStarA';
import { IconFireworksStarB } from '../../Icons/IconFireworksStarB';
import { IconFireworksStarC } from '../../Icons/IconFireworksStarC';
import { useTranslation } from 'react-i18next';

export const Container = styled.button`
  -webkit-tap-highlight-color: transparent;
  @keyframes pulseFarmingClaimed {
    0% {
      transform: scale(0.97);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(0.97);
    }
  }
  @keyframes pulseFireworksStar {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.1;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: 0;
  background: #00000085;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  .box {
    background: #e6d8ff;
    width: 700px;
    height: 700px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    h2 {
      color: #535353;
      font-size: 92px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-top: 50px;

      svg {
        width: 110px;
        height: auto;
        margin-top: -20px;
        margin-left: 10px;
      }
    }
    p {
      font-size: 32px;
      color: #565656;
      margin-top: 10px;
      font-weight: 500;
    }
    .fireworksRocket {
      margin-top: 50px;
      width: 400px;
      height: auto;
      animation: pulseFarmingClaimed 5s infinite;
    }
    h3 {
      font-size: 42px;
      font-weight: 500;
      text-transform: uppercase;
      position: absolute;
      bottom: 60px;
      color: #535353;
    }

    .fireworksStarA,
    .fireworksStarB,
    .fireworksStarC {
      position: absolute;
      right: -80px;
      top: -300px;
      width: 400px;
      height: auto;

      animation: pulseFireworksStar 5s infinite;

      &:first-of-type {
        width: 250px;
        left: -100px;
        top: 180px;
        animation: pulseFireworksStar 7s infinite;
      }

      &:nth-of-type(3) {
        width: 350px;
        right: -150px;
        top: 550px;
        animation: pulseFireworksStar 9s infinite;
      }
    }
  }
`;

const FarmingClaimed = () => {
  const { farmingStart, claimReward, isBusy } = useApp();
  const { t } = useTranslation();

  const multiplier = claimReward ? claimReward?.multiplier : 0;

  const handleOnClick = () => {
    doHapticFeedback();
    farmingStart();
  };

  return (
    <PageContainer spread light>
      <Container onClick={handleOnClick} disabled={isBusy}>
        <div className="box">
          <motion.div
            initial={{ x: 64 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}
          >
            <h2>
              +{claimReward?.reward.toLocaleString('us')} <IconCoin />
            </h2>
          </motion.div>

          {multiplier > 1 && (
            <motion.div
              initial={{ x: -64 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}
            >
              <p>
                {t('farming.claimed.includingBonus', { multiplier })}
                <br />
                {t('farming.claimed.stressResistance')}
              </p>
            </motion.div>
          )}
          <IconFireworksStarA />
          <IconFireworksStarC />
          <IconFireworksStarB />
          <IconFireworksRocket />
          <h3>{t('farming.claimed.claimed')}</h3>
        </div>
      </Container>
    </PageContainer>
  );
};

export default FarmingClaimed;
