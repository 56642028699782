import { MainButton } from '../../components/MainButton';
import { IconCoin } from '../../components/Icons/IconCoin';
import { BoostPageContainer } from '../../components/Boosts/Wrappers';
import { useApp } from '../../contexts/AppContext';
import { IconCloud } from '../../components/Icons/IconCloud';
import { getTurboLevelNext } from '../../lib/farming/util';
import MeerkatHandAnimated from '../../components/Icons/MeerkatHandAnimated ';
import { shouldDisableBoost } from '../../lib/utils';
import BoostCondtions from '../../components/pages/Boost/BoostCondtions';
import { useTranslation } from 'react-i18next';

const BoostCloud = () => {
  const { app, userUpgrade, isBusy, farmingState } = useApp();
  const { t } = useTranslation();

  const levelNext = getTurboLevelNext(app);
  const handleUpgrade = () => userUpgrade('turbo');
  const isDisabled = shouldDisableBoost(app, levelNext, isBusy, farmingState);

  return (
    <BoostPageContainer>
      <p className="upgrade">{t('boosts.upgrade') + ' ' + t('boosts.cloud.insights')}</p>
      <p className="level">
        {t('boosts.toLevel')} {levelNext.level}
      </p>
      <p className="duration">
        {t('boosts.cloud.upToTimes')}&nbsp;
        <b>
          {t('boosts.cloud.speedX')}
          {levelNext.multiplier} {t('boosts.for')} {levelNext.duration} {t('system.seconds')}
        </b>
      </p>

      <IconCloud />

      <p className="price">
        {levelNext.price.toLocaleString('us')} <IconCoin />
      </p>

      <BoostCondtions levelNext={levelNext} />

      <MainButton onClick={handleUpgrade} disabled={isDisabled}>
        {t('boosts.levelUp')}
        {!isDisabled && <MeerkatHandAnimated />}
      </MainButton>
    </BoostPageContainer>
  );
};

export default BoostCloud;
