import { MainButton } from '../../components/MainButton';
import { IconSpeed } from '../../components/Icons/IconSpeed';
import { IconCoin } from '../../components/Icons/IconCoin';
import { BoostPageContainer } from '../../components/Boosts/Wrappers';
import { useApp } from '../../contexts/AppContext';
import { getSpeedLevelNext } from '../../lib/farming/util';
import MeerkatHandAnimated from '../../components/Icons/MeerkatHandAnimated ';
import { shouldDisableBoost } from '../../lib/utils';
import BoostCondtions from '../../components/pages/Boost/BoostCondtions';
import { useTranslation } from 'react-i18next';

const BoostSpeed = () => {
  const { app, userUpgrade, isBusy, farmingState } = useApp();
  const { t } = useTranslation();

  const levelNext = getSpeedLevelNext(app);
  const handleUpgrade = () => userUpgrade('speed');
  const isDisabled = shouldDisableBoost(app, levelNext, isBusy, farmingState);

  return (
    <BoostPageContainer>
      <p className="upgrade">{t('boosts.upgrade') + ' ' + t('boosts.speed.physicalStrength')}</p>
      <p className="level">
        {t('boosts.toLevel')} {levelNext.level}
      </p>
      <p className="duration">
        {t('boosts.speed.profit')}&nbsp;
        <b>
          {levelNext.speed} {t('boosts.speed.coinsPerSec')}
        </b>
      </p>

      <IconSpeed />

      <p className="price">
        {levelNext.price.toLocaleString('us')} <IconCoin />
      </p>

      <BoostCondtions levelNext={levelNext} />

      <MainButton onClick={handleUpgrade} disabled={isDisabled}>
        {t('boosts.levelUp')}
        {!isDisabled && <MeerkatHandAnimated />}
      </MainButton>
    </BoostPageContainer>
  );
};

export default BoostSpeed;
