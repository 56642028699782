import styled from 'styled-components';
import { loadEruda } from '../lib/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { notificationEmit } from '../controllers/NotificationsController';
import { useTranslation } from 'react-i18next';

const Debug = styled((props) => {
  const [, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (localStorage.getItem('debug') === 'on') return;
    const currentTime = Date.now();
    if (currentTime - lastClickTime < 500) {
      // 500ms threshold for consecutive clicks
      setClickCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount === 5) {
          notificationEmit({
            title: t('notifications.debug.taps_away', {
              count: 10 - newCount,
            }),
          });
        } else if (newCount === 10) {
          notificationEmit({
            title: t('notifications.debug.on'),
          });
          localStorage.setItem('debug', 'on');
          loadEruda();
          return 0;
        }
        return newCount;
      });
    } else {
      setClickCount(1);
    }
    setLastClickTime(currentTime);
  }, [lastClickTime]);

  useEffect(() => {
    if (localStorage.getItem('debug') === 'on') loadEruda();
  }, []);

  return <div {...props} onClick={handleClick} />;
})``;

export default Debug;
