export const IconCup = () => (
  <svg width="279" height="282" viewBox="0 0 279 282" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M194.707 254.857C186.736 256.733 179.052 257.328 173.069 257.419C183.442 249.74 189.997 242.115 193.783 234.308C198.691 224.187 198.697 214.274 197.118 204.593C196.334 199.785 195.145 194.953 193.949 190.18C193.872 189.873 193.794 189.565 193.717 189.258C192.587 184.752 191.465 180.282 190.6 175.698C188.761 165.962 188.134 155.971 191.175 145.18C197.248 123.633 211.142 115.624 223.575 112.794C228.731 111.62 233.663 111.341 237.708 111.428C230.722 117.268 226.691 123.43 224.931 130.102C222.597 138.944 224.474 147.944 227.169 156.824C228.089 159.854 229.107 162.901 230.138 165.986C235.298 181.424 240.774 197.808 235.775 217.702C229.936 240.936 211.981 250.792 194.707 254.857Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="7.68"
      strokeLinejoin="round"
    />
    <path
      d="M125.184 206.383L125.184 206.395C125.22 218.553 127.109 228.17 129.026 235.656C129.644 238.07 130.286 240.336 130.852 242.333C131.144 243.364 131.415 244.323 131.653 245.194C132.392 247.903 132.813 249.797 132.891 251.209C132.965 252.547 132.698 252.9 132.602 253.013C132.428 253.221 131.789 253.777 129.668 254.243C125.32 255.198 117.478 255.242 103.759 254.588L103.759 254.588C70.7989 253.02 47.0177 243.701 30.987 230.18C14.982 216.68 6.4132 198.754 4.25371 179.37C1.13655 151.267 16.1365 123.707 34.0185 105.651C34.5709 110.661 36.1944 115.708 39.6866 120.132C45.3345 127.288 56.6549 131.861 66.5619 128.702L66.5669 128.7C79.699 124.492 82.6461 110.367 79.6083 100.315L79.6057 100.306C78.1482 95.5241 75.7185 91.0768 73.5084 87.0315C73.4553 86.9344 73.4024 86.8376 73.3497 86.741C71.0237 82.4824 68.955 78.641 67.7792 74.6404C64.0898 62.0175 69.9431 47.8148 81.1808 37.1128C89.8342 28.872 101.112 22.6599 113.397 17.6201C109.288 27.4162 110.389 38.8234 117.174 47.7099L117.175 47.7114C121.57 53.4614 127.667 57.6747 133.725 61.3404C135.775 62.5804 137.793 63.7451 139.772 64.8864C143.858 67.2438 147.772 69.5017 151.428 72.1192L151.43 72.1211C169.229 84.841 176.808 106.276 167.509 122.453C166.2 124.729 164.591 126.915 162.824 129.308L162.776 129.374C161.072 131.683 159.217 134.196 157.678 136.884C154.569 142.29 152.281 149.691 155.731 156.533C157.537 160.114 160.984 162.813 164.688 164.249C165.779 164.672 166.938 165.003 168.135 165.214C165.651 167.916 162.455 171.132 159.058 174.268C155.363 177.678 151.554 180.877 148.255 183.192C147.32 182.363 146.242 181.572 145.106 180.868C142.049 178.971 137.56 177.082 133.394 177.682C131.121 178.009 128.88 179.1 127.297 181.309C125.787 183.417 125.175 186.128 125.184 189.177L125.184 206.383Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="7.68"
      strokeLinejoin="round"
    />
    <path
      d="M163.417 214.9C155.583 228.481 141.505 238.944 125.43 244.71C123.734 245.312 122.017 245.875 120.279 246.382C102.766 251.492 83.5795 250.102 67.5047 242.156C55.4861 236.22 44.4548 226.377 37.4368 209.715C30.1399 192.396 34.3034 168.014 45.4635 153.25C45.5494 160.613 54.5633 166.286 62.9333 165.553C71.3034 164.821 78.2784 159.091 81.7338 152.367C85.1891 145.661 85.7042 138.091 85.6398 130.727C85.5754 123.364 84.9745 115.944 86.4339 108.693C87.8933 101.442 92.9368 90.0027 101.35 83.5033C93.5162 96.8965 99.9118 110.046 107.874 121.147C115.836 132.249 126.074 136.118 131.46 155.09C135.238 168.409 127.597 181.163 142.9 184.732C154.682 187.493 163.868 175.434 163.288 168.409C170.456 182.253 171.208 201.356 163.374 214.919L163.417 214.9Z"
      fill="#EDD500"
    />
    <path
      d="M128.796 236.589C121.84 245.15 112.551 249.917 96.7555 249.345C80.4464 248.754 70.7294 240.574 63.9446 229.172C57.9304 219.085 57.0957 206.367 59.9423 195.555C65.2074 204.479 72.3774 208.102 82.1372 209.284C72.7627 194.526 80.5962 168.117 102.128 154.159C97.8257 160.128 98.7246 167.697 102.256 173.952C105.809 180.206 111.673 185.43 117.431 190.598C123.167 195.746 128.967 201.066 132.263 207.415C137.143 216.816 135.795 228.047 128.839 236.608L128.796 236.589Z"
      fill="#EDB900"
    />
    <path
      d="M146.798 91.1075C146.644 91.4477 146.491 91.7864 146.339 92.1239C156.187 85.3845 161.587 78.3517 162.927 71.3484C164.607 62.5757 160.196 52.0245 145.74 39.763C138.668 34.0191 136.633 26.9107 137.068 19.679C137.309 15.6919 138.306 11.599 139.539 7.63897C132.874 11.7943 128.151 18.4323 126.9 26.7645C125.816 34.7978 129.662 43.4302 135.933 49.1801L135.968 49.2128L136.003 49.2464C137.242 50.4412 138.608 51.6654 140.068 52.9733L140.231 53.1193C141.731 54.4627 143.316 55.887 144.827 57.3536C147.788 60.229 150.804 63.5859 152.474 67.3714L152.57 67.5889L152.638 67.8165C153.432 70.4604 153.214 72.341 152.998 74.2109C152.979 74.3743 152.96 74.5375 152.942 74.7012L152.916 74.9316L152.863 75.1572C151.752 79.8709 149.686 84.6756 147.652 89.2113C147.366 89.8484 147.081 90.4803 146.798 91.1075Z"
      fill="#DFD8EC"
      stroke="#303030"
      strokeWidth="7.68"
    />
    <path
      d="M100.653 37.0839C99.7292 39.046 99.1668 41.1699 99.0043 43.3673L99.0011 43.4109L98.9968 43.4545C98.4895 48.6894 101.999 53.9246 107.131 58.5435L107.245 58.6458L107.35 58.7568L107.454 58.8664C111.018 62.6275 114.704 66.5179 114.364 73.4987L114.361 73.5446L114.358 73.5903C114.293 74.4827 114.187 75.3787 114.051 76.2712C114.752 74.9464 115.284 73.6307 115.632 72.3282C116.859 67.7488 115.985 62.7396 110.73 57.1086L110.71 57.0872L110.69 57.0655C110.208 56.5326 109.614 55.9136 108.977 55.2506C107.806 54.03 106.491 52.6603 105.467 51.4041C103.714 49.2532 102.081 46.6572 101.375 43.3212C100.885 41.3482 100.671 39.2544 100.653 37.0839Z"
      fill="#DFD8EC"
      stroke="#303030"
      strokeWidth="7.68"
    />
    <path
      d="M118.2 108.431C118.885 110.254 120.086 112.056 121.755 113.758C121.199 112.213 120.702 110.625 120.38 109.06L120.336 108.844L120.316 108.623C120.311 108.568 120.305 108.506 120.299 108.439C120.217 107.606 120.049 105.888 120.866 104.118L120.926 103.988L120.996 103.862C122.496 101.162 124.957 98.8817 126.91 97.0754C126.968 97.0219 127.025 96.9687 127.082 96.9158C129.163 94.9914 130.817 93.4623 131.904 91.84L131.962 91.7529L132.025 91.6691C135.674 86.8203 134.811 80.3466 131.389 76.1037C131.444 76.4584 131.492 76.8108 131.53 77.1594C131.938 80.8773 131.433 85.0798 128.038 88.5749C127.163 89.5403 126.235 90.418 125.419 91.1894L125.358 91.247C124.475 92.082 123.709 92.8094 123.004 93.5906L122.964 93.6356L122.922 93.6794C117.295 99.5346 116.726 104.51 118.2 108.431Z"
      fill="#DFD8EC"
      stroke="#303030"
      strokeWidth="7.68"
    />
    <path
      d="M74.6504 130.014H175.742V250.083C175.742 255.972 170.965 260.75 165.076 260.75H85.317C79.428 260.75 74.6504 255.972 74.6504 250.083V130.014Z"
      fill="#DFD8EC"
      stroke="#303030"
      strokeWidth="7.68"
      strokeMiterlimit="10"
    />
    <line
      x1="4.23936"
      y1="-4.23936"
      x2="66.4335"
      y2="-4.23936"
      transform="matrix(-0.00240415 0.999997 -0.99998 -0.00639539 156.84 141.871)"
      stroke="white"
      strokeWidth="8.47872"
      strokeLinecap="round"
    />
    <path d="M161.076 225.228L160.942 240.175" stroke="white" strokeWidth="8.47872" strokeLinecap="round" />
    <path
      d="M115.326 260.75H85.5162C79.511 260.75 74.6505 255.873 74.6505 249.884L74.6505 130.013H115.326L115.326 260.75Z"
      fill="#B38DBF"
    />
    <path
      d="M74.6504 130.014H175.742V250.083C175.742 255.972 170.965 260.75 165.076 260.75H85.317C79.428 260.75 74.6504 255.972 74.6504 250.083V130.014Z"
      stroke="#303030"
      strokeWidth="7.68"
      strokeMiterlimit="10"
    />
    <path
      d="M175.842 233.959L184.949 233.014C197.523 231.703 207.095 221.103 207.095 208.462V185.288C207.095 172.431 197.225 161.715 184.402 160.686L175.858 159.99V145.839L185.53 146.619C205.536 148.245 221.195 165.215 221.195 185.288V208.462C221.195 228.402 206.232 244.991 186.409 247.048L175.842 248.142V233.959Z"
      fill="#C6B8E0"
      stroke="#303030"
      strokeWidth="7.68"
      strokeMiterlimit="10"
    />
  </svg>
);
