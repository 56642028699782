import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import WebApp from '@twa-dev/sdk';
import { env } from '../../../lib/env';
import { getTgUserId } from '../../../lib/utils';

const Container = styled.div`
  position: relative;
  padding: 0 60px;
  min-width: 540px;
  height: 130px;
  background-color: #000000c4;
  border: 1.5px solid #f9ff00;
  border-radius: 105px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const StyledText = styled.div`
  color: #f9ff00;
  font-family: var(--font), sans-serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-transform: uppercase;
`;

export const IconFriend = styled((props) => (
  <svg width={27} height={37} viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.3804 12.963C16.7564 12.963 19.4932 10.2262 19.4932 6.85013C19.4932 3.47411 16.7564 0.737305 13.3804 0.737305C10.0044 0.737305 7.26758 3.47411 7.26758 6.85013C7.26758 10.2262 10.0044 12.963 13.3804 12.963Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth={0.9792}
      strokeLinejoin="round"
    />
    <path
      d="M10.1404 15.0359H16.8596C21.914 15.0359 26 18.4281 26 22.6243V35.7676H1L1 22.6243C1 18.4281 5.08596 15.0359 10.1404 15.0359Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth={0.9792}
      strokeLinejoin="round"
    />
    <path
      d="M10.1747 15.6458C7.73595 15.6458 7.14114 16.0214 5.89203 16.5723C4.405 17.2234 2.62056 18.9514 2.08523 20.3537C1.6986 21.4055 1.60938 22.4573 1.60938 23.7345V35.1579H25.3899V29.0604H10.2044V15.6458H10.1747Z"
      fill="#EDD500"
    />
    <path
      d="M8.92773 6.85013C8.92773 4.38719 10.9159 2.42871 13.3491 2.42871"
      stroke="white"
      strokeWidth={1.5552}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))``;

export const InviteFriendButton = () => {
  const { t } = useTranslation();

  const urlInvitation = `https://t.me/share/url?url=${encodeURIComponent(
    `${env.botUrl}?startapp=${getTgUserId()}`
  )}&text=${encodeURIComponent(t('friends.playWithMe'))}`;

  return (
    <Container onClick={() => WebApp.openTelegramLink(urlInvitation)}>
      <StyledText>{t('friends.inviteFriend')}</StyledText>
      <IconFriend />
    </Container>
  );
};
