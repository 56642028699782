import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { useTranslation } from 'react-i18next';

export const Icon = () => (
  <svg width="103" height="83" viewBox="0 0 103 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7026 28.9862C26.7794 28.9862 30.8949 24.8707 30.8949 19.7939C30.8949 14.7171 26.7794 10.6016 21.7026 10.6016C16.6258 10.6016 12.5103 14.7171 12.5103 19.7939C12.5103 24.8707 16.6258 28.9862 21.7026 28.9862Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M27 32.2439C26.2073 32.0976 25.3618 32 24.4634 32H13.5772C6.60163 32 1 37.2195 1 43.6098V70H24.4634V39.7073C24.4634 36.9268 25.4146 34.3415 27 32.2439Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M80.8579 28.9862C85.9346 28.9862 90.0502 24.8707 90.0502 19.7939C90.0502 14.7171 85.9346 10.6016 80.8579 10.6016C75.7811 10.6016 71.6655 14.7171 71.6655 19.7939C71.6655 24.8707 75.7811 28.9862 80.8579 28.9862Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M51.28 23.9576C57.4319 23.9576 62.419 18.9705 62.419 12.8186C62.419 6.66676 57.4319 1.67969 51.28 1.67969C45.1282 1.67969 40.1411 6.66676 40.1411 12.8186C40.1411 18.9705 45.1282 23.9576 51.28 23.9576Z"
      fill="#B48EC0"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M45.4528 28H57.5472C66.6453 28 74 34.6758 74 42.9341V82H29V42.9341C29 34.6758 36.3547 28 45.4528 28Z"
      fill="#B48EC0"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M102 43.6098C102 37.1707 96.128 32 88.939 32H77.6341C76.7561 32 75.878 32.0976 75 32.2439C76.6463 34.3902 77.6341 36.9268 77.6341 39.7073V70H102V43.6098Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path d="M13.7557 33C7.29008 33 2 37.8462 2 43.8791V69H23V61.7802H13.7557V33Z" fill="#EDD500" />
    <path
      d="M45.4878 29C41.0781 29 40.0026 29.745 37.7439 30.8376C35.0551 32.1289 31.8284 35.5559 30.8604 38.3372C30.1613 40.4231 30 42.5091 30 45.042V81H72V70.5205H45.5416V29H45.4878Z"
      fill="#A579B2"
    />
    <path
      d="M43.1689 12.8185C43.1689 8.3305 46.7918 4.76172 51.2257 4.76172"
      stroke="#E6C1F2"
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8623 20.0102C15.8623 16.5496 18.6741 13.7378 22.1347 13.7378"
      stroke="white"
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.3965 20.0102C75.3965 16.5496 78.2083 13.7378 81.6689 13.7378"
      stroke="white"
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.3907 33.0656H78.8977C78.0605 33.0656 77.7814 32.918 77 33.0656C78.5628 35.0346 78.9535 37.5943 78.9535 40.1541V69H101V62.1085H89.3349V33.0656H89.3907Z"
      fill="#EDD500"
    />
    <path d="M68 40L68 66" stroke="#D5D5D5" strokeWidth="2.7" strokeLinecap="round" />
    <path d="M97 44L97 57" stroke="white" strokeWidth="2.7" strokeLinecap="round" />
    <path d="M19 44L19 57" stroke="white" strokeWidth="2.7" strokeLinecap="round" />
  </svg>
);

const Friends = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
      <ButtonLabel active={active} marginTop={11}>
        {t('menu.friends')}
      </ButtonLabel>
    </ButtonContainer>
  );
};

export default Friends;
