import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notificationEmit } from '../../../controllers/NotificationsController';
import { env } from '../../../lib/env';
import { getTgUserId } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const ClipIcon = styled((props) => (
  <svg width={95} height={95} viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.75} y={0.75} width={93.5} height={93.5} rx={46.75} fill="#000000c4" stroke="#FAFF00" strokeWidth={1.5} />
    <path
      d="M59.8329 35.996C59.8329 34.0068 58.2206 32.3945 56.2315 32.3945H54.1321H31.5476C29.588 32.3945 28 33.9834 28 35.9421V67.4521C28 69.4117 29.5889 70.9997 31.5476 70.9997H56.2853C58.2449 70.9997 59.8329 69.4108 59.8329 67.4521V37.9972V35.996ZM57.164 39.3317V66.2878C57.164 67.4165 56.2488 68.3316 55.1201 68.3316H32.7128C31.5841 68.3316 30.669 67.4165 30.669 66.2878V37.1082C30.669 35.9795 31.5841 35.0644 32.7128 35.0644H52.7985H55.075C56.1872 35.0644 57.0954 35.9543 57.1188 37.0665L57.164 39.3317Z"
      fill="#FAFF00"
    />
    <path
      d="M62.0507 25H59.9513H37.366C35.4064 25 33.8184 26.5889 33.8184 28.5476V30.5063H36.4873V29.7128C36.4873 28.5841 37.4024 27.669 38.5311 27.669H58.6169H60.8934C62.0056 27.669 62.9137 28.5589 62.9372 29.6711L62.9841 31.9372V58.8932C62.9841 59.7458 62.4614 60.476 61.7199 60.7825V63.606H62.1054C64.065 63.606 65.653 62.0171 65.653 60.0584V30.6018V28.6006C65.6521 26.6114 64.0398 25 62.0507 25Z"
      fill="#FAFF00"
    />
  </svg>
))`
  flex-shrink: 0;
  width: 130px;
  height: 130px;
`;

const ClipboardButton = () => {
  const { t } = useTranslation();

  const url = `${env.botUrl}?startapp=${getTgUserId()}`;
  const text = `${url}\n${t('friends.playWithMe')}`;

  const handleCopy = () => {
    notificationEmit({
      title: t('friends.inviteFriend'),
      subtitle: t('friends.linkCopied'),
    });
  };

  return (
    <CopyToClipboard text={text} onCopy={() => handleCopy()}>
      <ClipIcon />
    </CopyToClipboard>
  );
};

export default ClipboardButton;
