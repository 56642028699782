import WebApp from '@twa-dev/sdk';

import { env } from '../env';
import HttpException from '../../app/exception/HttpException';

const { backUrl } = env;

export const getBackApi = async (endpoint: string) => callApi(backUrl, endpoint);
export const patchBackApi = async (endpoint: string, body: any = undefined) =>
  callApi(backUrl, endpoint, 'PATCH', JSON.stringify(body));
export const deleteBackApi = async (endpoint: string) => callApi(backUrl, endpoint, 'DELETE');
export const postBackApi = async (endpoint: string, body: any = undefined) =>
  callApi(backUrl, endpoint, 'POST', JSON.stringify(body));

const callApi = async (
  backUrl: string,
  endpoint: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET',
  body: string | undefined = undefined
): Promise<null | any> => {
  if (!WebApp.initData) throw new Error('Must run inside telegram app');

  try {
    return await callApiEmitEvents(backUrl, endpoint, method, body);
  } catch (e: any) {
    document.dispatchEvent(
      new CustomEvent('appHttpError', {
        detail: e,
      })
    );

    throw new HttpException(JSON.stringify(e));
  }
};

const callApiEmitEvents = async (
  backUrl: string,
  endpoint: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET',
  body: string | undefined = undefined
): Promise<null | any> => {
  if (!WebApp.initData) throw new Error('Must run inside telegram app');

  console.debug('API request:', { backUrl, endpoint, body });

  document.dispatchEvent(new CustomEvent('appHttpStart'));

  const response = await fetch(`${backUrl}/${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${btoa(WebApp.initData)}`,
    },
    body,
  });
  document.dispatchEvent(new CustomEvent('appHttpEnd'));

  const reponseJson = await response.json();

  console.debug('API response:', { endpoint, reponseJson });

  if (response.status === 200) {
    return reponseJson;
  }
  if (response.status === 204) {
    return null;
  }

  document.dispatchEvent(
    new CustomEvent('appApiError', {
      detail: reponseJson,
    })
  );

  return reponseJson;
};
