export const IconFireworksStarC = () => (
  <svg
    className="fireworksStarC"
    width="372"
    height="372"
    viewBox="0 0 372 372"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M193.463 195.895L268.145 277.101L271.398 273.831L271.773 273.473L275.026 270.203L193.837 195.538L185.168 186.869L189.324 191.382L184.811 187.226L193.463 195.895Z"
      fill="#FAFF00"
    />
    <path
      d="M176.872 178.574L102.189 97.3685L98.9363 100.639L98.5616 100.996L95.3086 104.249L176.514 178.932L185.166 187.601L181.011 183.088L185.524 187.226L176.872 178.574Z"
      fill="#FAFF00"
    />
    <path
      d="M197.158 187.482L307.385 192.097V187.482V186.971V182.372L197.158 186.971H184.912L191.043 187.226L184.912 187.482H197.158Z"
      fill="#FAFF00"
    />
    <path
      d="M173.176 186.971L62.9492 182.372V186.971V187.482V192.097L173.176 187.482H185.422L179.308 187.226L185.422 186.971H173.176Z"
      fill="#FAFF00"
    />
    <path
      d="M193.837 178.932L275.026 104.249L271.773 100.996L271.398 100.639L268.145 97.3685L193.463 178.574L184.811 187.226L189.324 183.088L185.168 187.601L193.837 178.932Z"
      fill="#FAFF00"
    />
    <path
      d="M176.514 195.538L95.3086 270.203L98.5616 273.473L98.9363 273.831L102.189 277.101L176.872 195.895L185.524 187.226L181.011 191.382L185.166 186.869L176.514 195.538Z"
      fill="#FAFF00"
    />
    <path
      d="M185.423 175.236L190.039 65.0259H185.423H184.912H180.297L184.912 175.236V187.482L185.168 181.367L185.423 187.482V175.236Z"
      fill="#FAFF00"
    />
    <path
      d="M184.912 199.216L180.297 309.444H184.912H185.423H190.039L185.423 199.216V186.971L185.168 193.102L184.912 186.971V199.216Z"
      fill="#FAFF00"
    />
    <path
      d="M220.197 246.802L229.565 262.829L232.341 261.04L232.647 260.836L235.44 259.048L224.864 244.009L220.197 246.802Z"
      fill="#EDD500"
    />
    <path
      d="M149.073 125.845L140.779 111.641L138.003 113.429L137.696 113.634L134.92 115.422L144.304 128.774C145.854 127.719 147.455 126.748 149.09 125.862L149.073 125.845Z"
      fill="#EDD500"
    />
    <path
      d="M253.834 199.387C253.459 201.175 253 202.946 252.438 204.684L269.997 209.282L270.695 206.046L270.78 205.688L271.479 202.452L253.817 199.37L253.834 199.387Z"
      fill="#EDD500"
    />
    <path
      d="M116.613 169.446L100.314 165.171L99.6155 168.407L99.5303 168.764L98.832 172L115.404 174.896C115.71 173.056 116.119 171.251 116.63 169.463L116.613 169.446Z"
      fill="#EDD500"
    />
    <path
      d="M245.795 151.58L260.765 142.825L258.977 140.049L258.773 139.743L256.984 136.967L242.865 146.896C243.904 148.429 244.875 149.996 245.795 151.597V151.58Z"
      fill="#EDD500"
    />
    <path
      d="M124.838 222.703L109.578 231.627L111.366 234.403L111.571 234.71L113.359 237.486L127.887 227.267C127.887 227.267 127.904 227.233 127.887 227.216L124.872 222.686C124.872 222.686 124.855 222.686 124.838 222.686V222.703Z"
      fill="#EDD500"
    />
    <path
      d="M203.014 118.471L207.221 102.393L203.985 101.695L203.627 101.609L200.391 100.911L197.547 117.244C199.386 117.585 201.209 117.994 202.997 118.471H203.014Z"
      fill="#EDD500"
    />
    <path
      d="M167.776 254.313L163.109 272.094L166.345 272.792L166.703 272.877L169.939 273.576L173.107 255.403L167.81 254.296C167.81 254.296 167.776 254.296 167.776 254.313Z"
      fill="#EDD500"
    />
    <path
      d="M187.38 202.282L204.905 341.02L210.662 340.049L211.309 339.947L217.066 338.959L188.027 202.163L185.438 186.852L186.409 194.567L184.791 186.971L187.38 202.282Z"
      fill="#B480C5"
    />
    <path
      d="M182.951 172.188L165.443 33.4497L159.669 34.4205L159.039 34.5227L153.266 35.5105L182.321 172.307L184.893 187.601L183.922 179.903L185.54 187.499L182.951 172.188Z"
      fill="#B480C5"
    />
    <path
      d="M197.361 196.304L307.861 282.023L311.25 277.254L311.625 276.726L315.014 271.975L197.736 195.776L185.099 186.784L191.23 191.535L184.707 187.312L197.361 196.304Z"
      fill="#B480C5"
    />
    <path
      d="M172.971 178.166L62.4716 92.4465L59.0823 97.1983L58.7076 97.7433L55.3184 102.495L172.597 178.694L185.251 187.686L179.103 182.917L185.626 187.158L172.971 178.166Z"
      fill="#B480C5"
    />
    <path
      d="M200.204 185.029L338.959 167.504L337.972 161.747L337.869 161.1L336.899 155.326L200.102 184.382L184.791 186.971L192.506 186L184.893 187.601L200.204 185.029Z"
      fill="#B480C5"
    />
    <path
      d="M170.128 189.44L31.373 206.966L32.3609 212.722L32.4631 213.369L33.4339 219.126L170.23 190.088L185.541 187.499L177.826 188.47L185.439 186.852L170.128 189.44Z"
      fill="#B480C5"
    />
    <path
      d="M194.245 175.032L279.964 64.532L275.195 61.1598L274.667 60.7681L269.898 57.3959L193.717 174.657L184.707 187.311L189.476 181.18L185.252 187.686L194.245 175.032Z"
      fill="#B480C5"
    />
    <path
      d="M176.088 199.438L90.3691 309.938L95.1379 313.31L95.6659 313.701L100.435 317.074L176.633 199.812L185.626 187.158L180.857 193.289L185.098 186.783L176.088 199.438Z"
      fill="#B480C5"
    />
    <path
      d="M188.096 194.567L213.677 262.505L216.47 261.33L216.777 261.194L219.57 260.002L188.403 194.431L185.252 187.005L186.682 190.786L184.945 187.141L188.096 194.567Z"
      fill="#D9B0E7"
    />
    <path
      d="M182.236 179.903L156.655 111.964L153.861 113.14L153.555 113.276L150.762 114.468L181.929 180.039L185.08 187.448L183.649 183.684L185.387 187.328L182.236 179.903Z"
      fill="#D9B0E7"
    />
    <path
      d="M192.418 190.343L258.551 220.301L259.692 217.491L259.811 217.167L260.953 214.357L192.537 190.036L185.078 187.022L188.739 188.674L184.941 187.328L192.418 190.343Z"
      fill="#D9B0E7"
    />
    <path
      d="M177.909 184.126L111.776 154.168L110.635 156.978L110.516 157.302L109.375 160.112L177.79 184.433L185.267 187.448L181.588 185.796L185.386 187.141L177.909 184.126Z"
      fill="#D9B0E7"
    />
    <path
      d="M192.49 184.297L260.446 158.716L259.253 155.922L259.134 155.616L257.942 152.823L192.371 183.99L184.945 187.141L188.726 185.727L185.082 187.448L192.49 184.297Z"
      fill="#D9B0E7"
    />
    <path
      d="M177.842 190.173L109.887 215.754L111.079 218.547L111.215 218.854L112.39 221.647L177.961 190.479L185.387 187.328L181.606 188.742L185.251 187.005L177.842 190.173Z"
      fill="#D9B0E7"
    />
    <path
      d="M188.278 179.971L218.236 113.838L215.409 112.714L215.102 112.578L212.292 111.453L187.954 179.852L184.939 187.328L186.608 183.65L185.263 187.448L188.278 179.971Z"
      fill="#D9B0E7"
    />
    <path
      d="M182.047 194.482L152.105 260.632L154.916 261.756L155.222 261.892L158.032 263.016L182.37 194.618L185.385 187.141L183.716 190.82L185.078 187.022L182.047 194.482Z"
      fill="#D9B0E7"
    />
    <path d="M281.102 280.03L321.875 313.174L313.172 321.877L281.102 280.03Z" fill="#FAFF00" />
    <path d="M90.8962 91.9696L50.123 58.8265L58.8261 50.1234L90.8962 91.9696Z" fill="#FAFF00" />
    <path d="M319.73 185.234L372 179.852V192.148L319.73 185.234Z" fill="#FAFF00" />
    <path d="M52.2694 186.766L0 192.148V179.852L52.2694 186.766Z" fill="#FAFF00" />
    <path d="M280.029 90.8966L313.172 50.1234L321.875 58.8265L280.029 90.8966Z" fill="#FAFF00" />
    <path d="M91.9692 281.103L58.8261 321.877L50.123 313.174L91.9692 281.103Z" fill="#FAFF00" />
    <path d="M185.231 52.2694L179.85 0H192.146L185.231 52.2694Z" fill="#FAFF00" />
    <path d="M186.764 319.748L192.146 372H179.85L186.764 319.748Z" fill="#FAFF00" />
  </svg>
);
