import styled from 'styled-components';
import TaskCard from '../components/pages/Tasks/TaskCard';
import { useApp } from '../contexts/AppContext';
import { IconCoin } from '../components/Icons/IconCoin';
import React from 'react';
import { env } from '../lib/env';
import Row from '../components/Row';
import { MainButton } from '../components/MainButton';
import { deleteUser } from '../lib/api';
import IconSepuku from '../components/Icons/IconSepuku';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../lib/utils';

const Header = styled.div`
  width: 710px;
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 66px */
  letter-spacing: -1.2px;
  text-transform: uppercase;
  margin-bottom: 30px;

  svg {
    margin-top: -30px;
    width: 200px;
    height: 200px;
  }
`;

const Label = styled.div`
  color: #fff;
  font-family: var(--font), sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0;
`;

const Tasks = () => {
  const { tasks } = useApp();
  const { t } = useTranslation();
  const allowSepuku = (!env.isProd || env.isVercel) && localStorage.getItem('debug') === 'on';

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Header>
          <IconCoin />
          <br />
          {t('tasks.moreCoins')}
        </Header>
      </motion.div>
      <Label>{t('tasks.tasksList')}</Label>
      <div style={{ maxHeight: '862px', overflow: 'scroll' }}>
        {tasks.map((task, i) => (
          <TaskCard key={task.id} task={task} />
        ))}
        {allowSepuku && (
          <Row margin="30px 0 37px" gap="40px">
            <MainButton
              onClick={async () => {
                await deleteUser();
                alert('User removed. Tap ok to reload app');
                window.location.reload();
              }}
            >
              commit seppuku
              <IconSepuku />
            </MainButton>
          </Row>
        )}
      </div>
    </PageContainer>
  );
};

export default Tasks;
