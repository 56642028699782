import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Option from '../components/pages/Settings/Option';
import { useNavigate } from 'react-router-dom';
import i18n, { languages } from '../lib/i18n';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../lib/utils';

const Header = styled.div`
  color: white;
  margin: 50px 0 35px;
  text-align: center;
  font-size: 81px;
  font-weight: 500;
`;

const Settings = ({ selection }: { selection: 'network' | 'language' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const header = selection === 'network' ? t('settings.chooseNetwork') : t('settings.chooseLanguage');

  const handleOnclick = async (id: string) => {
    await i18n.changeLanguage(id);
    navigate('/statistics');
  };

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer vcenter>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Header>{header}</Header>
      </motion.div>
      <div style={{ maxHeight: '1145px', overflow: 'scroll' }}>
        {Object.entries(languages).map(([languageId, language]) => (
          <Option
            key={languageId}
            label={`${language} (${languageId})`}
            selected={i18n.language.substring(0, 2) === languageId}
            onClick={() => handleOnclick(languageId)}
          />
        ))}
      </div>
    </PageContainer>
  );
};

export default Settings;
