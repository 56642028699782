export const speedQuotesRu = [
  ['Скорость нужна, чтобы догнать свою удачу.', 'Москва слезам не верит'],
  ['Тише едешь – дальше будешь, но быстрее не получится.', 'Иван Васильевич меняет профессию'],
  ['Надо успевать везде и всюду!', 'Гараж'],
  ['В нашем деле, чем быстрее, тем лучше.', 'Брат 2'],
  ['Скорость решает всё!', 'Гонщики'],
  ['Торопись, но осторожно.', 'Ирония судьбы, или С легким паром!'],
  ['Быстрее, товарищи, время не ждёт!', 'Операция Ы и другие приключения Шурика'],
  ['Если бегать быстро, можно догнать мечту.', 'Джентльмены удачи'],
  ['Скорость — это жизнь.', 'Покровские ворота'],
  ['Быстрое решение – половина победы.', 'Девчата'],
  ['Кто не успел, тот опоздал.', 'Кавказская пленница'],
  ['Скорость — главное оружие.', 'Белое солнце пустыни'],
  ['Быстрее, товарищи, быстрее!', 'Неуловимые мстители'],
  ['Жизнь — это гонка, успей первым!', 'Экипаж'],
  ['Скорость — это сила!', 'В бой идут одни старики'],
  ['Сила есть – ума не надо.', 'Операция Ы и другие приключения Шурика'],
  ['Главное – духом не падать, а силой надавить.', 'Брат'],
  ['Тот, кто сильный, всегда добьётся своего.', 'Москва слезам не верит'],
  ['Сила в правде, брат!', 'Брат 2'],
  ['Сила духа – это главное.', 'Кин-дза-дза!'],
  ['Сила есть – воля будет.', 'Мимино'],
  ['Сильные всегда идут до конца.', 'Кавказская пленница'],
  ['Тот, кто силён, всегда побеждает.', 'Гараж'],
  ['Сила приходит с опытом и мудростью.', 'Афоня'],
  ['Настоящая сила – это выдержка.', 'Любовь и голуби'],
  ['Сильные всегда защищают слабых.', 'Приключения Шерлока Холмса и доктора Ватсона'],
  ['Сила – это когда можешь защитить.', 'Зимний вечер в Гаграх'],
  ['Сила духа сильнее всего.', 'Собачье сердце'],
  ['Сила в единстве.', 'Иван Васильевич меняет профессию'],
  ['Сила – это когда ты не сдаешься.', 'Белое солнце пустыни'],
  ['Сила и смелость – ключ к победе.', 'А зори здесь тихие'],
  ['Сила – это когда можешь бороться за справедливость.', 'Блондинка за углом'],
  ['Сила есть, силы и останутся.', 'Они сражались за Родину'],
  ['Сила воли – это главная сила.', 'Служебный роман'],
  ['Сильный всегда знает, что делать.', 'Завтра была война'],
  ['Сила – это в тебе.', 'Три орешка для Золушки'],
  ['Настоящая сила – это доброта.', 'Курьер'],
  ['Сила духа побеждает всё.', 'Брат'],
  ['Сила и мужество идут вместе.', 'Собачье сердце'],
  ['Только сильные достигают цели.', 'Иван Бровкин на целине'],
  ['Сила – это когда ты не боишься.', 'Неуловимые мстители'],
  ['Сила духа преодолевает любые трудности.', 'Кин-дза-дза!'],
  ['Сила воли – это путь к победе.', 'В бой идут одни старики'],
  ['Сила и честь – это главное.', 'Экипаж'],
];

export const patienceQuotesRu = [
  ['Терпение и труд всё перетрут.', 'Морозко'],
  ['Терпение – это тоже подвиг.', 'Они сражались за Родину'],
  ['Всё приходит к тому, кто умеет ждать.', 'Девчата'],
  ['Терпение – путь к успеху.', 'Москва слезам не верит'],
  ['Сидишь, ждешь, а потом всё сразу и навалится.', 'Ирония судьбы, или С легким паром!'],
  ['Нужно терпение, чтобы дождаться настоящего.', 'Джентльмены удачи'],
  ['Кто терпел, тот и выиграл.', 'Блондинка за углом'],
  ['Терпеть и не сдаваться – вот секрет.', 'Служебный роман'],
  ['Не торопись, а дождись.', 'Любовь и голуби'],
  ['Всё лучшее приходит с терпением.', 'Три орешка для Золушки'],
  ['Победа приходит к терпеливым.', 'Операция Ы и другие приключения Шурика'],
  ['Терпение – это мудрость времени.', 'Белое солнце пустыни'],
  ['Терпение и выдержка – вот чего нам не хватает.', 'Брат'],
  ['Терпение всегда вознаграждается.', 'Кавказская пленница'],
  ['Ждать и верить – это тоже искусство.', 'Завтра была война'],
  ['Терпение делает человека сильным.', 'Афоня'],
  ['Нужно время, чтобы научиться терпению.', 'Сказка о потерянном времени'],
  ['Кто терпит, тот и победит.', 'В бой идут одни старики'],
  ['Терпение – это залог успеха.', 'Приключения Шерлока Холмса и доктора Ватсона'],
  ['Терпение – это сила души.', 'Гараж'],
  ['Терпение и вера идут рука об руку.', 'Война и мир'],
  ['Всё проходит, если терпеливо ждать.', 'Собачье сердце'],
  ['Терпение учит нас многому.', 'Мимино'],
  ['Кто умеет терпеть, тот сможет всё.', 'А зори здесь тихие'],
  ['Терпение приносит плоды.', 'Иван Васильевич меняет профессию'],
  ['Терпеливые достигают высот.', 'Кин-дза-дза!'],
  ['Кто ждёт – тот дождётся.', 'Иван Бровкин на целине'],
  ['Терпение – ключ к мудрости.', 'Зимний вечер в Гаграх'],
  ['Терпение делает нас сильнее.', 'Курьер'],
  ['Терпение – это добродетель сильных.', 'Зеркало'],
];

export const emotionalIntelligenceQuotesRu = [
  ['Надо уметь чувствовать, когда не стоит говорить.', 'Ирония судьбы, или С легким паром!'],
  ['Доброе слово и кошке приятно.', 'Морозко'],
  ['Самое сложное – понять друг друга.', 'Москва слезам не верит'],
  ['Слово может и ранить, и вылечить.', 'Афоня'],
  ['Тонкие вещи надо чувствовать сердцем, а не умом.', 'Девчата'],
  ['Человек – это не только разум, но и душа.', 'Белое солнце пустыни'],
  ['Умение слушать – редкий талант.', 'Операция Ы и другие приключения Шурика'],
  ['Истинное понимание – в молчании.', 'Служебный роман'],
  ['Чтобы понять другого, нужно стать на его место.', 'Кавказская пленница'],
  ['Люди не всегда говорят то, что чувствуют.', 'Джентльмены удачи'],
  ['Доброе сердце – лучший советчик.', 'Сказка о потерянном времени'],
  ['Иногда важно просто быть рядом.', 'Зимний вечер в Гаграх'],
  ['Умение прощать – это признак силы.', 'Собачье сердце'],
  ['Человек человеку – зеркало.', '12 стульев'],
  ['Чувства – это язык души.', 'Мимино'],
  ['Важно не то, что ты говоришь, а как ты это делаешь.', 'Брат'],
  ['Тепло души согревает даже в самую холодную зиму.', 'Зеркало'],
  ['Слова – лишь поверхность, настоящие чувства скрыты глубже.', 'Асса'],
  ['Понимание – это мост между сердцами.', 'Иван Васильевич меняет профессию'],
  ['Сердце знает больше, чем разум.', 'Гараж'],
  ['Не суди человека, не зная его боль.', 'Комиссар'],
  ['Слушай сердцем, и ты услышишь правду.', 'Кин-дза-дза!'],
  ['Настоящая мудрость – в умении понимать других.', 'Покровские ворота'],
  ['Эмоции – это ключ к пониманию.', 'Кавказская пленница'],
  ['Вежливость – это зеркало души.', 'Курьер'],
  ['Тишина иногда говорит громче слов.', 'Утомленные солнцем'],
  ['Самое важное в общении – это искренность.', 'Сталкер'],
  ['Мудрость – это когда слова идут от сердца.', 'Любовь и голуби'],
  ['Понимание – это основа дружбы.', 'Большая перемена'],
  ['Иногда нужно просто выслушать.', 'Человек с бульвара Капуцинов'],
];

export const stressResistanceQuotesRu = [
  ['Боль временная, гордость вечная.', 'Вратарь Галактики'],
  ['Если хочешь что-то сделать, сделай это сам.', 'Белое солнце пустыни'],
  ['Живи и давай жить другим.', 'Служебный роман'],
  ['Смело идите навстречу трудностям, они вам помогут стать сильнее.', 'Кавказская пленница'],
  ['Не важно, как сильно тебя били, главное – что ты встал и пошёл дальше.', 'Москва слезам не верит'],
  ['На всё воля Божья, а нам остаётся только терпеть.', 'Подкидыш'],
  ['Выживает тот, кто умеет находить выход из трудных ситуаций.', 'Летят журавли'],
  ['Величайшая доблесть – это мужество в трудные времена.', 'Девчата'],
  ['Тот, кто стоит в тени, должен готовиться к трудностям.', '17 мгновений весны'],
  ['Пока ты жив, всё возможно.', 'Кин-дза-дза!'],
  ['Терпение и труд всё перетрут.', 'Человек с бульвара Капуцинов'],
  ['Мы переживём это вместе.', 'Улица разбитых фонарей'],
  ['Не важно, как трудно, главное – верить в себя.', 'Комиссар'],
  ['Чем больше трудностей, тем больше у нас возможностей для роста.', 'Собачье сердце'],
  ['Каждый день – это шанс стать лучше.', 'Драма'],
  ['Сложности – это лишь временные испытания.', 'Гусарская баллада'],
  ['Верь в лучшее и преодолевай трудности.', 'Ирония судьбы'],
  ['Каждая проблема – это шанс стать сильнее.', 'Операция Ы'],
  ['Преодолей свои страхи и двигайся вперёд.', 'Крымские каникулы'],
  ['Сдержанность и мужество в трудные времена – вот что важно.', 'Мимино'],
  ['Главное – не сдаваться и идти вперёд.', 'Свой среди чужих'],
  ['Если жизнь бросает вызов, отвечай ударом.', 'Человек амфибия'],
  ['Терпение – это ключ к успеху.', 'В зоне особого внимания'],
  ['Не стоит отчаиваться, просто продолжай идти.', 'Старики-разбойники'],
  ['Не бойся трудностей, они тебя закаляют.', 'Кавказская пленница'],
  ['Даже в самых трудных ситуациях нужно сохранять спокойствие.', 'Зимний вечер в Гаграх'],
  ['Вместе мы справимся с любой проблемой.', 'Майор Вихрь'],
  ['Будь сильным, несмотря на все трудности.', 'Батальонъ'],
  ['Преодолевай преграды и двигайся вперёд.', 'Большая перемена'],
];
