import styled from 'styled-components';

import MenuMain from './MenuMain';
import MenuTop from './MenuTop';
import { useLocation } from 'react-router-dom';

const FixedContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Menu = () => {
  const location = useLocation();

  console.log(location);

  if (location.pathname === '/welcome') {
    return false;
  }
  return (
    <FixedContainer>
      <MenuTop />
      <MenuMain />
    </FixedContainer>
  );
};

export default Menu;
