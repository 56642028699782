import React from 'react';

import Row from '../../Row';
import { ClaimFriendsButton } from './ClaimFriendsButton';

const ClaimReward: React.FC = () => (
  <div>
    <Row gap="36px" margin="0 0 20px">
      <ClaimFriendsButton />
    </Row>
  </div>
);

export default ClaimReward;
