export const speedQuotes = [
  ['I feel the need, the need for speed!', 'Top Gun'],
  ['Speed. I am speed.', 'Cars'],
  [
    "Pop quiz, hotshot: There's a bomb on a bus. Once the bus goes 50 miles an hour, the bomb is armed. If it drops below 50, it blows up. What do you do?",
    'Speed',
  ],
  ['You know what happens if you go below 50 miles per hour? BOOM!', 'Speed'],
  ["Speed has never killed anyone. Suddenly becoming stationary, that's what gets you.", 'Top Gear'],
  ["In racing, it's not who's fastest, it's who can slow down the least.", 'Rush'],
  [
    'You live more for five minutes going fast on a bike like that than other people do in all of their life.',
    "The World's Fastest Indian",
  ],
  ['I’m faster than fast, quicker than quick. I am Lightning.', 'Cars'],
  ['Too close for missiles, I’m switching to guns.', 'Top Gun'], // Speed context in dogfighting
  [
    'This is a race! What’s the matter with you? Are you slow? You’re going to get us both killed!',
    'Mad Max: Fury Road',
  ],
  ['The speed limit is just a suggestion.', 'Fast & Furious'],
  ['It’s all about the speed, baby.', 'Speed Racer'],
  ["Remember, speed's just a matter of attitude. You either got it or you don’t.", 'Fast & Furious'],
  ['I’m not afraid of dying. I’m afraid of slowing down.', 'Speed'],
  ['Life is measured in achievement, not in years alone.', 'The Fast and the Furious'], // Implied speed of life
  [
    "A lot of people go through life doing things badly. Racing's important to men who do it well. When you're racing, it's life. Anything that happens before or after is just waiting.",
    'Le Mans',
  ],
  ['We ride together. We die together. Bad boys for life.', 'Bad Boys II'], // Speed and intensity
  ["Keep your foot on the throttle, or you'll never make it.", 'Speed Racer'],
  ["Winning's winning, whether you win by an inch or a mile.", 'The Fast and the Furious'],
  ['Fast cars, fast life.', 'Fast & Furious'],
  ['What are you smiling about? Dude, I almost had you.', 'The Fast and the Furious'],
  ['It’s not the speed that breaks you; it’s the sudden stop.', 'Speed'],
  ['There’s no speed limit on the road to success.', 'Talladega Nights: The Ballad of Ricky Bobby'],
  ["It doesn't matter if you win by an inch or a mile. Winning's winning.", 'The Fast and the Furious'],
  ['I’ve been living my life a quarter-mile at a time.', 'The Fast and the Furious'],
  ['If you ain’t out of control, you ain’t in control.', 'The Fast and the Furious: Tokyo Drift'],
  ['We’re gonna need a bigger speedometer.', 'Jaws'], // Playful adaptation from Jaws for speed context
  ['Speed’s got everything to do with it.', 'Fast & Furious'],
  ['You can’t put a limit on anything. The more you dream, the farther you get.', "The World's Fastest Indian"],
  ['When you’re racing, it’s life. Anything that happens before or after is just waiting.', 'Le Mans'],
  ['Feel the power of speed.', 'Speed Racer'],
  ['Control speed, control life.', 'The Fast and the Furious'],
];

export const patienceQuotes = [
  ['Patience, young grasshopper.', 'Kung Fu'],
  ['The strongest of all warriors are these two — Time and Patience.', 'War and Peace'],
  ['Patience, you must have my young Padawan.', 'Star Wars: The Empire Strikes Back'],
  [
    'All we have to decide is what to do with the time that is given to us.',
    'The Lord of the Rings: The Fellowship of the Ring',
  ],
  ['Good things come to those who wait.', 'Silence of the Lambs'],
  ['I will not fall into despair! I will keep myself hardy until freedom is opportune.', '12 Years a Slave'],
  ['Wait for it... wait for it...', 'How I Met Your Mother'],
  ['Sometimes you have to slow down to speed up.', 'Kung Fu Panda'],
  ['Patience is a virtue.', 'The Mummy'],
  ["Why so serious? Let's put a smile on that face.", 'The Dark Knight'], // Implicit patience in execution
  ['The night is darkest just before the dawn. And I promise you, the dawn is coming.', 'The Dark Knight'],
  ['It’s not the destination, it’s the journey.', 'Peaceful Warrior'],
  ["Some people can't believe in themselves until someone else believes in them first.", 'Good Will Hunting'],
  ['Our patience will achieve more than our force.', 'Edmund Burke'],
  ['Be still, my heart; thou hast known worse than this.', "Homer's Odyssey"],
  ['It is the calm and silent water that drowns a man.', 'War Horse'],
  ['Why do we fall? So we can learn to pick ourselves up.', 'Batman Begins'],
  ['A wise man can learn more from his enemies than a fool from his friends.', 'Rush Hour'],
  ['The waiting is the hardest part.', 'Tom Petty & The Heartbreakers: The Waiting'],
  ['He who is not courageous enough to take risks will accomplish nothing in life.', 'Ali'],
  ['Peace comes from within. Do not seek it without.', 'The Buddha'],
  [
    'Never forget what you are. The rest of the world will not. Wear it like armor, and it can never be used to hurt you.',
    'Game of Thrones',
  ],
  [
    'In time, you will know what it’s like to lose, to feel so desperately that you’re right, yet to fail all the same.',
    'Avengers: Infinity War',
  ],
  ['A man who has nothing can still have faith.', 'The Book of Eli'],
  ["If you're good at something, never do it for free.", 'The Dark Knight'],
  ["It's not who I am underneath, but what I do that defines me.", 'Batman Begins'],
  ['To achieve anything, you must be prepared to dabble on the boundary of disaster.', 'Stargate SG-1'],
  [
    'Life moves pretty fast. If you don’t stop and look around once in a while, you could miss it.',
    "Ferris Bueller's Day Off",
  ],
  ['Calm down, breathe, and take it one step at a time.', 'Kung Fu Panda'],
  ["Patience isn't about waiting, it's about how you act while you're waiting.", 'The Karate Kid'],
];

export const emotionalIntelligenceQuotes = [
  ["It's not the anger that will kill you. It's the grief.", 'The Lion King'],
  [
    "Sometimes it's the people no one imagines anything of who do the things that no one can imagine.",
    'The Imitation Game',
  ],
  ["It's not about how hard you hit. It's about how hard you can get hit and keep moving forward.", 'Rocky Balboa'],
  ["The greatest thing you'll ever learn is just to love, and be loved in return.", 'Moulin Rouge!'],
  ["You can't handle the truth!", 'A Few Good Men'],
  ["There's a difference between knowing the path and walking the path.", 'The Matrix'],
  ['People should not be afraid of their governments. Governments should be afraid of their people.', 'V for Vendetta'],
  ["It's not who I am underneath, but what I do that defines me.", 'Batman Begins'],
  ['Sometimes you have to take a leap of faith first. The trust part comes later.', 'Man of Steel'],
  ['The force will be with you, always.', 'Star Wars'],
  ['You are what you love, not what loves you.', 'Adaptation'],
  [
    'It is not our abilities that show what we truly are, it is our choices.',
    'Harry Potter and the Chamber of Secrets',
  ],
  ['When you can’t run, you crawl. And when you can’t crawl, you find someone to carry you.', 'Firefly'],
  ['To love someone is to identify with them.', 'Avatar'],
  ['The needs of the many outweigh the needs of the few.', 'Star Trek II: The Wrath of Khan'],
  ['The hardest thing in this world is to live in it.', 'Buffy the Vampire Slayer'],
  ['The flower that blooms in adversity is the most rare and beautiful of all.', 'Mulan'],
  ['You are braver than you believe, stronger than you seem, and smarter than you think.', 'Winnie the Pooh'],
  ['We accept the love we think we deserve.', 'The Perks of Being a Wallflower'],
  [
    'Happiness can be found, even in the darkest of times, if one only remembers to turn on the light.',
    'Harry Potter and the Prisoner of Azkaban',
  ],
  ['Do or do not. There is no try.', 'Star Wars: The Empire Strikes Back'],
  [
    'The only thing standing between you and your goal is the story you keep telling yourself as to why you can’t achieve it.',
    'The Wolf of Wall Street',
  ],
  ['The moment you doubt whether you can fly, you cease forever to be able to do it.', 'Peter Pan'],
  ['It’s not what’s outside that counts, it’s what’s inside.', 'Aladdin'],
  ['Don’t let anyone ever make you feel like you don’t deserve what you want.', '10 Things I Hate About You'],
  ['The past can hurt. But the way I see it, you can either run from it, or learn from it.', 'The Lion King'],
  ["You can't change who people are without destroying who they were.", 'The Butterfly Effect'],
  ['The truth is, sometimes I miss you so much I can hardly stand it.', 'Brokeback Mountain'],
  [
    'Remember, Red, hope is a good thing, maybe the best of things, and no good thing ever dies.',
    'The Shawshank Redemption',
  ],
  ['Sometimes the right path is not the easiest one.', 'Pocahontas'],
];

export const stressResistanceQuotes = [
  [
    'You can’t control the things that happen to you, but you can control how you react to them.',
    'The Pursuit of Happyness',
  ],
  ['Fear does not exist in this dojo, does it?', 'The Karate Kid'],
  ['It’s not the load that breaks you down, it’s the way you carry it.', 'The Green Mile'],
  ['Pressure is a privilege.', 'A League of Their Own'],
  ["I'm not afraid of storms, for I'm learning how to sail my ship.", 'Little Women'],
  ['What lies behind us and what lies before us are tiny matters compared to what lies within us.', 'The Road'],
  ['Sometimes it’s the people no one imagines anything of who do the things no one can imagine.', 'The Imitation Game'],
  ['Keep moving forward.', 'Meet the Robinsons'],
  ['We are who we choose to be.', 'Spider-Man'],
  ['When you get knocked down, you get back up and keep going.', 'Rocky Balboa'],
  ['The night is darkest just before the dawn. And I promise you, the dawn is coming.', 'The Dark Knight'],
  ['The only way to achieve the impossible is to believe it is possible.', 'Alice in Wonderland'],
  ['Our greatest glory is not in never falling, but in rising every time we fall.', 'Mulan'],
  ['Endure, and in enduring grow strong.', 'The Lord of the Rings: The Two Towers'],
  [
    'You don’t get to choose how you’re going to die, or when. You can only decide how you’re going to live now.',
    'The Fault in Our Stars',
  ],
  [
    'Courage is not the absence of fear, but rather the judgment that something else is more important than fear.',
    'The Princess Diaries',
  ],
  ['It’s not who I am underneath, but what I do that defines me.', 'Batman Begins'],
  ['Don’t let anyone ever make you feel like you don’t deserve what you want.', '10 Things I Hate About You'],
  [
    'You have to do everything you can. You have to work your hardest. And if you stay positive, you have a shot at a silver lining.',
    'Silver Linings Playbook',
  ],
  [
    'In the darkest times, hope is something you give yourself. That is the meaning of inner strength.',
    'Avatar: The Last Airbender',
  ],
  ['It’s not the size of the dog in the fight, it’s the size of the fight in the dog.', 'Rocky'],
  ['After all this time? Always.', 'Harry Potter and the Deathly Hallows'],
  ['It’s not about how hard you can hit. It’s about how hard you can get hit and keep moving forward.', 'Rocky Balboa'],
  ['Hope is a good thing, maybe the best of things, and no good thing ever dies.', 'The Shawshank Redemption'],
  ['The flower that blooms in adversity is the most rare and beautiful of all.', 'Mulan'],
  ['You cannot live your life to please others. The choice must be yours.', 'Alice in Wonderland'],
  ['Just keep swimming.', 'Finding Nemo'],
  ['It’s not over until it’s over.', 'Rocky IV'],
  ['When life gets you down, you know what you gotta do? Just keep swimming.', 'Finding Nemo'],
  ['Pain is temporary, pride is forever.', 'Remember the Titans'],
];
