import styled from 'styled-components';

const IconHand = styled((props) => (
  <svg width={134} height={172} viewBox="0 0 134 172" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M42.4268 120.306C42.4268 120.306 34.2291 129.857 34.8659 141.53C32.3721 139.222 30.6476 137.63 30.4354 137.338C29.6926 136.224 15.844 109.614 13.9604 107.386C12.1033 105.131 1.73021 96.2431 1.22615 95.0227C0.615961 93.4575 0.934318 90.5922 6.10762 89.5841C9.23813 88.9739 20.3276 91.6534 25.3947 99.3206C30.4354 106.988 32.6904 114.682 34.574 115.982C36.4311 117.308 42.4268 120.306 42.4268 120.306Z"
      fill="#534138"
    />
    <path
      d="M67.1527 74.0104C48.3431 74.9655 41.2332 83.8796 41.2332 83.8796C40.8087 74.8859 40.3311 67.2187 39.8005 64.1147C38.1292 54.3781 37.572 51.1945 37.2006 45.3844C36.8027 39.5743 33.4334 12.0625 34.3885 11.1074C35.3435 10.1523 43.3821 9.80746 45.7963 13.5482C48.237 17.289 54.7898 44.8273 57.2306 51.5659C59.6183 58.1189 66.7548 73.2145 67.1527 74.0104Z"
      fill="#534138"
    />
    <path
      d="M83.8144 43.1299C84.5573 45.7564 92.9937 76.8497 92.9937 76.8497C83.8144 67.6703 67.1538 74.0375 67.1538 74.0375C67.1538 73.2151 67.1272 55.9705 65.8538 52.495C64.5273 48.94 47.2564 13.31 48.7952 8.87946C51.2359 1.84896 55.1623 1 56.6745 1C58.1867 1 64.3416 12.4345 67.1538 16.361C68.268 17.9262 71.0006 22.0384 73.9454 26.6016C73.9454 26.6281 73.9719 26.6547 73.9719 26.6812C78.4554 33.6321 83.3634 41.5646 83.8144 43.1299Z"
      fill="#534138"
    />
    <path
      d="M118.833 85.2859C109.017 74.0371 92.9933 76.8493 92.9933 76.8493C92.9933 76.8493 84.5568 45.756 83.814 43.1295C83.363 41.5642 78.455 33.6317 73.9715 26.6808C73.9715 26.6543 73.945 26.6277 73.945 26.6012C73.8388 25.9114 72.9634 20.1809 72.7776 15.8034C72.5919 11.1076 74.1837 9.14437 74.9266 8.58724C77.0755 6.99543 79.0387 7.92399 80.6304 9.33008C82.2222 10.7362 89.1465 25.2482 91.5872 29.095C94.028 32.9419 103.473 47.8253 104.985 51.3804C106.47 54.9354 117.719 83.2166 118.833 85.2859Z"
      fill="#534138"
    />
    <path
      d="M130.083 119.722L128.386 142.406L132.524 130.122C132.524 130.122 132.312 142.406 130.455 146.703C128.571 151.001 122.761 160.367 117.906 162.622C117.906 162.622 104.986 169.36 95.6214 170.66C86.2564 171.987 76.8914 170.66 76.8914 170.66C76.8914 170.66 60.7878 165.619 58.3471 162.993C56.5165 161.03 42.2701 148.295 34.8683 141.53C34.2316 129.857 42.4292 120.306 42.4292 120.306C42.217 111.365 41.7925 96.3492 41.2354 83.8801C41.2354 83.8801 48.3454 74.9659 67.155 74.0374C67.155 74.0374 83.8156 67.6701 92.9949 76.8496C92.9949 76.8496 109.019 74.0374 118.835 85.2862C119.976 87.329 128.014 128.159 128.014 128.159L130.083 119.722Z"
      fill="#D4A356"
    />
    <path
      d="M42.4268 120.306C42.4268 120.306 36.4311 117.308 34.574 115.981C32.6904 114.681 30.4354 106.988 25.3947 99.3204C20.3276 91.6532 9.23813 88.9736 6.10762 89.5838C0.934318 90.592 0.615961 93.4572 1.22615 95.0225C1.73021 96.2429 12.1033 105.13 13.9604 107.386C15.844 109.614 29.6926 136.224 30.4354 137.338C30.6476 137.63 32.3721 139.222 34.8659 141.53C42.2676 148.295 56.5141 161.03 58.3447 162.993C60.7854 165.619 76.889 170.66 76.889 170.66C76.889 170.66 86.2539 171.986 95.6189 170.66C104.984 169.36 117.904 162.621 117.904 162.621C122.759 160.366 128.569 151.001 130.452 146.703C132.31 142.405 132.522 130.122 132.522 130.122L128.383 142.405L130.081 119.722L128.012 128.159C128.012 128.159 119.973 87.3287 118.832 85.2859C117.718 83.2166 106.47 54.9354 104.984 51.3804C103.472 47.8253 94.0272 32.9419 91.5864 29.0951C89.1457 25.2482 82.2214 10.7362 80.6296 9.33009C79.0379 7.92399 77.0747 6.99543 74.9258 8.58724C74.1829 9.14437 72.5911 11.1076 72.7768 15.8034C72.9625 20.1809 73.838 25.9114 73.9442 26.6012"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path
      d="M67.153 74.0375C66.7551 73.2151 59.6186 58.1194 57.2309 51.5665C54.7902 44.8278 48.2373 17.2895 45.7966 13.5488C43.3824 9.80802 35.3173 10.1794 34.3888 11.108C33.4603 12.0366 36.803 39.5749 37.2009 45.385C37.5724 51.1951 38.1295 54.3787 39.8009 64.1152C40.3315 67.2193 40.809 74.8865 41.2335 83.8802C41.7906 96.3494 42.2151 111.365 42.4273 120.306C42.56 125.108 42.613 128.159 42.613 128.159"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path
      d="M92.9937 76.8497C92.9937 76.8497 84.5573 45.7564 83.8144 43.1299C83.3634 41.5646 78.4554 33.6321 73.9719 26.6812C73.9719 26.6547 73.9454 26.6281 73.9454 26.6016C71.0006 22.0384 68.268 17.9262 67.1538 16.361C64.3416 12.4345 58.1602 1 56.6745 1C55.1888 1 51.2359 1.84896 48.7952 8.87946C47.2564 13.31 64.5273 48.94 65.8538 52.495C67.1272 55.9705 67.1538 73.2151 67.1538 74.011"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path
      d="M41.2305 83.8798C41.2305 83.8798 48.3404 74.9657 67.15 74.0371"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path
      d="M118.835 85.2862C109.019 74.0374 92.9951 76.8496 92.9951 76.8496"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path
      d="M67.1523 74.0374C67.1523 74.0374 83.813 67.6701 92.9923 76.8496"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path
      d="M86.9434 80.9614L91.4269 85.392L92.9921 103.061L95.3002 96.7469L96.8124 121.553"
      stroke="black"
      strokeWidth={1.2003}
      strokeMiterlimit={10}
    />
    <path d="M101.297 138.903V126.912" stroke="black" strokeWidth={1.2003} strokeMiterlimit={10} />
    <path d="M70.335 88.0986L74.341 104.574" stroke="black" strokeWidth={1.2003} strokeMiterlimit={10} />
    <path d="M75.0859 98.5781L76.89 111.047" stroke="black" strokeWidth={1.2003} strokeMiterlimit={10} />
    <path
      d="M42.4292 120.306C42.4292 120.306 34.2316 129.857 34.8683 141.53"
      stroke="black"
      strokeWidth={1.2003}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))`
  transform: scaleX(${({ left }) => (left ? -1 : 1)});
`;

export default IconHand;
