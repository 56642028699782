import { useNavigate } from 'react-router-dom';

import { ButtonContainer, Group, IconArrow } from './Wrappers';
import Text from './Text';
import { IconCalm } from '../Icons/IconCalm';
import { useApp } from '../../contexts/AppContext';
import { getFuelLevel } from '../../lib/farming/util';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Calm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { app } = useApp();

  const level = getFuelLevel(app);
  const m = moment(0).utc().add(level.duration, 'seconds');
  const hh = m.format('H');
  const mm = moment(0).utc().add(level.duration, 'seconds').format('m');
  const ss = moment(0).utc().add(level.duration, 'seconds').format('s');

  return (
    <ButtonContainer onClick={() => navigate('/boost/calm')}>
      <Group>
        <IconCalm />
        <Text
          heading={t('boosts.calm.mentalEnergy')}
          level={t('system.levelShort') + ` ${app.state.fuelLevel}/30`}
          reward={t('boosts.for') + ' ' + t('boosts.calm.duration', { hours: hh, minutes: mm, seconds: ss })}
        />
      </Group>
      <IconArrow />
    </ButtonContainer>
  );
};

export default Calm;
