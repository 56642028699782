import React from 'react';
import styled from 'styled-components';
import Row from '../../Row';
import { amountWithSpaces } from '../../../lib/utils';
import getIcon from './TaskIcons';
import { IconCoin } from '../../Icons/IconCoin';
import { useNavigate } from 'react-router-dom';
import { ITask } from '../../../lib/types';
import { useApp } from '../../../contexts/AppContext';

const Container = styled.div<{ done?: boolean }>`
  height: 130px;
  width: 780px;
  border-radius: 84px;
  border: ${({ done }) => (done ? '1.5px solid #FAFF00' : '1.5px solid #ebebeb9c')};
  background: rgba(50, 54, 60, 0.64);

  padding: 0 33px;
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  color: #faff00;
  width: 510px;
  font-size: 32px;
  font-family: var(--font), sans-serif;
  margin-left: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Amount = styled.div`
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin: 0 20px;
`;

const DoneIcon = styled((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79" fill="none" {...props}>
    <rect width="79" height="79" rx="39.5" fill="#FAFF00" fillOpacity="0.6" />
    <rect
      x="0.486"
      y="0.486"
      width="78.028"
      height="78.028"
      rx="39.014"
      stroke="#EBEBEB"
      strokeOpacity="0.61"
      strokeWidth="0.972"
    />
    <path
      d="M62.5034 28.6389L37.1409 54.733C35.9037 56.0059 33.8967 56.0059 32.6595 54.733L23.8364 45.6553C22.4527 44.2318 22.4191 41.8656 23.8297 40.4697C25.1972 39.1166 27.3707 39.1332 28.7181 40.5194L34.9002 46.8799L57.5666 23.5597C58.9301 22.1569 61.1399 22.1569 62.5034 23.5597C63.8669 24.9625 63.8669 27.2361 62.5034 28.6389Z"
      fill="#575757"
    />
  </svg>
))``;

const NextIcon = styled((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="47" viewBox="0 0 26 47" fill="none" {...props}>
    <path
      d="M2.18239 47C1.62246 47 1.07167 46.79 0.640246 46.37C-0.213415 45.5209 -0.213415 44.1514 0.640246 43.3114L20.7426 23.3163L1.01662 3.69546C0.162961 2.84635 0.162961 1.47681 1.01662 0.636832C1.8611 -0.212277 3.24713 -0.212277 4.09162 0.636832L25.3598 21.7915C26.2134 22.6406 26.2134 24.0102 25.3598 24.8501L3.72448 46.37C3.30224 46.79 2.74232 47 2.18239 47Z"
      fill="white"
    />
  </svg>
))`
  padding: 0 25px;
`;

const TaskCard = ({ task }: { task: ITask }) => {
  const { taskFactor } = useApp();
  const navigate = useNavigate();
  const Icon = getIcon(task);

  const handleOnClick = () => {
    navigate(`/task/${task.id}`);
  };

  return (
    <Container done={task.isCompleted} onClick={handleOnClick}>
      <Row spread={true} style={{ width: '100%' }}>
        <Row>
          <Icon />
          <div>
            <Label>{task.title}</Label>
            {!task.isCompleted && (
              <Row style={{ justifyContent: 'left' }}>
                <Amount>+{amountWithSpaces(task.reward * taskFactor)}</Amount>
                <IconCoin />
              </Row>
            )}
          </div>
        </Row>
        {task.isCompleted && <DoneIcon />}
        {!task.isCompleted && <NextIcon />}
      </Row>
    </Container>
  );
};

export default TaskCard;
