import styled from 'styled-components';
import React, { useState } from 'react';
import RefreshIcon from './RefreshIcon';
import { useApp } from '../../../../contexts/AppContext';
import { useInterval } from 'react-use';

const Container = styled.div``;

const Seconds = styled.div`
  position: absolute;
  font-size: 20px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
`;

const RefreshButton = () => {
  const [loading, setLoading] = useState(false);
  const [, setUpdate] = useState(Infinity);
  const { updateApp, lastAppUpdate } = useApp();

  const getSecondsLeft = () => Math.round(coolDownSeconds - (Date.now() - lastAppUpdate) / 1000);
  const coolDownSeconds = 60;
  const secondsLeft = getSecondsLeft();
  const coolDown = secondsLeft > 0 && secondsLeft < coolDownSeconds * 1000;

  useInterval(() => {
    setUpdate(getSecondsLeft());
  }, 1000);

  const handleOnclick = async () => {
    setLoading(true);
    await updateApp();
    setLoading(false);
  };

  return (
    <Container>
      {!loading && coolDown && !!secondsLeft && <Seconds>{secondsLeft}</Seconds>}
      <RefreshIcon onClick={handleOnclick} isLoading={loading} disabled={loading || coolDown} />
    </Container>
  );
};

export default RefreshButton;
