import styled from 'styled-components';

export const IconArrow = () => (
  <svg width="26" height="47" viewBox="0 0 26 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.18239 47C1.62246 47 1.07167 46.79 0.640246 46.37C-0.213415 45.5209 -0.213415 44.1514 0.640246 43.3114L20.7426 23.3163L1.01662 3.69546C0.162961 2.84635 0.162961 1.47681 1.01662 0.636832C1.8611 -0.212277 3.24713 -0.212277 4.09162 0.636832L25.3598 21.7915C26.2134 22.6406 26.2134 24.0102 25.3598 24.8501L3.72448 46.37C3.30224 46.79 2.74232 47 2.18239 47Z"
      fill="white"
    />
  </svg>
);

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  display: flex;
  width: 800px;
  margin-left: -50px;
`;

export const ButtonContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 2px solid #ebebebff;
  background: #00000040;
  padding: 15px 20px;
  width: 100%;
  border-radius: 100px;
  justify-content: space-between;

  > svg {
    width: 70px;
    height: 50px;
  }
`;

export const Group = styled.span`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;

  svg {
    width: 160px;
    background: #ffffff77;
    padding: 10px;
    border-radius: 100px;
    border: 2px solid #ffffff6a;
    height: 100px;
  }
`;

export const BoostPageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1000px;
  min-height: calc(100vh / var(--scale) - 80px);
  padding: 40px;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8) 0%;

  @keyframes pulseBoost {
    0% {
      transform: scale(0.9);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(0.9);
    }
  }

  > svg {
    height: 400px;
    width: 400px;
    margin-bottom: 70px;

    transform: scale(1.1);
    animation: pulseBoost 2s infinite;
  }
  p {
    color: #fff;
    font-size: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.upgrade {
      font-family: var(--font), sans-serif;
      color: #efff00;
      font-size: 48px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    &.level {
      font-family: var(--font), sans-serif;
      color: #efff00;
      font-size: 88px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    &.price {
      color: #fff;
      font-size: 80px;
      font-weight: 500;

      svg {
        margin-top: -10px;
        width: 120px;
        height: 100px;
      }
    }
    &.tasks {
      color: #ccc;
      gap: 20px;
      display: flex;
      justify-content: space-between;

      svg {
        width: 50px;
      }
    }
  }
  button {
    margin-top: 20px;
    position: relative;

    .meerkatHand {
      position: absolute;
    }
  }
`;
