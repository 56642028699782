import { useApp } from '../../contexts/AppContext';

import Calm from './Events/Calm';
import Cloud from './Events/Cloud';
import Speed from './Events/Speed';
import Luck from './Events/Luck';
import TaskCompleted from './Events/TaskCompleted';

const Popups = () => {
  const { event } = useApp();
  if (!event) return;

  if (event.type === 'speed') return <Speed />;
  if (event.type === 'fuel') return <Calm />;
  if (event.type === 'turbo') return <Cloud />;
  if (event.type === 'luck') return <Luck />;

  if (event.type === 'taskComplete') return <TaskCompleted />;
};

export default Popups;
