export const IconCalm = () => (
  <svg width="173" height="161" viewBox="0 0 173 161" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M86.178 61.1644C80.4152 61.1644 75.709 56.4582 75.709 50.6954C75.709 44.9327 80.4152 40.1304 86.178 40.1304C91.9407 40.1304 96.743 44.8366 96.743 50.6954C96.743 56.5542 92.0368 61.1644 86.178 61.1644Z"
      fill="#DFD8EC"
    />
    <path
      d="M61.4941 123.786C61.4941 113.605 65.2399 103.617 69.562 95.4526C67.449 105.633 66.5846 117.543 70.0422 128.588C74.9406 144.628 85.8898 149.911 94.2458 151.639C90.596 153.656 87.5226 154.521 86.37 154.905H85.9858C84.4491 154.425 79.2626 152.984 73.9801 149.142C65.7202 143.187 61.4941 134.639 61.4941 123.786Z"
      fill="#EDD500"
    />
    <path
      d="M27.3018 147.221C16.7367 138.289 10.7819 124.17 7.61239 113.893C5.01915 105.537 3.96265 98.2379 3.48242 94.9724C7.4203 94.7803 17.5051 94.6843 28.6464 96.7012C26.1492 111.3 24.9006 139.346 45.4544 155.673C38.8273 154.233 32.4883 151.543 27.3018 147.221Z"
      fill="#B38DBF"
    />
    <path
      d="M61.8783 90.4581C44.1098 124.074 55.6353 144.436 66.1043 154.713C54.2906 152.504 46.1268 146.645 41.8047 137.232C35.9459 124.746 36.5222 109.475 38.0589 98.8141C38.155 98.0457 38.251 97.3734 38.347 96.7011C39.5956 88.9214 41.4205 82.6784 42.2849 79.8931C45.5505 81.3338 53.4262 84.7914 61.8783 90.4581Z"
      fill="#DFD8EC"
    />
    <path
      d="M137.809 98.1625C151.131 94.7806 164.061 94.9269 168.796 95.1155C168.788 95.1691 168.78 95.2238 168.772 95.2795C168.269 98.6048 167.2 105.686 164.696 113.753L164.696 113.754C159.037 132.457 150.889 142.228 145.054 147.106C139.465 151.748 128.97 157.254 110.284 157.347C121.321 154.49 129.386 148.143 133.856 138.738L133.857 138.736C140.021 125.446 139.442 109.377 137.809 98.227V98.1625Z"
      fill="#B38DBF"
      stroke="#303030"
      strokeWidth="0.3"
    />
    <path
      d="M104.596 154.853C100.98 155.382 97.5261 155.505 94.6853 155.371C96.1795 154.694 97.8125 153.725 99.5172 152.532C99.5177 152.531 99.5182 152.531 99.5186 152.531L100.38 151.956L100.38 151.956L100.385 151.953C106.752 147.322 114.278 138.733 114.278 123.786C114.278 112.947 110.363 102.402 105.773 93.8629C115.685 86.5197 125.947 81.7774 129.977 80.0949C130.858 82.9495 132.637 89.0974 133.861 96.7237C133.957 97.3956 134.053 98.0664 134.148 98.8327L134.149 98.8355C136.93 118.114 133.668 130.282 130.416 137.168L130.415 137.17C125.927 146.911 117.236 152.842 104.596 154.853Z"
      fill="#DFD8EC"
      stroke="#303030"
      strokeWidth="0.3"
    />
    <path
      d="M94.2461 151.639C85.8901 149.911 74.9409 144.628 70.0425 128.588C66.5849 117.543 67.4493 105.633 69.5623 95.4525H69.6583C69.8504 94.9723 70.0425 94.5881 70.1386 94.3C70.3307 94.1079 70.3307 93.9158 70.4267 93.8197C71.7713 91.4186 73.116 89.2095 74.3646 87.2886C79.1669 79.9891 84.0652 74.4185 86.1782 72.0173C88.3873 74.4185 93.2856 79.9891 98.0879 87.2886C99.6246 89.6898 101.065 92.0909 102.218 94.3C109.325 107.266 110.958 117.255 110.958 123.786C110.958 134.543 106.732 143.091 98.5681 149.046C97.1274 150.103 95.5907 150.967 94.2461 151.639Z"
      fill="#FAFF00"
    />
    <path
      d="M134.105 96.6051C134.105 95.8367 134.681 95.1644 135.354 94.9723C135.93 94.7802 136.506 94.6842 137.082 94.5881C137.274 95.6446 137.467 96.7972 137.659 97.9497C137.178 97.9497 136.698 98.1418 136.218 98.2379C135.354 98.43 134.393 97.9497 134.201 97.0853C134.105 96.8932 134.105 96.7011 134.105 96.6051Z"
      fill="#303030"
    />
    <path
      d="M104.139 90.9384C117.009 81.3339 129.975 76.4355 130.647 76.2434C131.032 76.0513 131.512 76.0513 131.896 76.2434C132.28 76.4355 132.568 76.8197 132.76 77.2039C132.856 77.5881 135.45 84.5994 137.082 94.6842C136.506 94.7803 135.93 94.8763 135.354 95.0684C134.681 95.2605 134.105 95.9328 134.105 96.7012C132.856 88.9215 131.032 82.6785 130.167 79.8932C126.229 81.526 115.76 86.3282 105.675 93.8198C105.195 92.8594 104.619 91.8029 104.139 90.9384Z"
      fill="#303030"
    />
    <path
      d="M104.139 90.9385C104.619 91.8029 105.195 92.8594 105.675 93.8199C105.291 94.0119 105.003 94.3001 104.619 94.5882C103.851 95.1645 102.794 95.0684 102.218 94.3001C101.738 93.6278 101.834 92.5713 102.602 91.995C103.082 91.6108 103.562 91.2266 104.043 90.9385H104.139Z"
      fill="#303030"
    />
    <path
      d="M94.2461 151.639C95.5907 150.967 97.1275 150.103 98.5682 149.046C106.732 143.091 110.958 134.543 110.958 123.786C110.958 117.255 109.325 107.266 102.218 94.3001C102.794 95.0684 103.851 95.1645 104.619 94.5882C105.003 94.3001 105.291 94.0119 105.676 93.8198C110.286 102.368 114.224 112.933 114.224 123.786C114.224 138.673 106.732 147.221 100.393 151.831L99.5286 152.408C97.9919 152.312 96.263 152.12 94.3421 151.639H94.2461Z"
      fill="#303030"
    />
    <path
      d="M86.8503 155.001L86.5622 154.905H86.3701C87.5227 154.521 90.5961 153.656 94.2459 151.639C96.1668 152.12 97.8956 152.312 99.4324 152.408C97.5114 153.752 95.6866 154.809 94.0538 155.481C90.404 155.481 87.8108 155.097 86.8503 155.001Z"
      fill="#303030"
    />
    <path
      d="M94.0536 155.481C97.031 155.673 100.777 155.481 104.715 155.001C117.393 152.984 126.133 147.029 130.647 137.233C133.913 130.317 137.178 118.119 134.393 98.8142C134.297 98.0458 134.201 97.3735 134.105 96.7012C134.105 96.7972 134.105 96.9893 134.201 97.1814C134.393 98.0458 135.353 98.526 136.218 98.334C136.698 98.334 137.178 98.0458 137.658 98.0458V98.2379C139.291 109.379 139.867 125.419 133.721 138.673C129.11 148.374 120.658 154.809 109.037 157.498H108.364C102.121 157.498 96.6469 156.73 92.709 156.154C93.0932 155.961 93.5734 155.769 94.0536 155.481Z"
      fill="#303030"
    />
    <path
      d="M86.7544 158.074C87.4268 157.978 89.7319 157.402 92.7093 156.154C96.6472 156.73 102.122 157.402 108.365 157.498H109.037C107.788 157.786 106.444 158.074 105.099 158.267C101.738 158.747 98.472 158.939 95.7828 158.939C91.1726 158.939 87.7149 158.459 86.4663 158.267H86.5624V158.171H86.6584L86.7544 158.074Z"
      fill="#303030"
    />
    <path
      d="M86.8506 155.001C87.811 155.001 90.4043 155.481 94.054 155.481C93.5738 155.769 93.0936 155.961 92.7094 156.154C89.828 155.673 87.811 155.289 87.0427 155.097H86.8506V155.001Z"
      fill="#303030"
    />
    <path
      d="M92.7092 156.154C89.7318 157.402 87.4267 157.979 86.7544 158.075C86.8504 158.075 86.9465 158.075 87.0425 157.882C87.6188 157.594 88.003 156.922 87.8109 156.25C87.7149 155.769 87.5228 155.481 87.1386 155.193C87.1386 155.193 87.1386 155.193 87.1386 155.097C87.9069 155.289 89.9239 155.673 92.8053 156.154H92.7092Z"
      fill="#303030"
    />
    <path
      d="M86.9466 155.001H87.0427V155.097C87.0427 155.097 87.0427 155.097 86.8506 155.001H86.9466Z"
      fill="#303030"
    />
    <path
      d="M86.7544 158.074C86.7544 158.074 86.9465 157.978 87.0425 157.882C86.9465 158.074 86.8504 158.074 86.7544 158.074Z"
      fill="#303030"
    />
    <path d="M86.5625 154.905C86.5625 154.905 86.5625 154.905 86.8506 155.001H86.5625V154.905Z" fill="#303030" />
    <path
      d="M86.466 158.266C87.7146 158.459 91.1723 158.939 95.7825 158.939C98.4718 158.939 101.737 158.747 105.099 158.266C106.444 158.074 107.788 157.786 109.037 157.498H109.805C128.822 157.498 139.483 151.927 145.15 147.221C151.009 142.323 159.173 132.526 164.839 113.797C167.433 105.441 168.489 98.1418 168.969 94.9722C164.359 94.7802 151.201 94.5881 137.658 98.0457C137.466 96.8932 137.274 95.7406 137.082 94.6841C154.178 90.5541 170.218 91.7067 170.89 91.8027C171.371 91.8027 171.755 92.0909 172.043 92.379C172.331 92.7632 172.523 93.1474 172.427 93.6276C172.427 94.0118 171.563 103.232 168.009 114.758C164.743 125.515 158.5 140.306 147.263 149.718C138.619 157.018 125.653 160.86 109.805 160.86C109.325 160.86 108.845 160.764 108.268 160.764C97.2232 160.572 88.3869 158.747 86.2739 158.362H86.37H86.466V158.266Z"
      fill="#303030"
    />
    <path
      d="M86.5622 158.17H86.4662C86.4662 158.17 86.4662 158.17 86.3701 158.266C86.4662 158.17 86.5622 158.17 86.5622 158.17Z"
      fill="#303030"
    />
    <path d="M85.986 154.905H86.0821C86.0821 154.905 85.89 154.905 85.7939 155.001H85.986V154.905Z" fill="#303030" />
    <path d="M85.8897 154.905L85.7937 155.001H85.6016L85.9857 154.905H85.8897Z" fill="#303030" />
    <path
      d="M85.7942 158.17C84.9298 157.882 84.4496 157.114 84.6417 156.25C84.6417 155.865 84.9298 155.481 85.2179 155.289C85.314 155.193 85.41 155.193 85.5061 155.097C85.5061 155.097 85.6021 155.097 85.6982 155.097H85.7942C85.7942 155.097 85.9863 155.001 86.0823 155.001H86.3705C86.3705 155.001 86.5626 155.097 86.6586 155.097H86.9468L87.1388 155.289C87.523 155.577 87.7151 155.865 87.8112 156.346C88.0033 157.018 87.6191 157.69 87.0428 157.978C86.9468 157.978 86.8507 158.17 86.7547 158.17C86.7547 158.17 86.7547 158.267 86.6586 158.267C86.6586 158.267 86.5626 158.267 86.4665 158.363H85.9863V158.267L85.7942 158.17Z"
      fill="#303030"
    />
    <path
      d="M85.4097 155.001C85.4097 155.001 85.2176 155.097 85.1216 155.193C85.1216 155.097 85.2176 155.097 85.3137 155.001H85.4097Z"
      fill="#303030"
    />
    <path
      d="M85.5056 158.074C84.8333 157.786 84.4492 157.018 84.6412 156.25C84.4492 157.114 84.9294 157.882 85.7938 158.17C85.6977 158.17 85.6017 158.17 85.5056 158.074Z"
      fill="#303030"
    />
    <path
      d="M85.5059 158.075C84.6415 157.882 82.5285 157.306 79.7432 156.154C82.6245 155.673 84.5455 155.289 85.4099 155.097C85.3138 155.193 85.2178 155.193 85.2178 155.289C84.9296 155.481 84.6415 155.866 84.6415 156.25C84.4494 157.018 84.8336 157.786 85.5059 158.075Z"
      fill="#303030"
    />
    <path
      d="M85.4098 155.001C84.5454 155.193 82.6245 155.577 79.7431 156.058C79.3589 155.865 78.8787 155.673 78.3984 155.385C81.9521 155.385 84.5454 155.001 85.5058 154.905L85.4098 155.001Z"
      fill="#303030"
    />
    <path
      d="M68.9858 157.306C73.2119 157.018 76.8616 156.538 79.743 156.154C82.5283 157.306 84.6413 157.882 85.5057 158.074C85.6018 158.171 85.6978 158.171 85.7939 158.171H85.986C84.7374 158.459 81.2797 158.939 76.6695 158.939C74.7486 158.939 72.7316 158.843 70.5226 158.555C70.0423 158.267 69.5621 157.786 68.9858 157.306Z"
      fill="#303030"
    />
    <path
      d="M86.1781 40.1302C80.4154 40.1302 75.7091 44.8365 75.7091 50.6953C75.7091 56.5541 80.4154 61.1643 86.1781 61.1643C91.9409 61.1643 96.7431 56.458 96.7431 50.6953C96.7431 44.9325 92.0369 40.1302 86.1781 40.1302ZM73.2119 50.6953C73.2119 43.4918 79.0707 37.6331 86.1781 37.6331C93.2855 37.6331 99.2403 43.4918 99.2403 50.6953C99.2403 57.8987 93.3815 63.6615 86.1781 63.6615C78.9747 63.6615 73.2119 57.8027 73.2119 50.6953Z"
      fill="#303030"
      stroke="#303030"
      strokeWidth="1.04976"
      strokeMiterlimit="10"
    />
    <path
      d="M72.9237 83.4469C79.1666 74.3225 84.7373 68.6558 85.0254 68.3677C85.3136 67.9835 85.7938 67.8875 86.178 67.8875C86.6582 67.8875 87.0424 67.9835 87.4266 68.3677C87.7147 68.6558 94.1498 75.1869 100.777 85.3678C101.833 87.0006 102.986 88.8254 104.042 90.8424C103.562 91.1305 103.082 91.5147 102.602 91.8989C101.833 92.4752 101.737 93.5317 102.218 94.204C101.065 91.9949 99.6244 89.5938 98.0877 87.1927C93.2854 79.8932 88.387 74.3225 86.178 71.9214C84.065 74.3225 79.1666 79.8932 74.3643 87.1927C73.1157 89.1136 71.7711 91.3226 70.4265 93.7238C70.6186 93.2435 70.5225 92.7633 70.2344 92.3791C71.0988 89.2096 71.9632 86.1362 72.9237 83.4469Z"
      fill="#303030"
    />
    <path
      d="M68.4097 90.9385C69.4662 88.9215 70.6187 87.0967 71.6752 85.4639C72.1555 84.7916 72.5396 84.1192 72.9238 83.543C71.9634 86.2323 71.099 89.3057 70.2345 92.4752C70.2345 92.2831 70.0424 92.091 69.8504 91.995C69.3701 91.6108 68.8899 91.2266 68.4097 90.9385Z"
      fill="#303030"
    />
    <path
      d="M86.274 158.266C84.9293 158.459 79.8389 159.515 72.9236 160.187C72.9236 160.187 72.9236 160.187 72.8276 160.187C72.1552 159.707 71.3869 159.227 70.5225 158.459C72.7315 158.747 74.7485 158.843 76.6694 158.843C81.2796 158.843 84.7372 158.363 85.9858 158.17H86.274V158.266Z"
      fill="#303030"
    />
    <path
      d="M69.7544 94.5881C69.9465 93.8197 70.1386 93.1474 70.2346 92.4751C70.5228 92.8593 70.6188 93.3395 70.4267 93.8197C70.3307 93.8197 70.3307 94.1079 70.1386 94.3C70.0425 94.4921 69.9465 94.5881 69.7544 94.5881Z"
      fill="#303030"
    />
    <path
      d="M69.7543 94.5882C69.7543 94.5882 70.0424 94.4921 70.1384 94.3C70.0424 94.5882 69.8503 94.9724 69.6582 95.3565C69.7542 95.0684 69.7543 94.7803 69.7543 94.5882Z"
      fill="#303030"
    />
    <path
      d="M67.3533 158.267C66.0086 158.075 64.664 157.786 63.3193 157.498C65.3363 157.498 67.1612 157.498 68.986 157.306C69.5623 157.786 70.0425 158.267 70.5228 158.555C69.4663 158.555 68.4098 158.459 67.3533 158.267Z"
      fill="#303030"
    />
    <path
      d="M69.7543 94.5882C69.2741 95.0684 68.4096 95.0684 67.8334 94.5882C67.4492 94.3001 67.161 94.0119 66.7769 93.8199C67.2571 92.8594 67.8334 91.8029 68.4096 90.9385C68.8899 91.2266 69.3701 91.6108 69.8503 91.995C70.0424 92.091 70.2345 92.2831 70.2345 92.4752C70.1385 93.1475 69.9464 93.8199 69.7543 94.5882Z"
      fill="#303030"
    />
    <path
      d="M67.8333 155.001C71.7712 155.577 75.4209 155.673 78.3984 155.481C78.8786 155.769 79.3588 155.961 79.743 156.154C76.8616 156.538 73.2119 157.018 68.9859 157.306C68.0254 156.538 67.065 155.673 66.1045 154.713L67.8333 155.001Z"
      fill="#303030"
    />
    <path
      d="M61.8784 90.4581C62.3586 89.4977 62.9349 88.4412 63.5112 87.4807C65.144 88.5372 66.7768 89.6898 68.4095 90.9384C67.8333 91.8028 67.257 92.8593 66.7768 93.8197C65.144 92.5711 63.5112 91.4186 61.8784 90.4581Z"
      fill="#303030"
    />
    <path
      d="M62.6468 160.86C59.0931 160.86 55.6354 160.668 52.3699 160.284C49.8727 158.939 47.5676 157.402 45.4546 155.673C51.5055 157.114 57.6524 157.498 63.3191 157.498C64.6638 157.786 66.0084 158.074 67.353 158.267C68.4095 158.459 69.466 158.555 70.5225 158.555C71.387 159.323 72.1553 159.803 72.8276 160.284C70.1384 160.572 67.257 160.764 64.1835 160.764C63.6072 160.764 63.127 160.86 62.6468 160.86Z"
      fill="#303030"
    />
    <path
      d="M58.2285 123.786C58.2285 112.933 62.1664 102.368 66.7766 93.8198C67.1608 94.0119 67.4489 94.3001 67.8331 94.5882C68.4094 95.0684 69.2738 95.0684 69.754 94.5882C69.754 94.7803 69.754 95.0684 69.658 95.3566H69.5619C65.2399 103.616 61.4941 113.605 61.4941 123.786C61.4941 134.639 65.7201 143.187 73.98 149.142C79.2625 152.984 84.449 154.425 85.9858 154.905H85.8897C85.8897 154.905 85.6976 154.905 85.5055 155.001C84.5451 155.001 81.9518 155.481 78.3981 155.481C76.4772 154.617 74.2682 153.368 72.0591 151.831C65.7201 147.221 58.2285 138.673 58.2285 123.786Z"
      fill="#303030"
    />
    <path
      d="M41.8052 76.2434C41.8052 76.2434 52.3702 80.1813 63.5115 87.4808C62.9353 88.4413 62.359 89.4978 61.8788 90.4582C53.4267 84.7915 45.551 81.3339 42.2854 79.8932C41.421 82.6785 39.5961 88.9215 38.3475 96.7012C38.3475 95.9328 37.7713 95.2605 37.0989 95.0684C36.5227 94.8763 35.9464 94.7803 35.3701 94.6842C37.0029 84.5994 39.5961 77.5881 39.6922 77.2039C39.8843 76.8197 40.1724 76.4355 40.5566 76.2434C40.9408 76.0513 41.421 76.0513 41.8052 76.2434Z"
      fill="#303030"
    />
    <path
      d="M63.3191 157.402C51.6975 154.617 43.3415 148.278 38.7313 138.577C32.5844 125.323 33.1607 109.283 34.7935 98.142V97.9499C35.2737 97.9499 35.7539 98.142 36.2341 98.238C37.0986 98.4301 38.059 97.9499 38.2511 97.0855C38.3472 96.8934 38.3472 96.7013 38.3472 96.6052C38.2511 97.2775 38.1551 97.9499 38.059 98.7182C36.5223 109.379 35.946 124.651 41.8048 137.137C46.1269 146.549 54.2908 152.408 66.1044 154.617C67.0649 155.577 68.0253 156.442 68.9858 157.21C67.1609 157.306 65.336 157.402 63.3191 157.402Z"
      fill="#303030"
    />
    <path
      d="M36.2341 98.2379C36.2341 98.2379 35.2737 97.9497 34.7935 97.9497C34.9855 96.7972 35.1776 95.6446 35.3697 94.5881C35.946 94.6842 36.5223 94.7802 37.0986 94.9723C37.7709 95.1644 38.3472 95.8367 38.3472 96.6051C38.3472 96.7011 38.3472 96.8932 38.2511 97.0853C38.059 97.9497 37.0986 98.43 36.2341 98.2379Z"
      fill="#303030"
    />
    <path
      d="M35.3697 94.5882C35.1776 95.6447 34.9855 96.7973 34.7934 97.9498C32.7765 97.3735 30.6634 96.8933 28.6465 96.6052C28.9346 95.4526 29.1267 94.3001 29.3188 93.3396C31.3358 93.6277 33.3527 94.0119 35.3697 94.5882Z"
      fill="#303030"
    />
    <path
      d="M25.0929 149.719C13.9516 140.306 7.70865 125.515 4.44309 114.758C0.889397 103.232 0.0249844 94.012 0.0249844 93.6278C-0.0710614 93.1476 0.12103 92.7634 0.409168 92.3792C0.697305 92.0911 1.08149 91.8029 1.56172 91.8029C2.13799 91.7069 14.6239 90.8425 29.319 93.4357C29.1269 94.3962 28.9348 95.5487 28.6466 96.7013C17.5053 94.6843 7.42051 94.7804 3.48263 94.9724C3.96286 98.238 5.01937 105.537 7.6126 113.893C10.7821 124.17 16.737 138.289 27.302 147.221C32.4885 151.543 38.8275 154.233 45.4547 155.673C47.5677 157.402 49.8728 158.939 52.3699 160.284C41.1326 158.939 31.8161 155.385 25.0929 149.719Z"
      fill="#303030"
    />
    <path
      d="M66.1043 154.617C55.6353 144.34 44.1098 123.978 61.8783 90.3623C63.5111 91.3228 65.1439 92.4753 66.7767 93.7239C62.1665 102.272 58.2286 112.837 58.2286 123.69C58.2286 138.577 65.7202 147.125 72.0592 151.736C74.2682 153.272 76.4773 154.521 78.3982 155.385C75.4208 155.577 71.771 155.385 67.8332 154.905L66.1043 154.617Z"
      fill="#C4B8DA"
    />
    <path
      d="M38.7313 138.673C43.3415 148.374 51.6975 154.713 63.319 157.498C57.6523 157.498 51.5054 157.114 45.4545 155.673C24.9007 139.346 26.1493 111.3 28.6465 96.7012C30.6635 96.9893 32.7765 97.4695 34.7934 98.0458V98.2379C33.1606 109.379 32.5844 125.419 38.7313 138.673Z"
      fill="#A479B1"
    />
    <path d="M86.1777 24.1866V2" stroke="#DFD8EC" strokeWidth="3.67416" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M67.545 29.373L61.4941 18.9041"
      stroke="#DFD8EC"
      strokeWidth="3.67416"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.2286 41.3789L41.2285 33.7913"
      stroke="#DFD8EC"
      strokeWidth="3.67416"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.223 41.3789L131.223 33.7913"
      stroke="#DFD8EC"
      strokeWidth="3.67416"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.466 29.373L109.517 18.9041"
      stroke="#DFD8EC"
      strokeWidth="3.67416"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.2686 90.4583C103.851 109.956 104.043 125.899 100.873 136.176"
      stroke="white"
      strokeWidth="4.8289"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
