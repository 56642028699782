import { useNavigate } from 'react-router-dom';

import { ButtonContainer, Group, IconArrow } from './Wrappers';
import Text from './Text';
import { IconSpeed } from '../Icons/IconSpeed';
import { useApp } from '../../contexts/AppContext';
import { getSpeedLevel } from '../../lib/farming/util';
import { useTranslation } from 'react-i18next';

const Speed = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { app } = useApp();

  const level = getSpeedLevel(app);

  return (
    <ButtonContainer onClick={() => navigate('/boost/speed')}>
      <Group>
        <IconSpeed />
        <Text
          heading={t('boosts.speed.physicalStrength')}
          level={t('system.levelShort') + ` ${app.state.speedLevel}/30`}
          reward={`${level.speed} ${t('boosts.speed.coinsPerSec')}`}
        />
      </Group>
      <IconArrow />
    </ButtonContainer>
  );
};

export default Speed;
