import { MainButton } from '../../components/MainButton';
import { IconCoin } from '../../components/Icons/IconCoin';
import { BoostPageContainer } from '../../components/Boosts/Wrappers';
import { useApp } from '../../contexts/AppContext';
import { getLuckLevelNext } from '../../lib/farming/util';
import { IconCup } from '../../components/Icons/IconCup';
import MeerkatHandAnimated from '../../components/Icons/MeerkatHandAnimated ';
import { shouldDisableBoost } from '../../lib/utils';
import BoostCondtions from '../../components/pages/Boost/BoostCondtions';
import { useTranslation } from 'react-i18next';

const BoostLuck = () => {
  const { app, userUpgrade, isBusy, farmingState } = useApp();
  const { t } = useTranslation();

  const levelNext = getLuckLevelNext(app);
  const handleUpgrade = () => userUpgrade('luck');
  const isDisabled = shouldDisableBoost(app, levelNext, isBusy, farmingState);

  return (
    <BoostPageContainer>
      <p className="upgrade">{t('boosts.upgrade') + ' ' + t('boosts.luck.stressResistance')}</p>
      <p className="level">
        {t('boosts.toLevel')} {levelNext.level}
      </p>
      <p className="duration">
        <b>
          {levelNext.probability}
          {t('boosts.luck.percentChance')}
          {levelNext.multiplier}
        </b>
      </p>

      <IconCup />

      <p className="price">
        {levelNext.price.toLocaleString('us')} <IconCoin />
      </p>

      <BoostCondtions levelNext={levelNext} />

      <MainButton onClick={handleUpgrade} disabled={isDisabled}>
        {t('boosts.levelUp')}
        {!isDisabled && <MeerkatHandAnimated />}
      </MainButton>
    </BoostPageContainer>
  );
};

export default BoostLuck;
