import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useApp } from '../../../contexts/AppContext';
import { getTurboAvailable } from '../../../lib/farming/util';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: none;
  border: 0;
  width: 800px;
  height: 835px;
  background: none;
  border: none;

  img {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;

    &.active {
      opacity: 1;
    }
  }
`;

const MeerkatWalk = () => {
  const [i, setIndex] = useState(1);
  const { app, farmingTurboActivate, isBusy } = useApp();

  const turboAvailable = getTurboAvailable(app);

  useEffect(() => {
    const timer = setInterval(() => setIndex((i) => (i === 6 ? 1 : i + 1)), 200);

    return () => clearInterval(timer);
  }, []);

  const handleTurbo = () => {
    if (turboAvailable && !isBusy) farmingTurboActivate();
  };

  return (
    <Container onClick={handleTurbo}>
      <img src={`/img/animation/walk1.webp`} className={i === 1 ? 'active' : ''} />
      <img src={`/img/animation/walk2.webp`} className={i === 2 ? 'active' : ''} />
      <img src={`/img/animation/walk3.webp`} className={i === 3 ? 'active' : ''} />
      <img src={`/img/animation/walk4.webp`} className={i === 4 ? 'active' : ''} />
      <img src={`/img/animation/walk5.webp`} className={i === 5 ? 'active' : ''} />
      <img src={`/img/animation/walk6.webp`} className={i === 6 ? 'active' : ''} />
    </Container>
  );
};

export default MeerkatWalk;
