import styled from 'styled-components';

const ButtonLabel = styled.div<{ active: boolean; marginTop: number }>`
  color: ${({ active }) => (active ? 'black' : 'white')};
  color: #fff;

  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-size: 32px;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  font-family: var(--font), sans-serif;
  text-transform: uppercase;
`;

export default ButtonLabel;
