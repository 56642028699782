import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Goblet from './Buttons/Goblet';
import Meerkat from './Buttons/Meerkat';
import Stats from './Buttons/Stats';

const MenuContainer = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: space-between;
  border: 1px solid #888;
  background: #00000040;

  position: absolute;
  top: calc(60px * var(--scale));
  left: 50%;
  margin: 0 auto 0 -465px;

  border-radius: 90px;
  width: 930px;
  height: 120px;
  padding: 20px 0;
  transform-origin: center 0;
  transform: scale(var(--scale));

  a,
  > div {
    flex: 3;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  > div {
    border-left: 1px solid #888;
    border-right: 1px solid #888;
  }
`;

const MenuTop = () => {
  return (
    <MenuContainer>
      <NavLink to="/statistics">{({ isActive }) => <Meerkat active={isActive} />}</NavLink>
      <div style={{ flex: 4 }}>
        <Stats active={false} />
      </div>
      <NavLink to="/leaderboard">{({ isActive }) => <Goblet active={isActive} />}</NavLink>
    </MenuContainer>
  );
};

export default MenuTop;
